import { TwoWayAnovaResultsV2, TwoWayAnovaResultsDataV2 } from 'api/data/analyses';
import { Table } from 'components/UI/Table';
import { useTranslation } from 'hooks/store';

import { VariablesData } from 'store/data/variables';
import { AnalysisStatistic } from 'components/Analysis/Analyses';
import { computeCellValue } from 'helpers/analysis';
import { PStatistic } from 'components/Analysis/Analyses/AnalysisStatistics';

interface Props {
	variablesData: VariablesData;
	results: TwoWayAnovaResultsV2;
}

export function CompareNumericTwoWayAnova({ results, variablesData }: Props) {
	const { translate } = useTranslation();

	const { data, error } = results;

	return (
		<AnalysisStatistic>
			<AnalysisStatistic.Title>
				{translate(({ analysis }) => analysis.statistics.twoWayAnova.name)}
			</AnalysisStatistic.Title>

			{error && (
				<AnalysisStatistic.Error>
					{error.message ??
						translate(({ errors }) => errors.api.analyses.statistics.twoWayAnova)}
				</AnalysisStatistic.Error>
			)}

			{data && <Result variablesData={variablesData} data={data} />}
		</AnalysisStatistic>
	);
}

function Result({
	data,
	variablesData
}: {
	data: TwoWayAnovaResultsDataV2[];
	variablesData: VariablesData;
}) {
	const { variablesMap } = variablesData;
	const COLUMN_NAMES = [
		'Source/effect',
		'Sum of squares',
		'df',
		'Mean square',
		'F-value',
		'p-value'
	];

	/**
	 *
	 * @param variableNames [var1, var2]
	 * @returns computed label(string) => "var1Label * var2Label" if variableNames > 1, otherwise just "var1Label"
	 */
	function computeName(variableNames: string[]) {
		if (variableNames?.[0] === 'Residual') {
			return 'Residual';
		}

		if (variableNames?.[0] === 'Intercept') {
			return 'Intercept';
		}

		if (variableNames.length) {
			return variableNames.map(name => variablesMap[name]?.label).join(' * ');
		}

		return variablesMap[variableNames[0]]?.label ?? variableNames[0];
	}

	return (
		<Table fullWidth={false}>
			<Table.Head>
				<Table.Row>
					{COLUMN_NAMES.map(colName => (
						<Table.Column key={`twoWayAnova-${colName}-head`} title={colName}>
							{colName}
						</Table.Column>
					))}
				</Table.Row>
			</Table.Head>
			<Table.Body>
				{data.map((res, idx) => (
					<Table.Row key={`two-way-anova-row-${idx + 1}`}>
						<Table.Cell minWidth={15} bold>
							{computeName(res.variables.map(d => d.name))}
						</Table.Cell>
						<Table.Cell>{computeCellValue(res.sumSq)}</Table.Cell>
						<Table.Cell>{computeCellValue(res.df, { noDecimals: true })}</Table.Cell>
						<Table.Cell>{computeCellValue(res.meanSq)}</Table.Cell>
						<Table.Cell>
							{res.fValue === null ? '' : computeCellValue(res.fValue)}
						</Table.Cell>
						<Table.Cell>
							{res.pValue !== null ? <PStatistic value={res.pValue as number} /> : ''}
						</Table.Cell>
					</Table.Row>
				))}
			</Table.Body>
		</Table>
	);
}
