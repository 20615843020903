import { BrowserRouter } from 'react-router-dom';

import { Alerts } from './Alerts';
import ErrorBoundary from './ErrorBoundary';
import { Init } from './Init';
import { Navigation } from './Navigation';
import { PageLifecycle } from './PageLifecycle';
import { Store } from './Store';
import { TrackingProvider } from './tracking/TrackingProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnReconnect: false
		}
	}
});

export function App() {
	return (
		<BrowserRouter>
			<TrackingProvider>
				<Store>
					<PageLifecycle>
						<Init>
							<ErrorBoundary>
								<Alerts>
									<QueryClientProvider client={queryClient}>
										<Navigation />
									</QueryClientProvider>
								</Alerts>
							</ErrorBoundary>
						</Init>
					</PageLifecycle>
				</Store>
			</TrackingProvider>
		</BrowserRouter>
	);
}
