import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
	Columns,
	DataModel,
	KaplanMeierAnalysisV2,
	KaplanMeierDataModels,
	KaplanMeierStatisticsV2,
	KaplanMeierVariablesV2,
	TimeWindowSizeType
} from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ConfigContainer } from '../../UI';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Switch } from 'components/UI/Interactables/Switch';
import {
	buildSeriesLevelVariableData,
	buildVariablesDataLocation,
	getAggregatorVariableNameByAggregationRuleName,
	mergeSelectItems
} from 'helpers/variables';
import {
	useTranslation,
	useUpdateAnalysis,
	useRefetchAnalyses,
	useAnalysisConfigPanel,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useFilters,
	useVariables,
	useVariablesDataSelectItems
} from 'hooks/store';
import { useDebounce, usePrevious } from 'hooks/utils';
import { Nullable, SelectItem } from 'types/generic';
import { AnalysisOptionsHeader } from '../../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';
import { DateTimeInput } from 'components/UI/Inputs/DateTimeInput';
import { GenericMap } from 'types/index';
import { useGroupingVariablesSelectItems } from 'hooks/store/data/analysis/useGroupingVariablesSelectItems';
import { isAnalysisInputValid } from 'helpers/analysis';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	analysis: KaplanMeierAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

const TIME_WINDOW_VALUES = [
	TimeWindowSizeType.Years,
	TimeWindowSizeType.Months,
	TimeWindowSizeType.Weeks,
	TimeWindowSizeType.Days,
	TimeWindowSizeType.Hours,
	TimeWindowSizeType.Minutes,
	TimeWindowSizeType.Seconds
];

const DEFAULT_VARIABLE_VALUES: KaplanMeierVariablesV2 = {
	durationVariable: null,
	endDate: null,
	eventVariable: null,
	positiveEvent: [],
	startDate: null,
	timeUnit: TimeWindowSizeType.Days,
	endTimeCensorVariable: null
};

const DEFAULT_STATISTICS_VALUES: KaplanMeierStatisticsV2 = {
	confidenceIntervalsV2: false,
	logRankV2: false,
	patientsV2: false
};

export function KaplanConfigV2({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();

	const validInput = isAnalysisInputValid(analysis);

	const updateAnalysis = useUpdateAnalysis();
	const [shouldRefetchAnalyses] = useRefetchAnalyses();

	const {
		input: { variables, series, dataModel, statistics, selectedDataModel },
		options: { showCategoryLabels }
	} = analysis;
	const [values, setValues] = useState(variables);
	const [stats, setStats] = useState(statistics);
	const [{ areFiltersOpen }] = useFilters();

	const [{ isConfigPanelOpen, isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(
		analysis.id
	);
	const [fullscreen] = useFullscreenAnalysis();

	const [activeColumn] = useAnalysesActiveColum();

	const [{ data: variablesData }] = useVariables();
	const { variableSets, variableSetsMap, variablesMap } = variablesData;
	const aggregatorVariableNameByAggregationRuleName =
		getAggregatorVariableNameByAggregationRuleName(variableSetsMap);

	const {
		selectItemsMap: mainSelectItemsMap,
		selectItems: mainSelectItems,
		categorySelectItems: mainCategorySelectItems,
		dateSelectItems: mainDateSelectItems
	} = variablesDataSelectItems;

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const setVariablesData = buildSeriesLevelVariableData(variablesData, series);
	const seriesVariableSelectItems = useVariablesDataSelectItems(setVariablesData, {
		series,
		omitVariables: []
	});

	const {
		selectItemsMap: seriesSelectItemsMap,
		selectItems: seriesSelectItems,
		categorySelectItems: seriesCategorySelectItems,
		dateSelectItems: seriesDateSelectItems
	} = seriesVariableSelectItems;

	const selectItemsMap = {
		...seriesSelectItemsMap,
		...mainSelectItemsMap
	};

	const dataModelSelectItems: SelectItem[] = [
		{
			label: translate(dict => dict.analysis.analyses.correlationsV2.config.mainLevel),
			value: DataModel.main
		},
		{
			label: translate(dict => dict.analysis.analyses.correlationsV2.config.singleSeries),
			value: DataModel.series
		}
	];

	const variableSetSelectItems: SelectItem[] = variableSets.map(set => ({
		label: set.setLabel,
		value: set.setName
	}));

	useEffect(() => {
		if (!isEqual(variables, values)) {
			setValues(variables);
		}
	}, [variables]);

	useEffect(() => {
		if (!isEqual(statistics, stats)) {
			setStats(statistics);
		}
	}, [statistics]);

	useDebounce(
		() => {
			if (
				haveVariablesChanged(variables, values) ||
				(haveStatisticsChanged(statistics, stats) && values.positiveEvent.length !== 0)
			) {
				const updatedAnalysis: KaplanMeierAnalysisV2 = {
					...analysis,
					input: {
						...analysis.input,
						variables: {
							...analysis.input.variables,
							...values
						},
						statistics: stats
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values, stats, values.positiveEvent, variables],
		ANALYSIS_DEBOUNCE_TIME
	);

	const prevShouldRefetchAnalyses = usePrevious(shouldRefetchAnalyses);
	const prevGrouping = usePrevious(values.groupVariable);
	useEffect(() => {
		if (
			(prevShouldRefetchAnalyses !== undefined &&
				prevShouldRefetchAnalyses !== shouldRefetchAnalyses) ||
			!values.groupVariable ||
			(prevGrouping !== undefined && prevGrouping !== values.groupVariable)
		) {
			setStats(state => ({
				...state,
				logRankV2: false
			}));
		}
	}, [shouldRefetchAnalyses, values]);

	function getVariableCategories(name: string) {
		let variable = variablesMap[name];

		if (name in aggregatorVariableNameByAggregationRuleName) {
			const aggregatorVariableName = aggregatorVariableNameByAggregationRuleName[name];

			if (aggregatorVariableName in variablesMap) {
				variable = variablesMap[aggregatorVariableName];
			}
		}

		if (variable) return variable.categories;

		return [];
	}

	function getVariableCategoriesSelectItems(variableName: string): SelectItem[] {
		return getVariableCategories(variableName).map(c => ({
			label: showCategoryLabels ? c.label : c.value,
			value: c.value
		}));
	}

	const positiveEventSelectItems = getVariableCategoriesSelectItems(
		values.eventVariable?.name ?? ''
	);

	// BUILD DATAMODEL
	const timeDataModel = {
		is: {
			duration: selectedDataModel === KaplanMeierDataModels.duration,
			timeRangeWithEvent: selectedDataModel === KaplanMeierDataModels.timeRangeWithEvent
		},
		label: {
			[KaplanMeierDataModels.duration]: translate(
				({ analysis }) => analysis.analyses.kaplan.config.duration
			),
			[KaplanMeierDataModels.timeRangeWithEvent]: translate(
				({ analysis }) => analysis.analyses.kaplan.config.timeRangeWithEvent
			)
		}
	};

	const numericSelectItems = useMemo(() => {
		if (series) {
			return mergeSelectItems(seriesSelectItems, variablesData, [
				VariableType.Float,
				VariableType.Integer,
				VariableType.TimeDuration
			]);
		}
		return mergeSelectItems(mainSelectItems, variablesData, [
			VariableType.Float,
			VariableType.Integer,
			VariableType.TimeDuration
		]);
	}, [series, mainSelectItems, seriesSelectItems]);

	const eventVariableSelectItems = useMemo(() => {
		if (series) {
			return seriesCategorySelectItems;
		}

		return mainCategorySelectItems;
	}, [dataModel, series, variablesDataSelectItems, seriesSelectItems]);

	const groupingSelectItems = useGroupingVariablesSelectItems({
		variablesData,
		series
	});

	const dateTimeSelectItems = useMemo(() => {
		if (series) {
			return seriesDateSelectItems;
		}

		return mainDateSelectItems;
	}, [dataModel, series, variablesDataSelectItems, seriesSelectItems]);

	const timeDataModelSelectItems: SelectItem[] = [
		{
			label: timeDataModel.label[KaplanMeierDataModels.duration],
			value: KaplanMeierDataModels.duration
		},
		{
			label: timeDataModel.label[KaplanMeierDataModels.timeRangeWithEvent],
			value: KaplanMeierDataModels.timeRangeWithEvent
		}
	];

	// BUILD TIME UNIT
	const timeWindowValues = useMemo(() => {
		const items: SelectItem[] = [];
		const itemsMap: GenericMap<SelectItem> = {};

		const isDateTimeVariableSelected = [values.endDate, values.startDate]
			.map(d => d?.name)
			.some(variableName => variablesMap[variableName ?? '']?.type === VariableType.DateTime);

		const timeValues = !isDateTimeVariableSelected
			? TIME_WINDOW_VALUES.slice(0, 4)
			: TIME_WINDOW_VALUES;

		timeValues.forEach(timeWindow => {
			const item = {
				label: translateTimeWindowValues(timeWindow),
				value: timeWindow
			};

			items.push(item);
			itemsMap[item.value] = item;
		});

		return { items, itemsMap };
	}, [values.startDate, values.endDate]);

	function onSelectDataModel(dataModel: Nullable<string>) {
		const newAnalysis: KaplanMeierAnalysisV2 = {
			...analysis,
			input: {
				...analysis.input,
				...(dataModel === DataModel.main ? { series: undefined } : {}),
				dataModel: dataModel as DataModel | null,
				variables: DEFAULT_VARIABLE_VALUES,
				statistics: {
					confidenceIntervalsV2: false,
					logRankV2: false,
					patientsV2: false
				}
			}
		};

		updateAnalysis({
			analysis: newAnalysis
		});
	}

	function onSelectSeries(series: Nullable<string>) {
		if (!series) return;

		const newAnalysis: KaplanMeierAnalysisV2 = {
			...analysis,
			input: { ...analysis.input, series }
		};

		updateAnalysis({
			analysis: {
				...newAnalysis,
				input: {
					...newAnalysis.input,
					variables: DEFAULT_VARIABLE_VALUES
				}
			} as KaplanMeierAnalysisV2
		});

		setValues(DEFAULT_VARIABLE_VALUES);
	}

	function onSelectTimeDataModel(dataModel: KaplanMeierDataModels) {
		if (dataModel === selectedDataModel) return;

		updateAnalysis({
			analysis: {
				...analysis,
				input: {
					...analysis.input,
					selectedDataModel: dataModel as KaplanMeierDataModels,
					variables: DEFAULT_VARIABLE_VALUES
				}
			}
		});

		setStats(DEFAULT_STATISTICS_VALUES);
	}

	function onSelectVariable(
		key: keyof KaplanMeierAnalysisV2['input']['variables'],
		variableName: Nullable<string>
	) {
		if (!variableName) {
			setValues(values => ({
				...values,
				[key]: null
			}));
			return;
		}
		const setName = variablesLocation[variableName]?.setName;
		const payload: Partial<KaplanMeierVariablesV2> = {
			[key]: {
				name: variableName,
				...(setName ? { series: setName } : {})
			},
			...(key === 'eventVariable' ? { positiveEvent: [] } : {})
		};

		// AUTO SELECT NEW TIME UNIT IF CURRENT SELECTION RESTICTS TIME UNIT SELECTIONS AND CURRENT TIME UNIT FALLS OUTSIDE OF NEW RANGE;
		const variableKey = key === 'endDate' || key === 'startDate' ? key : false;
		if (variableKey) {
			const newlySelectedVariable =
				variablesMap[variableName] ??
				variablesMap[aggregatorVariableNameByAggregationRuleName[variableName]];

			const otherVariableKey = variableKey === 'startDate' ? 'endDate' : 'startDate';
			const otherVariableName = values[otherVariableKey]?.name;
			const otherSelectedVariable =
				variablesMap[otherVariableName ?? ''] ??
				variablesMap[aggregatorVariableNameByAggregationRuleName[otherVariableName ?? '']];

			if (
				otherSelectedVariable?.type === VariableType.Date &&
				newlySelectedVariable?.type === VariableType.Date &&
				!TIME_WINDOW_VALUES.slice(0, 4).includes(values.timeUnit)
			) {
				payload.timeUnit = TimeWindowSizeType.Days; // this is always available and also default selection
			}
		}

		setValues(values => ({
			...values,
			...payload
		}));

		setStats(DEFAULT_STATISTICS_VALUES);
	}

	function translateTimeWindowValues(type: TimeWindowSizeType) {
		switch (type) {
			case TimeWindowSizeType.Years:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.years
				);
			case TimeWindowSizeType.Months:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.months
				);
			case TimeWindowSizeType.Weeks:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.weeks
				);
			case TimeWindowSizeType.Days:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.days
				);
			case TimeWindowSizeType.Hours:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.hours
				);
			case TimeWindowSizeType.Minutes:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.minutes
				);
			case TimeWindowSizeType.Seconds:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.seconds
				);

			default:
				return translate(
					({ analysis }) => analysis.analyses.timeCourse.config.timeUnit.years
				);
		}
	}

	return (
		<ConfigContainer
			data-testid={`${analysis.type}-config-container`}
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as KaplanMeierAnalysisV2} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<CreatableSelect
						label={translate(({ analysis }) => analysis.generic.dataModel)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === dataModel)}
						onValueSelected={onSelectDataModel}
						canClear={false}
					/>
					{dataModel === DataModel.series && (
						<CreatableSelect
							label={translate(({ analysis }) => analysis.generic.series)}
							items={variableSetSelectItems}
							value={variableSetSelectItems.find(item => item.value === series)}
							onValueSelected={onSelectSeries}
							canClear={false}
						/>
					)}
					<CreatableSelect
						disabled={!dataModel || (dataModel === DataModel.series && !series)}
						label={translate(
							({ analysis }) => analysis.analyses.kaplan.config.dataType
						)}
						items={timeDataModelSelectItems}
						value={timeDataModelSelectItems.find(
							item => item.value === selectedDataModel
						)}
						onValueSelected={dataModel => {
							onSelectTimeDataModel(dataModel as KaplanMeierDataModels);
						}}
						canClear={false}
					/>
					{/* VARIABLE INPUTS */}
					{selectedDataModel === KaplanMeierDataModels.duration && (
						<>
							<CreatableSelect
								label={translate(
									({ analysis }) => analysis.analyses.kaplan.config.timeVariable
								)}
								items={numericSelectItems}
								disabled={
									!timeDataModel.is.duration ||
									!dataModel ||
									(dataModel === DataModel.series && !series)
								}
								value={selectItemsMap[values.durationVariable?.name ?? ''] ?? null}
								onValueSelected={durationVariable => {
									onSelectVariable('durationVariable', durationVariable);
								}}
								canClear={false}
								isItemDisabled={item => item.value === values.groupVariable?.name}
							/>
							<Gap marginGap={{ bottom: 1.6 }} notLastChild>
								<CreatableSelect
									disabled={
										!dataModel || (dataModel === DataModel.series && !series)
									}
									label={translate(
										({ analysis }) => analysis.analyses.kaplan.config.endpoint
									)}
									items={eventVariableSelectItems}
									value={selectItemsMap[values.eventVariable?.name ?? '']}
									onValueSelected={eventVariable => {
										onSelectVariable('eventVariable', eventVariable);
									}}
									canClear={false}
									isItemDisabled={item =>
										item.value === values.groupVariable?.name
									}
								/>

								<CreatableSelect
									disabled={
										!dataModel ||
										(dataModel === DataModel.series && !series) ||
										!values.eventVariable
									}
									items={positiveEventSelectItems}
									value={positiveEventSelectItems.find(
										item => item.value === values.positiveEvent[0]
									)}
									values={values.positiveEvent.map(val => ({
										label: val,
										value: val
									}))}
									onValuesSelected={newValues => {
										newValues &&
											setValues(state => ({
												...state,
												positiveEvent: newValues
											}));
										setStats(DEFAULT_STATISTICS_VALUES);
									}}
									onClear={() => {
										setValues(state => ({
											...state,
											positiveEvent: []
										}));
									}}
									canClear={false}
									hasMultipleValues
								/>
							</Gap>
						</>
					)}
					{/* TIME RANGE WITH EVENTS */}
					{selectedDataModel === KaplanMeierDataModels.timeRangeWithEvent && (
						<>
							<CreatableSelect
								label={translate(({ patientCard }) => patientCard.startDate)}
								items={dateTimeSelectItems}
								disabled={
									!timeDataModel.is.timeRangeWithEvent ||
									!dataModel ||
									(dataModel === DataModel.series && !series)
								}
								value={selectItemsMap[values.startDate?.name ?? ''] ?? null}
								onValueSelected={startDate => {
									onSelectVariable('startDate', startDate);
								}}
								isItemDisabled={item =>
									item.value === values.endDate?.name ||
									item.value === values.endTimeCensorVariable?.name
								}
								canClear={false}
							/>
							<CreatableSelect
								label={translate(dict => dict.projects.tableView.endDate)}
								items={dateTimeSelectItems}
								disabled={
									!dataModel ||
									(dataModel === DataModel.series && !series) ||
									!timeDataModel.is.timeRangeWithEvent
								}
								value={selectItemsMap[values.endDate?.name ?? ''] ?? null}
								onValueSelected={endDate => {
									onSelectVariable('endDate', endDate);
								}}
								isItemDisabled={item =>
									item.value === values.startDate?.name ||
									item.value === values.endTimeCensorVariable?.name
								}
								canClear={false}
							/>
							<CreatableSelect
								disabled={!dataModel || (dataModel === DataModel.series && !series)}
								label={translate(
									({ analysis }) => analysis.analyses.kaplan.config.timeUnit
								)}
								items={timeWindowValues.items}
								value={
									timeWindowValues.itemsMap[values.timeUnit ?? ''] ??
									timeWindowValues.items[0]
								}
								onValueSelected={timeUnit => {
									if (timeUnit) {
										setValues(state => ({
											...state,
											timeUnit: timeUnit as TimeWindowSizeType
										}));
									}
									setStats(DEFAULT_STATISTICS_VALUES);
								}}
								canClear={false}
							/>
							<Gap marginGap={{ bottom: 1.6 }} notLastChild>
								<CreatableSelect
									disabled={
										!dataModel || (dataModel === DataModel.series && !series)
									}
									label={translate(
										({ analysis }) => analysis.analyses.kaplan.config.endpoint
									)}
									items={eventVariableSelectItems}
									value={selectItemsMap[values.eventVariable?.name ?? '']}
									onValueSelected={eventVariable => {
										onSelectVariable('eventVariable', eventVariable);
									}}
									canClear={false}
									isItemDisabled={item =>
										item.value === values.groupVariable?.name
									}
								/>

								<CreatableSelect
									disabled={
										!dataModel ||
										(dataModel === DataModel.series && !series) ||
										!values.eventVariable
									}
									items={positiveEventSelectItems}
									value={positiveEventSelectItems.find(
										item => item.value === values.positiveEvent[0]
									)}
									values={values.positiveEvent.map(val => ({
										label: val,
										value: val
									}))}
									onValuesSelected={newValues => {
										newValues &&
											setValues(state => ({
												...state,
												positiveEvent: newValues
											}));

										setStats(DEFAULT_STATISTICS_VALUES);
									}}
									onClear={() => {
										setValues(state => ({
											...state,
											positiveEvent: []
										}));
									}}
									canClear={false}
									hasMultipleValues
								/>

								{/** TIMESTAMP */}
								<DateTimeInput
									value={values.autofillDate}
									options={{
										disabled:
											!dataModel ||
											(dataModel === DataModel.series && !series) ||
											!values.eventVariable,
										label: translate(
											({ analysis }) =>
												analysis.analyses.kaplan.config.endDateCensoring
										),
										small: true,
										noLeftMargins: true,
										responsive: true,
										smallCalendar: true
									}}
									onChange={autofillDate => {
										if (autofillDate) {
											setValues(state => ({
												...state,
												autofillDate: autofillDate
											}));
										}
									}}
								/>
								{/** VARIABLE */}
								<CreatableSelect
									label={translate(
										dict =>
											dict.analysis.analyses.kaplan.config
												.endTimeCensorVariable
									)}
									items={dateTimeSelectItems}
									disabled={
										!dataModel ||
										(dataModel === DataModel.series && !series) ||
										!values.eventVariable
									}
									value={
										selectItemsMap[values.endTimeCensorVariable?.name ?? ''] ??
										null
									}
									isItemDisabled={item =>
										[values.endDate?.name, values.startDate?.name].includes(
											item.value
										)
									}
									onValueSelected={endTimeCensorVariable =>
										onSelectVariable(
											'endTimeCensorVariable',
											endTimeCensorVariable
										)
									}
								/>
							</Gap>
						</>
					)}

					{/* GROUPING VARIABLE */}
					{selectedDataModel ? (
						<CreatableSelect
							onInputChange={() => undefined}
							disabled={!validInput}
							label={translate(
								({ analysis }) => analysis.analyses.kaplan.config.groups
							)}
							placeholder={translate(
								({ analysis }) => analysis.analyses.kaplan.config.noGrouping
							)}
							items={groupingSelectItems}
							isItemDisabled={item =>
								item.value === values.eventVariable?.name ||
								item.value === values.durationVariable?.name
							}
							value={selectItemsMap[values.groupVariable?.name ?? ''] ?? null}
							onValueSelected={groupVariable => {
								onSelectVariable('groupVariable', groupVariable);
							}}
						/>
					) : (
						<></>
					)}
					{/* STATISTICS */}
					<Switch
						label={translate(
							({ analysis }) => analysis.analyses.kaplan.config.confidenceIntervals
						)}
						disabled={!validInput}
						on={stats.confidenceIntervalsV2}
						onChange={() => {
							setStats(state => ({
								...state,
								confidenceIntervalsV2: !state.confidenceIntervalsV2
							}));
						}}
					/>
					<Switch
						label={translate(
							({ analysis }) => analysis.analyses.kaplan.config.patientsAtRisk
						)}
						disabled={!validInput}
						on={stats.patientsV2}
						onChange={() =>
							setStats(state => ({ ...state, patientsV2: !state.patientsV2 }))
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.analyses.kaplan.config.logRank)}
						disabled={!values.groupVariable}
						on={stats.logRankV2}
						onChange={() => {
							setStats(state => ({ ...state, logRankV2: !state.logRankV2 }));
						}}
					/>
				</Gap>
			</CollapsibleCard>

			{/* FORMATTING */}
			<AnalysisFormatting analysis={analysis} hasChartTicks isEnabled={validInput} />
		</ConfigContainer>
	);
}

function haveStatisticsChanged(own: KaplanMeierStatisticsV2, values: KaplanMeierStatisticsV2) {
	return (
		own.patientsV2 !== values.patientsV2 ||
		own.confidenceIntervalsV2 !== values.confidenceIntervalsV2 ||
		own.logRankV2 !== values.logRankV2
	);
}

function haveVariablesChanged(own: KaplanMeierVariablesV2, values: KaplanMeierVariablesV2) {
	return (
		own.eventVariable?.name !== values.eventVariable?.name ||
		own.positiveEvent !== values.positiveEvent ||
		own.durationVariable?.name !== values.durationVariable?.name ||
		own.groupVariable?.name !== values.groupVariable?.name ||
		own.startDate !== values.startDate ||
		own.endDate !== values.endDate ||
		own.timeUnit !== values.timeUnit ||
		own.autofillDate !== values.autofillDate ||
		own.endTimeCensorVariable !== values.endTimeCensorVariable
	);
}
