import { makeRequest } from './makeRequest';

export type FileForUpload = {
	base64Bytes: string;
	fileName: string;
	variableName: string;
};

export const storeBase64Files = async ({
	projectId,
	files,
	entryId
}: {
	projectId: string;
	files: FileForUpload[];
	entryId: string;
}) =>
	makeRequest({
		method: 'storeBase64Files',
		service: 'data-entry',
		data: {
			files: files.map(file => ({
				base64Bytes: file.base64Bytes,
				fileName: file.fileName,
				variableName: file.variableName
			})),
			projectId,
			datasetentryid: entryId
		}
	});
