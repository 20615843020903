import { makeRequest } from './makeRequest';
import { useQuery } from '@tanstack/react-query';

export type GetProjectData = {
	project: {
		organizations: {
			organizationId: number;
			organizationName: string;
		}[];

		projectOwnerDetails: {
			organization: string;
		};
	};

	metadata?: Record<string, any>;
};

export const useGetProjectQuery = ({ projectId }: { projectId: string }) => {
	return useQuery({
		queryKey: ['project', projectId],
		queryFn: () =>
			makeRequest<GetProjectData>({
				method: 'getProject',
				service: 'projects',
				data: {
					project: {
						projectId: parseInt(projectId)
					},
					retrieveProjectOwnerDetails: true
				}
			})
	});
};
