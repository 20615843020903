// ----------------------------------- IMPORT TIMEZONE ---------------------------------------------

import type { SelectItem } from 'types';
import type { PreviewVariable } from 'types/data/projects/import/types';
import { VariableType } from 'types/data/variables/constants';
import { TranslateFunction } from '../../../hooks/store/ui/useTranslation';

// Returns a list of timezones supported by the browser
const allTimezones = Intl.supportedValuesOf('timeZone');

// Returns the name and offset of the current timezone in the format 'Timezone/Name (+/-)HH:MM'
export function getTimeZoneOffset(timezone: string) {
	if (!allTimezones.includes(timezone)) return '';
	const now = new Date();
	const utcDate = new Date(now.toLocaleString('en-US', { timeZone: 'UTC' })).getTime();
	const tzDate = new Date(
		now.toLocaleString('en-US', {
			timeZone: timezone
		})
	).getTime();
	const offset = (tzDate - utcDate) / 3600000;
	const sign = offset >= 0 ? '+' : '-';
	const hours = Math.abs(Math.floor(offset)).toString().padStart(2, '0');
	const minutes = Math.abs((offset % 1) * 60)
		.toString()
		.padStart(2, '0');
	return `(GMT${sign}${hours}:${minutes}) `;
}

// Returns a list that can be used as dropdown select items
export const timezones: SelectItem[] = allTimezones.map((tz: string) => ({
	label: `${getTimeZoneOffset(tz)}${tz}`,
	value: tz
}));

export function generateTimeZoneSelectItems(timezones: string[]) {
	return timezones.map((tz: string) => ({
		label: `${getTimeZoneOffset(tz)}${tz}`,
		value: tz
	}));
}

// Returns the client timezone
export const userTimezome = Intl.DateTimeFormat().resolvedOptions().timeZone;

// returns the client timezone as a select item
export const userDetectedTimezone = timezones.find(
	(item: SelectItem) => item.value === userTimezome
);

export const defaultTimeZone = {
	label: `(GMT+0:00) UTC`,
	value: 'UTC'
};

export function getUserTimeZoneSelectItem() {
	return timezones.find((item: SelectItem) => item.value === userTimezome);
}

export const variableHasSuggestedDateFormat = (
	variable: PreviewVariable,
	suggestedVariables: PreviewVariable[],
	isExcelOrigin?: boolean
) => {
	if (isExcelOrigin) return false;
	// look for the original suggested variable
	const foundVariable = suggestedVariables?.find(v => v.id === variable.id);

	// if no suggested variable, return false
	if (!foundVariable) return false;

	// check that the changed variable is the same type as the original suggested variable
	const typeHasNotChanged = variable.type === foundVariable.type;

	// return true if the type has not changed and the original variable has a detected date format
	return !!foundVariable.dateFormat && typeHasNotChanged;
};

export function applyTimezoneToAllDatetimeVariables(
	variables: PreviewVariable[],
	globalTimezone: SelectItem,
	setNewVariables: (formattedVariables: PreviewVariable[]) => void,
	translate: TranslateFunction
) {
	const formattedVariablesWithTimeZone = variables.map(variable => {
		if (variable.type === VariableType.DateTime && !/(%z)/i.test(variable.dateFormat)) {
			const updatedVariable = {
				...variable,
				timeZone: globalTimezone,
				timeZoneError:
					globalTimezone.value === ''
						? translate(
								dict =>
									dict.projects.createAndImport.generics.previewVariables
										.missingTimeZone
						  )
						: ''
			};

			return updatedVariable;
		}
		return variable;
	});
	setNewVariables(formattedVariablesWithTimeZone);
}

export function importRequiresGlobalTimezoneSettings(variables: PreviewVariable[]) {
	const hasVariablesThatRequireTimeZoneSettings = variables.some(
		variable =>
			variable.type === VariableType.DateTime &&
			(variable.customDateFormat
				? !/Z|([+-]HH:MM)|([+-]HHMM)/i.test(variable.customDateFormat)
				: !/Z|([+-]HH:MM)|([+-]HHMM)/i.test(variable.dateFormat))
	);
	return (
		variables.some(variable => variable.type === VariableType.DateTime) &&
		hasVariablesThatRequireTimeZoneSettings
	);
}

export function requiresTimezone(variable: PreviewVariable) {
	if (variable.customDateFormat !== '') {
		return (
			variable.type === VariableType.DateTime &&
			!/Z|([+-]HH:MM)|([+-]HHMM)|(%z)/i.test(variable.customDateFormat)
		);
	}
	return (
		variable.type === VariableType.DateTime &&
		!/Z|([+-]HH:MM)|([+-]HHMM)|(%z)/i.test(variable.dateFormat)
	);
}

export function requiresDateFormatTimezone(dateFormat: string, enabled: boolean) {
	return enabled && !/Z|([+-]HH:MM)|([+-]HHMM)|(%z)/i.test(dateFormat);
}

export function getClientTimezone(timeZones: SelectItem[]) {
	const timeZoneNames = Object.values(timeZones).map(timezone => timezone.value);
	const detectedClientTimezone = timeZoneNames.find(timezone => timezone === userTimezome);
	return detectedClientTimezone
		? {
				label: `${getTimeZoneOffset(detectedClientTimezone)}${detectedClientTimezone}`,
				value: detectedClientTimezone
		  }
		: defaultTimeZone;
}
// ----------------------------------- IMPORT TIMEZONE ---------------------------------------------
