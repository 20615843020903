import type { ApiEntrySearchFilter } from './../../../api/data/filters/types';
import { cloneDeep, isEmpty, isEqual, values } from 'lodash';
import { batch } from 'react-redux';

import {
	type EntryFileResponse,
	type EntryFilesResponse,
	type FileToStore,
	type ApiSort,
	DownloadFormat,
	ExportDatasetType
} from 'api/data/entries';
import { ENTRY_FILE_SIZE_LIMIT, FETCH_DEBOUNCE_TIME, systemGeneratedVariables } from 'consts';
import { navigationHubEvent } from 'helpers/navigation';
import { rebuildAnalyses } from 'store/data/analyses';
import { getDependenciesAction } from 'store/data/dependencies';
import { rebuildFilters } from 'store/data/filters';
import { getFormsAction } from 'store/data/forms';
import { getProject } from 'store/data/projects';
import { getEntryRevisions, setRevisionId } from 'store/data/revisions';
import { getStatuses } from 'store/data/statuses';
import {
	VariablesMap,
	getVariablesAction,
	hydrateData,
	rebuildVariablesDataCategories
} from 'store/data/variables';
import type { ActionPayload, Thunk, ThunkContext } from 'store/types';
import { createActivity } from 'store/ui/activities';
import { ExportOptions, Nullable, StringMap, TableName } from 'types/index';

import {
	ActionTypes,
	type CreateEntryAction,
	type CreateSeriesEntryAction,
	type DeleteAllLocalEntryFilesAction,
	type DeleteEntryAction,
	type DeleteLocalEntryFileAction,
	type DeleteSeriesEntryAction,
	type EntryDraft,
	type EntryStatus,
	type EntryStatusValue,
	type EntryValues,
	type GetEntriesAction,
	type GetEntryAction,
	type GetEntryDraftAction,
	type GetEntryFileAction,
	type GetEntryFilesAction,
	type GetLatestEntryAction,
	type GetMoreEntriesAction,
	type GetSeriesEntriesAction,
	type GetSeriesEntriesCountAction,
	type HydrateEntryAction,
	type LocalEntryFile,
	type RebuildEntriesTableAction,
	type ResetCreateEntryDraftAction,
	type ResetEntryFetchedDataAction,
	type SaveEntryDraftAction,
	type SaveLocalEntryFileAction,
	type SelectedSeriesEntry,
	type SetEntriesSearchTermAction,
	type SetEntriesTableErrorsFilterAction,
	type SetEntriesTableParamsAction,
	type SetEntriesTableVisibleColumnsAction,
	type SetEntryIdAction,
	type SetEntryStatusAction,
	type SetRefetchEntriesAction,
	type SetSelectedSeriesEntryAction,
	type UpdateEntryAction,
	type UpdateEntryStatusAction,
	type UpdateSeriesEntryAction,
	type SetConflictedDataAction,
	type ConflictedData,
	type Entry,
	type AddNamesFromUserIdsAction,
	type GetConflictEntryAction,
	type SetSeriesConflictedDataAction
} from './types';

import { parseGetFileResponse, parseGetFilesResponse } from './parsers';
import {
	buildEntriesTableColumns,
	extractVariableNamesFromEntry,
	prepareValidEntryValues,
	parseFormValuesForAPI,
	extractStatusFromEntry,
	generateStatusObservationData,
	preparePartialEntryValues,
	variableSetHasDefinedAggregation,
	withoutEntryMetadata,
	getConflictedData,
	extractIdsWithoutNames,
	parseEntries,
	parseActiveSortToApiSort
} from 'helpers/entries';
import { downloadFileFromUrl } from 'helpers/generic';
import {
	buildVariablesDataFromStoreData,
	getMapDifferences,
	buildVariableSetVariablesData,
	buildAggregationRuleNameToAggregatorVariableMap
} from 'helpers/variables';
import { parseApiEntryFilters } from 'helpers/analysis';
import { objectDifference } from 'helpers/objects';
import type { ThunkDispatch } from 'redux-thunk';
import type { ApplicationState } from 'store/root';
import type { EntryFilter } from 'api/data/filters';
import { TIME_DURATION_KEYS } from 'timeDurationConsts';
import type { Action } from 'types/store/types';
import { resetExportWizardAction, setSeriesExportFileAndNamesAction } from '../exportWizard';
import { removeFitlersWithNoValue } from 'components/Dataset/Entries/helpers';
import { AnalysisVariable } from 'api/data/analyses';
import { ProjectType } from 'types/data/projects/constants';
import { track } from 'app/tracking/TrackingProvider';

export const DEFAULT_GET_ENTRIES_SORT: ApiSort = {
	variableName: 'lastmodifieddate',
	direction: 'DESC'
};

export const getEntriesAction = (payload: ActionPayload<GetEntriesAction>): GetEntriesAction => ({
	type: ActionTypes.GET_ENTRIES,
	payload
});

export const getEntries =
	({
		abortController,
		filtered,
		username
	}: {
		abortController?: AbortController;
		filtered?: boolean;
		sorting?: ApiSort;
		username?: string;
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_ENTRIES, dispatch });

		const {
			data: {
				projects: { projectId },
				statuses: { byProjectId: statusesByProjectId },
				entries: {
					metadata: { pageIndex, pageSize, term: searchTerm }
				},
				filters: { dataset: filtersStoreData } // PRJCTS-9211 could it be the filters are not in the store at the point in time when the request is sent to the backend. Which actions "modifies" these filters?
			},
			ui: {
				tables: {
					byName: { [TableName.Entries]: entryTable }
				},
				i18n: { translations }
			}
		} = getState();

		try {
			activity.begin({ payload: { projectId } });
			if (projectId) {
				let allFilters: any[] = [];
				const ownerFilter = {
					filterType: 'owner',
					value: username
				};
				let filters: EntryFilter[] = [];

				const searchFilter: ApiEntrySearchFilter = {
					filterType: 'search',
					value: searchTerm
				};

				if (filtered && filtersStoreData.byProjectId[projectId]) {
					// redux can have filters with no value set, but we need to make sure those are not sent to BE
					filters = removeFitlersWithNoValue(filtersStoreData, projectId); // PRJCTS-9211 Could this be the cause of EPOS issues
				}

				const { variablesData } = await context.api.data.variables().getVariables({
					projectId: Number(projectId)
				});
				const { variablesMap, variableSetsMap } = variablesData;

				const aggRuleNameToAggRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return { ...acc, [ruleName]: variablesMap[rule.aggregator.variableName] };
					},
					{}
				);

				const parsedFilters = parseApiEntryFilters(filters, {
					...variablesMap,
					...aggRuleToVariableMap
				});

				if (username)
					allFilters = [...parsedFilters, ownerFilter, ...(searchTerm && [searchFilter])];

				const { entries, statuses, errors, totalCount } = await context.api.data
					.entries()
					.getEntries(
						{
							projectId: Number(projectId),
							filters: username
								? allFilters
								: [...parsedFilters, ...(searchTerm && [searchFilter])],
							maxPageSize: pageSize,
							startAt: pageIndex * pageSize,
							...(entryTable?.activeSort
								? {
										sorting: parseActiveSortToApiSort(entryTable.activeSort)
								  }
								: {})
						},
						{ signal: abortController?.signal }
					);

				// populate entries with Names for enteredbyuser and ownedbyuser
				await fetchAndAddNamesFromIds(entries, dispatch, getState, context);

				const translateMap = TIME_DURATION_KEYS.reduce(
					(acc, key) => ({
						...acc,
						[key]: translations?.timeDurationPlaceholder.prefix[key]
					}),
					{}
				);

				const parsedEntries = parseEntries(
					entries,
					variablesData.variablesMap,
					...(values(translateMap).every(val => !!val) ? [translateMap as StringMap] : [])
				);

				batch(() => {
					dispatch(getVariablesAction({ projectId, variablesData }));
					dispatch(
						getEntriesAction({
							projectId,
							entries: parsedEntries,
							statuses,
							errors,
							totalCount
						})
					);
					dispatch(rebuildFilters());
					dispatch(rebuildAnalyses());
				});

				/**
				 * Detect new statuses from `getEntries` response and refetch statuses
				 */
				if (statuses && statusesByProjectId[projectId]) {
					const statusesData = statusesByProjectId[projectId];

					const { byName, fetched } = statusesData;

					if (fetched) {
						let refetchStatuses = false;

						for (const entryId in statuses) {
							const entryStatus: EntryStatusValue = statuses[entryId];

							if (!(entryStatus.variableName in byName)) {
								refetchStatuses = true;
								break;
							}
						}

						if (refetchStatuses) dispatch(getStatuses());
					}
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { projectId } });
		} finally {
			activity.end();
		}
	};

const getMoreEntriesAction = (
	payload: ActionPayload<GetMoreEntriesAction>
): GetMoreEntriesAction => ({
	type: ActionTypes.GET_MORE_ENTRIES,
	payload
});

export const getMoreEntries =
	(input: { projectId: string; startAt: number }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_MORE_ENTRIES, dispatch });

		const { projectId, startAt } = input;

		try {
			activity.begin();

			const {
				data: {
					projects: { projectId: currentProjectId },
					entries: {
						metadata: { pageSize }
					}
				},
				ui: {
					tables: {
						byName: { [TableName.Entries]: entryTable }
					}
				}
			} = getState();

			const apiSort = entryTable?.activeSort
				? parseActiveSortToApiSort(entryTable.activeSort)
				: DEFAULT_GET_ENTRIES_SORT;

			if (projectId === currentProjectId) {
				const { entries, moreData } = await context.api.data.entries().getEntries({
					projectId: Number(projectId),
					filters: [],
					maxPageSize: pageSize,
					startAt,
					sorting: apiSort
				});

				// populate entries with Names for enteredbyuser and ownedbyuser
				await fetchAndAddNamesFromIds(entries, dispatch, getState, context);

				dispatch(getMoreEntriesAction({ projectId, entries }));

				if (moreData) {
					dispatch(
						getMoreEntries({
							projectId,
							startAt: startAt + entries.length
						})
					);
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const getEntryAction = (payload: ActionPayload<GetEntryAction>): GetEntryAction => ({
	type: ActionTypes.GET_ENTRY,
	payload
});

export const getEntry = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_ENTRY, dispatch });

	try {
		activity.begin();

		const surveyEntryId = getState().auth.patientLoginParams?.entryId;
		const projectId = getState().data.projects.projectId;

		if (projectId && surveyEntryId) {
			const { entry, entryStatus } = await context.api.data.entries().getLatestEntry({
				projectId: Number(projectId),
				datasetentryid: surveyEntryId
			});

			const { variablesData } = await context.api.data.variables().getVariables({
				projectId: Number(projectId)
			});

			// populate entries with Names for enteredbyuser and ownedbyuser
			await fetchAndAddNamesFromIds([entry], dispatch, getState, context);

			const columns = buildEntriesTableColumns(variablesData);

			const [parsedEntry] = parseEntries([entry], variablesData.variablesMap);

			batch(() => {
				dispatch(
					getEntryAction({
						projectId,
						entry: parsedEntry,
						entryStatus,
						columns
					})
				);
				dispatch(getVariablesAction({ projectId, variablesData }));
			});
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const getConflictEntryAction = (
	payload: ActionPayload<GetConflictEntryAction>
): GetConflictEntryAction => ({
	type: ActionTypes.GET_CONFLICT_ENTRY,
	payload
});

const getLatestEntryAction = (
	payload: ActionPayload<GetLatestEntryAction>
): GetLatestEntryAction => ({
	type: ActionTypes.GET_LATEST_ENTRY,
	payload
});

/**
 * Fetches the latest entry version based on any previous `entryId` version.
 *
 * Calls `hydrateData` in case it detects created or deleted variables extracted from the entry fields.
 *
 * Handles entry deletion case -> trigger error + cleanup store
 */
export const getLatestEntry = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_LATEST_ENTRY, dispatch });

	try {
		activity.begin();

		const {
			projects: { projectId, byId: projectsById },
			entries: {
				metadata: { entryId }
			},
			variables: { byProjectId: variablesByProjectId },
			statuses: { byProjectId: statusesByProjectId }
		} = getState().data;

		if (projectId && entryId) {
			const storeVariablesData = variablesByProjectId[projectId].initial;
			const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

			const { entry, entryStatus } = await context.api.data.entries().getLatestEntry(
				{
					projectId: Number(projectId),
					datasetentryid: entryId
				},
				{
					errorCallback: (e: string) => {
						activity.error({
							error: e
						});
						// Cleanup redux and navigates to index page
						dispatch(setEntryId({ entryId: null }));
						navigationHubEvent().dispatch({
							replace: ({ routes, promOrProject }) =>
								routes[promOrProject].dataset.view(projectId)
						});
					}
				}
			);

			// populate entries with Names for enteredbyuser and ownedbyuser
			await fetchAndAddNamesFromIds([entry], dispatch, getState, context);

			const variableNames = extractVariableNamesFromEntry(entry);

			const initialVariableNames = buildEntriesTableColumns(variablesData)
				// remove system generated variables to end up with clean field structure
				.filter(columnName => !systemGeneratedVariables.includes(columnName));

			const initialVariableNamesMap = initialVariableNames.reduce<StringMap>(
				(acc, variableName) => {
					acc[variableName] = variableName;

					return acc;
				},
				{}
			);
			const variableNamesMap = variableNames.reduce<StringMap>((acc, variableName) => {
				acc[variableName] = variableName;

				return acc;
			}, {});

			const variableNamesDifferences = getMapDifferences(
				initialVariableNamesMap,
				variableNamesMap
			);

			/**
			 * if variables got [created | deleted] => hydrate data
			 */
			if (
				variableNamesDifferences.created.length ||
				variableNamesDifferences.deleted.length
			) {
				await dispatch(hydrateData());
			}

			const statusesData = statusesByProjectId[projectId];

			/**
			 * if status got created => refetch statuses
			 */
			if (entryStatus && !(entryStatus.variableName in statusesData.byName)) {
				await dispatch(getStatuses());

				/**
				 * TODO: need an API to fetch project-permissions OR status-permissions
				 * to avoid refetching the entire project
				 */
				const projectType = projectsById[projectId].projectType ?? ProjectType.CORE;

				await dispatch(getProject(projectType));
			}

			const latestStatusesData = getState().data.statuses.byProjectId[projectId];
			const latestVariablesData = buildVariablesDataFromStoreData(
				getState().data.variables.byProjectId[projectId].initial
			);
			const latestColumns = buildEntriesTableColumns(latestVariablesData, {
				hasStatusColumn: latestStatusesData.names.length > 0
			});

			const [parsedEntry] = parseEntries([entry], latestVariablesData.variablesMap);

			dispatch(
				getLatestEntryAction({
					projectId,
					oldEntryId: entryId,
					entry: parsedEntry,
					entryStatus,
					columns: latestColumns
				})
			);
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const getLatestEntrySimple = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_LATEST_ENTRY_SIMPLE, dispatch });

	try {
		activity.begin();

		const {
			projects: { projectId },
			entries: {
				metadata: { entryId }
			}
		} = getState().data;

		if (projectId && entryId) {
			const { entry, entryStatus } = await context.api.data.entries().getLatestEntry(
				{
					projectId: Number(projectId),
					datasetentryid: entryId
				},
				{
					errorCallback: (e: string) => {
						activity.error({
							error: e
						});
						// Cleanup redux and navigates to index page
						dispatch(setEntryId({ entryId: null }));
						navigationHubEvent().dispatch({
							replace: ({ routes, promOrProject }) =>
								routes[promOrProject].dataset.view(projectId)
						});
					}
				}
			);

			// populate entries with Names for enteredbyuser and ownedbyuser
			await fetchAndAddNamesFromIds([entry], dispatch, getState, context);

			const latestStatusesData = getState().data.statuses.byProjectId[projectId];
			const latestVariablesData = buildVariablesDataFromStoreData(
				getState().data.variables.byProjectId[projectId].initial
			);
			const latestColumns = buildEntriesTableColumns(latestVariablesData, {
				hasStatusColumn: latestStatusesData.names.length > 0
			});

			const [parsedEntry] = parseEntries([entry], latestVariablesData.variablesMap);

			dispatch(
				getLatestEntryAction({
					projectId,
					oldEntryId: entryId,
					entry: parsedEntry,
					entryStatus,
					columns: latestColumns
				})
			);
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const createEntryAction = (payload: ActionPayload<CreateEntryAction>): CreateEntryAction => ({
	type: ActionTypes.CREATE_ENTRY,
	payload
});

export const createEntry =
	(
		input: {
			entryValues: EntryValues;
			entryStatus?: EntryStatus;
			organizationId?: string;
			options?: {
				setEntryId?: boolean;
				updateStatus?: boolean;
				canViewCreatedEntry?: boolean;
			};
			draftEntryId?: string;
		},
		callbacks?: {
			onUniqueError?(variableNames: string[]): void;
		}
	): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.CREATE_ENTRY, dispatch });

		const {
			entryValues,
			entryStatus = null,
			organizationId,
			options = {
				setEntryId: false,
				updateStatus: false,
				canViewCreatedEntry: true
			},
			draftEntryId
		} = input;

		try {
			activity.begin();

			const {
				data: {
					projects: { projectId },
					variables: { byProjectId: variablesByProjectId },
					statuses: { byProjectId: statusesByProjectId }
				}
			} = getState();

			if (projectId) {
				const variablesMap = variablesByProjectId[projectId].initial.variables.byName;
				const validEntryValues = prepareValidEntryValues(entryValues);
				const apiEntryValues = parseFormValuesForAPI(validEntryValues, variablesMap);

				const { entry: newEntry } = await context.api.data.entries().createEntry(
					{
						...(organizationId && {
							organizationId: Number(organizationId)
						}),
						projectId: Number(projectId),
						observationData: apiEntryValues,
						...(draftEntryId !== undefined && {
							draftEntryId
						})
					},
					{
						onUniqueError: callbacks?.onUniqueError
					}
				);

				track({
					eventName: 'entry_created',
					data: {
						entryHasStatus: !!entryStatus
					}
				});

				const [parsedEntry] = parseEntries([newEntry], variablesMap);

				// populate entries with Names for enteredbyuser and ownedbyuser
				await fetchAndAddNamesFromIds([parsedEntry], dispatch, getState, context);

				const defaultEntryStatus = extractStatusFromEntry(parsedEntry);

				let newEntryId = parsedEntry.datasetentryid;
				let newEntryStatus: EntryStatus = entryStatus ?? defaultEntryStatus;

				const { setEntryId, updateStatus, canViewCreatedEntry } = options;

				// HAS STATUS TO UPDATE
				if (updateStatus) {
					const observationData = generateStatusObservationData(
						entryStatus,
						defaultEntryStatus
					);

					const updateEntryStatusData = await context.api.data
						.entries()
						.updateEntryStatus(Number(projectId), newEntryId, observationData);

					newEntryId = updateEntryStatusData.newEntryId;
					newEntryStatus = updateEntryStatusData.updatedEntryStatus;

					// Give some time to backend to create revisions
					await new Promise(resolve => setTimeout(resolve, FETCH_DEBOUNCE_TIME));
				}

				// THIS MUTATES THE 2nd parameter (`row`) field with the new uploaded files ID's
				await dispatch(storeEntryFiles(newEntryId, parsedEntry));

				const storeVariablesData = variablesByProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);
				const columns = buildEntriesTableColumns(variablesData, {
					hasStatusColumn: statusesByProjectId[projectId].names.length > 0
				});

				batch(() => {
					// remove local entry files
					dispatch(deleteAllLocalEntryFilesAction());

					dispatch(
						setEntryStatus({
							status: newEntryStatus,
							entryId: newEntryId
						})
					);

					if (canViewCreatedEntry) {
						dispatch(
							createEntryAction({
								projectId,
								entry: parsedEntry,
								entryStatus: newEntryStatus,
								columns,
								setEntryId
							})
						);
					}
					dispatch(rebuildVariablesDataCategories({ entry: parsedEntry }));
				});
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

// CONFLICTED
const setConflictedDataAction = (
	payload: ActionPayload<SetConflictedDataAction>
): SetConflictedDataAction => ({
	type: ActionTypes.SET_CONFLICTED_DATA,
	payload
});

export const setConflictedData =
	(data: Nullable<ConflictedData>): Thunk =>
	async dispatch => {
		const activity = createActivity({ type: ActionTypes.SET_CONFLICTED_DATA, dispatch });

		try {
			activity.begin();

			dispatch(setConflictedDataAction(data));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const updateEntryAction = (payload: ActionPayload<UpdateEntryAction>): UpdateEntryAction => ({
	type: ActionTypes.UPDATE_ENTRY,
	payload
});

export const updateEntry =
	(
		input: {
			entryValues: EntryValues;
			entryStatus?: EntryStatus;
			options?: {
				updateEntry?: boolean;
				updateStatus?: boolean;
			};
			draftEntryId?: string;
			ignoreInputValidation?: boolean;
		},
		callbacks?: {
			onUniqueError?(variableNames: string[]): void;
		}
	): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_ENTRY, dispatch });

		const {
			entryValues,
			entryStatus: statusInput = null,
			options = {
				updateEntry: true,
				updateStatus: false
			},
			draftEntryId,
			ignoreInputValidation
		} = input;

		const { isPatientLoggedIn } = getState().auth;

		try {
			activity.begin();

			const {
				auth: { isPatientLoggedIn },
				data: {
					projects: { projectId },
					entries: {
						rows: { byId: entriesById, prevEntry }, // prevEntry is the one that might be in conflict with oldEntry(entriesById[entryId])
						metadata: { entryId },
						errors: { data: entriesErrors },
						statuses: { byId: statusesById }
					},
					variables: { byProjectId: variablesByProjectId },
					statuses: { byProjectId: statusesByProjectId }
				}
			} = getState();

			if (projectId && entryId) {
				const oldEntry = entriesById.entries[entryId];
				const oldStatus = cloneDeep(statusesById.current[entryId]);

				let newEntry = cloneDeep(oldEntry);
				let newEntryStatus = cloneDeep(statusInput);
				const storeVariablesData = variablesByProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);
				const { updateEntry, updateStatus } = options;

				const previousConflictData = entriesById.conflicted[entryId];
				// CHECK CONFLICTED DATA (if it already exists it means we are merging current conflicted data and should skip rebuilding conflicts)
				let entryIdToUpdate = previousConflictData?.metadata?.datasetentryid ?? entryId;
				if (
					isEmpty(previousConflictData?.statuses) &&
					isEmpty(previousConflictData?.values)
				) {
					const { entry: previousApiEntry, entryStatus: previousStatus } =
						await context.api.data.entries().getLatestEntry({
							datasetentryid: entryId,
							projectId: Number(projectId)
						});
					entryIdToUpdate = previousApiEntry.datasetentryid;

					const [previousEntry] = parseEntries(
						[previousApiEntry],
						variablesData.variablesMap
					);
					const columns = buildEntriesTableColumns(variablesData);

					// COMPARE PREVIOUS VS CURRENT(NOT SUBMITTED) TO GET 'UNSYNCED' CHANGES
					const unsyncedValues = objectDifference(oldEntry, previousEntry) as Entry;
					let unsyncedStatus: EntryStatus | undefined = undefined;
					if (oldStatus !== undefined && !isEqual(oldStatus, previousStatus)) {
						unsyncedStatus = previousStatus;
					}

					const changedValues = withoutEntryMetadata(unsyncedValues);
					const conflictedData = getConflictedData({
						prevValues: changedValues,
						currentValues: entryValues,
						currentStatus: statusInput?.variableName ? statusInput : null,
						prevStatus: unsyncedStatus
					});

					if (conflictedData) {
						dispatch(
							setConflictedDataAction({
								values: conflictedData.values ?? {},
								statuses: conflictedData.statuses ?? {},
								metadata: {
									user: previousEntry.enteredbyuser,
									date: previousEntry.lastmodifieddate,
									datasetentryid: previousEntry.datasetentryid
								}
							})
						);
						dispatch(
							getConflictEntryAction({
								projectId,
								columns,
								entry: previousEntry,
								entryStatus: previousStatus
							})
						);
						return;
					}
				}

				const conflictedEntry = prevEntry ?? oldEntry;

				const variablesMap = variablesByProjectId[projectId].initial.variables.byName;
				const partialEntryValues = preparePartialEntryValues(conflictedEntry, entryValues);
				const apiEntryValues = parseFormValuesForAPI(partialEntryValues, variablesMap);

				// HAS FORM DATA TO UPDATE
				if (updateEntry) {
					const { entry: updatedEntry, uniqueErrorVariableNames } = await context.api.data
						.entries()
						.updateEntry({
							projectId: Number(projectId),
							datasetentryid: entryIdToUpdate,
							observationData: apiEntryValues,
							...(draftEntryId !== undefined && {
								draftEntryRevisionId: draftEntryId
							}),
							...(ignoreInputValidation !== undefined && {
								ignoreInputValidation: true
							})
						});

					if (uniqueErrorVariableNames && uniqueErrorVariableNames.length > 0) {
						return callbacks?.onUniqueError?.(uniqueErrorVariableNames);
					}

					if (updatedEntry) {
						newEntry = updatedEntry;

						track({
							eventName: 'entry_updated',
							data: {
								entryHasStatus: !!statusInput
							}
						});

						// populate entries with Names for enteredbyuser and ownedbyuser
						await fetchAndAddNamesFromIds([newEntry], dispatch, getState, context);

						const defaultEntryStatus = extractStatusFromEntry(updatedEntry);

						newEntryStatus = newEntryStatus ?? defaultEntryStatus;

						// Give some time to backend to create revisions
						await new Promise(resolve => setTimeout(resolve, FETCH_DEBOUNCE_TIME));
					}
				}

				// HAS STATUS TO UPDATE
				if (updateStatus) {
					const response = await context.api.data.entries().getLatestEntry({
						projectId: Number(projectId),
						datasetentryid: entryId
					});
					const newStatus = previousConflictData?.statuses.current ?? statusInput;
					const observationData = generateStatusObservationData(
						newStatus,
						response.entryStatus
					);
					const updateEntryStatusData = await context.api.data
						.entries()
						.updateEntryStatus(
							Number(projectId),
							newEntry.datasetentryid,
							observationData
						);

					newEntry.datasetentryid = updateEntryStatusData.newEntryId;
					newEntryStatus = updateEntryStatusData.updatedEntryStatus;

					// Give some time to backend to create revisions
					await new Promise(resolve => setTimeout(resolve, FETCH_DEBOUNCE_TIME));
				}

				// THIS MUTATES THE 2nd parameter (`row`) field with the new uploaded files ID's
				await dispatch(storeEntryFiles(newEntry.datasetentryid, newEntry));

				const columns = buildEntriesTableColumns(variablesData, {
					hasStatusColumn: statusesByProjectId[projectId].names.length > 0
				});

				batch(() => {
					// remove local entry files
					dispatch(deleteAllLocalEntryFilesAction());

					dispatch(
						setEntryStatus({
							status: newEntryStatus,
							entryId: newEntry.datasetentryid,
							oldEntryId: entryId
						})
					);

					// IF IS PROM PATIENT => DONT GET REVISIONS => PATIENT FORM DOESN'T HAVE REVISIONS
					if (!isPatientLoggedIn) {
						// RESET REVISION ID
						dispatch(setRevisionId({ revisionId: null }));
					}

					const [parsedEntry] = parseEntries([newEntry], variablesData.variablesMap);

					dispatch(
						updateEntryAction({
							projectId,
							oldEntryId: entryId,
							entry: parsedEntry,
							entryStatus: newEntryStatus,
							columns
						})
					);
					dispatch(rebuildVariablesDataCategories({ entry: newEntry }));
				});

				dispatch(getEntryRevisions());

				/**
				 * Refetch entries to ensure latest data of `entriesErrors`
				 */
				if (entriesErrors) dispatch(getEntries({}));
			}
		} catch (e: any) {
			activity.error({ error: e.message });

			// IF IS PROM PATIENT => AVOID GETTING NEW ENTRIES
			if (!isPatientLoggedIn) {
				/*
				CASE: ENTRY DOES NOT EXIST ANYMORE
				FETCH LATEST ENTRIES
			*/

				dispatch(getEntries({}));
			}
		} finally {
			activity.end();
		}
	};

const updateEntryStatusAction = (
	payload: ActionPayload<UpdateEntryStatusAction>
): UpdateEntryStatusAction => ({
	type: ActionTypes.UPDATE_ENTRY_STATUS,
	payload
});

export const updateEntryStatus =
	(input: { entryStatus?: EntryStatus; ignoreInputValidation?: boolean }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_ENTRY, dispatch });

		const { entryStatus: statusInput = null } = input;

		const { isPatientLoggedIn } = getState().auth;

		try {
			activity.begin();

			const {
				auth: { isPatientLoggedIn },
				data: {
					projects: { projectId },
					entries: {
						rows: { byId: entriesById }, // prevEntry is the one that might be in conflict with oldEntry(entriesById[entryId])
						metadata: { entryId },
						errors: { data: entriesErrors }
					},
					variables: { byProjectId: variablesByProjectId },
					statuses: { byProjectId: statusesByProjectId }
				}
			} = getState();

			if (projectId && entryId) {
				const oldEntry = entriesById.entries[entryId];

				const newEntry = cloneDeep(oldEntry);
				let newEntryStatus = cloneDeep(statusInput);
				const storeVariablesData = variablesByProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const previousConflictData = entriesById.conflicted[entryId];

				// HAS STATUS TO UPDATE
				const response = await context.api.data.entries().getLatestEntry({
					projectId: Number(projectId),
					datasetentryid: entryId
				});
				const newStatus = previousConflictData?.statuses.current ?? statusInput;
				const observationData = generateStatusObservationData(
					newStatus,
					response.entryStatus
				);
				const updateEntryStatusData = await context.api.data
					.entries()
					.updateEntryStatus(Number(projectId), newEntry.datasetentryid, observationData);

				newEntry.datasetentryid = updateEntryStatusData.newEntryId;
				newEntryStatus = updateEntryStatusData.updatedEntryStatus;

				// Give some time to backend to create revisions
				await new Promise(resolve => setTimeout(resolve, FETCH_DEBOUNCE_TIME));

				// THIS MUTATES THE 2nd parameter (`row`) field with the new uploaded files ID's
				await dispatch(storeEntryFiles(newEntry.datasetentryid, newEntry));

				const columns = buildEntriesTableColumns(variablesData, {
					hasStatusColumn: statusesByProjectId[projectId].names.length > 0
				});

				batch(() => {
					// remove local entry files
					dispatch(deleteAllLocalEntryFilesAction());

					dispatch(
						setEntryStatus({
							status: newEntryStatus,
							entryId: newEntry.datasetentryid,
							oldEntryId: entryId
						})
					);

					// IF IS PROM PATIENT => DONT GET REVISIONS => PATIENT FORM DOESN'T HAVE REVISIONS
					if (!isPatientLoggedIn) {
						// RESET REVISION ID
						dispatch(setRevisionId({ revisionId: null }));
					}

					const [parsedEntry] = parseEntries([newEntry], variablesData.variablesMap);

					dispatch(
						updateEntryStatusAction({
							projectId,
							oldEntryId: entryId,
							entry: parsedEntry,
							entryStatus: newEntryStatus,
							columns
						})
					);
					dispatch(rebuildVariablesDataCategories({ entry: newEntry }));
				});

				dispatch(getEntryRevisions());

				/**
				 * Refetch entries to ensure latest data of `entriesErrors`
				 */
				if (entriesErrors) dispatch(getEntries({}));
			}
		} catch (e: any) {
			activity.error({ error: e.message });

			// IF IS PROM PATIENT => AVOID GETTING NEW ENTRIES
			if (!isPatientLoggedIn) {
				/*
					CASE: ENTRY DOES NOT EXIST ANYMORE
					FETCH LATEST ENTRIES
				*/

				dispatch(getEntries({}));
			}
		} finally {
			activity.end();
		}
	};

const deleteEntryAction = (payload: ActionPayload<DeleteEntryAction>): DeleteEntryAction => ({
	type: ActionTypes.DELETE_ENTRY,
	payload
});

export const deleteEntry = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.DELETE_ENTRY, dispatch });

	try {
		activity.begin();

		const {
			projects: { projectId },
			entries: {
				metadata: { entryId },
				errors: { data: entriesErrors }
			}
		} = getState().data;

		if (projectId && entryId) {
			await context.api.data.entries().deleteEntry(Number(projectId), entryId);

			track({
				eventName: 'entry_deleted'
			});

			navigationHubEvent().dispatch({
				replace: ({ routes, promOrProject }) =>
					routes[promOrProject].dataset.view(projectId)
			});

			dispatch(deleteEntryAction({ projectId, entryId }));

			/**
			 * Refetch entries to ensure latest data of `entriesErrors`, else just refetch the variables
			 */
			if (entriesErrors) {
				dispatch(getEntries({}));
			} else {
				const { variablesData } = await context.api.data.variables().getVariables({
					projectId: Number(projectId)
				});
				dispatch(getVariablesAction({ projectId, variablesData }));
			}
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const hydrateEntryAction = (payload: ActionPayload<HydrateEntryAction>): HydrateEntryAction => ({
	type: ActionTypes.HYDRATE_ENTRY,
	payload
});

export const hydrateEntry =
	(entryId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.HYDRATE_ENTRY, dispatch });

		try {
			activity.begin();

			const {
				projects: { projectId },
				variables: { byProjectId: variablesByProjectId },
				statuses: { byProjectId: statusesByProjectId }
			} = getState().data;

			if (projectId) {
				const { entry, entryStatus } = await context.api.data.entries().getEntry({
					projectId: Number(projectId),
					datasetentryid: entryId
				});

				const storeVariablesData = variablesByProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);

				const [parsedEntry] = parseEntries([entry], variablesData.variablesMap);

				// populate entries with Names for enteredbyuser and ownedbyuser
				await fetchAndAddNamesFromIds([parsedEntry], dispatch, getState, context);

				const columns = buildEntriesTableColumns(variablesData, {
					hasStatusColumn: statusesByProjectId[projectId].names.length > 0
				});

				dispatch(
					hydrateEntryAction({
						entry: parsedEntry,
						entryStatus,
						columns
					})
				);
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const setEntryIdAction = (payload: ActionPayload<SetEntryIdAction>): SetEntryIdAction => ({
	type: ActionTypes.SET_ENTRY_ID,
	payload
});

export const setEntryId =
	(payload: ActionPayload<SetEntryIdAction>): Thunk =>
	(dispatch, getState) => {
		const { entryId } = getState().data.entries.metadata;

		batch(() => {
			dispatch(setEntryIdAction(payload));

			/**
			 * Resets entry fetched data so when the user
			 * comes back to the entry its as fresh as possible
			 */
			if (payload.entryId === null && entryId !== null) {
				dispatch(resetEntryFetchedData({ entryId }));
			}
		});
	};

export const setEntriesSearchTerm = (
	payload: ActionPayload<SetEntriesSearchTermAction>
): SetEntriesSearchTermAction => ({
	type: ActionTypes.SET_ENTRIES_SEARCH_TERM,
	payload
});

export const setEntriesTableParamsAction = (
	payload: ActionPayload<SetEntriesTableParamsAction>
): SetEntriesTableParamsAction => ({
	type: ActionTypes.SET_ENTRIES_TABLE_PARAMS,
	payload
});

export const setEntriesTableParams =
	({
		pageSize,
		pageIndex,
		username
	}: {
		pageIndex: number;
		pageSize?: number;
		username?: string;
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.SET_ENTRIES_TABLE_PARAMS, dispatch });

		const {
			data: {
				projects: { projectId },
				entries: {
					metadata: { pageSize: oldPageSize }
				},
				statuses: { byProjectId: statusesByProjectId },
				filters: { dataset: filtersStoreData }
			},
			ui: {
				tables: {
					byName: { [TableName.Entries]: entryTable }
				},
				i18n: { translations }
			}
		} = getState();

		try {
			activity.begin({ payload: { projectId } });
			if (projectId) {
				const filters = filtersStoreData.byProjectId[projectId].active.map(
					(filterId: string) => filtersStoreData.byId[filterId]
				);

				const { variablesData } = await context.api.data.variables().getVariables({
					projectId: Number(projectId)
				});
				const { variablesMap } = variablesData;

				const parsedFilters = parseApiEntryFilters(filters, variablesMap);

				if (username) {
					parsedFilters.push({
						filterType: 'owner',
						value: username
					});
				}

				const { entries, statuses, errors, totalCount } = await context.api.data
					.entries()
					.getEntries({
						projectId: Number(projectId),
						filters: parsedFilters,
						maxPageSize: pageSize ?? oldPageSize,
						startAt: pageSize !== oldPageSize ? 0 : pageIndex * pageSize,
						...(entryTable?.activeSort
							? {
									sorting: parseActiveSortToApiSort(entryTable.activeSort)
							  }
							: {})
					});

				// populate entries with Names for enteredbyuser and ownedbyuser
				await fetchAndAddNamesFromIds(entries, dispatch, getState, context);

				const translateMap = TIME_DURATION_KEYS.reduce(
					(acc, key) => ({
						...acc,
						[key]: translations?.timeDurationPlaceholder.prefix[key]
					}),
					{}
				);

				const parsedEntries = parseEntries(
					entries,
					variablesData.variablesMap,
					...(values(translateMap).every(val => !!val) ? [translateMap as StringMap] : [])
				);

				batch(() => {
					dispatch(getVariablesAction({ projectId, variablesData }));
					dispatch(
						setEntriesTableParamsAction({
							pageSize,
							pageIndex: pageSize !== oldPageSize ? 0 : pageIndex
						})
					);
					dispatch(
						getEntriesAction({
							projectId,
							entries: parsedEntries,
							statuses,
							errors,
							totalCount
						})
					);
					dispatch(rebuildFilters());
					dispatch(rebuildAnalyses());
				});

				/**
				 * Detect new statuses from `getEntries` response and refetch statuses
				 */
				if (statuses && statusesByProjectId[projectId]) {
					const statusesData = statusesByProjectId[projectId];

					const { byName, fetched } = statusesData;

					if (fetched) {
						let refetchStatuses = false;

						for (const entryId in statuses) {
							const entryStatus: EntryStatusValue = statuses[entryId];

							if (!(entryStatus.variableName in byName)) {
								refetchStatuses = true;
								break;
							}
						}

						if (refetchStatuses) dispatch(getStatuses());
					}
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: { projectId } });
		} finally {
			activity.end();
		}
	};

export const getDownloadableDataset =
	(input: {
		removeLineShifts: boolean;
		exportFormat: DownloadFormat;
		datasets: { datasetName: string | null }[];
		datasetType: ExportDatasetType;
		amountOfData?: ExportOptions;
		variables?: AnalysisVariable[];
		categoryLabels?: boolean;
	}): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_DOWNLOADABLE_ENTRIES, dispatch });

		try {
			activity.begin();
			const {
				projects: { projectId, byId: projectsById },
				filters: {
					dataset: { byId: filtersById, byProjectId: filtersByProjectId }
				},
				variables: { byProjectId: variablesByProjectId }
			} = getState().data;

			if (projectId && projectId in projectsById) {
				const {
					removeLineShifts,
					exportFormat,
					datasets,
					datasetType,
					amountOfData,
					categoryLabels,
					variables
				} = input;

				const variablesStoreData = variablesByProjectId[projectId].current;

				const { variableSetsMap, variablesMap } =
					buildVariablesDataFromStoreData(variablesStoreData);

				const ruleNameToRuleMap =
					buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

				const ruleNameToAggVarMap = Object.entries(ruleNameToRuleMap).reduce(
					(acc, [ruleName, rule]) => {
						return {
							...acc,
							[ruleName]: variablesMap[rule.aggregator.variableName]
						};
					},
					{} as VariablesMap
				);

				const injectedAggRuleVariablesMap = {
					...variablesMap,
					...ruleNameToAggVarMap
				};

				const filters =
					filtersByProjectId[projectId]?.active?.map(id => filtersById[id]) ?? [];

				/*
				 * This removes the "invalid" key from the current filters since the BE model does not support it
				 */
				const filtersWithoutInvalid = filters.map(filter => {
					const { invalid, ...rest } = filter;
					return rest;
				});

				({ filtersWithoutInvalid });

				const parsedFilters = parseApiEntryFilters(
					filtersWithoutInvalid,
					injectedAggRuleVariablesMap
				);

				dispatch(resetExportWizardAction());

				const { url, emptyDataset, populatedDataset } = await context.api.data
					.entries()
					.getDownloadableDataset({
						projectId: Number(projectId),
						filters: parsedFilters,
						removeLineShifts,
						amountOfData: amountOfData ?? ExportOptions.AllData,
						datasets: datasets,
						exportFormat: exportFormat,
						categoryLabels,
						...(variables ? { variables } : {})
					});

				if (datasetType === ExportDatasetType.Main) {
					downloadFileFromUrl(url);
				} else {
					dispatch(
						setSeriesExportFileAndNamesAction({
							projectId,
							downloadUrl: url,
							emptySeriesNames: emptyDataset,
							erroredSeriesNames: [],
							validSeriesNames: populatedDataset
						})
					);
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const getEntryFileAction = (payload: ActionPayload<GetEntryFileAction>): GetEntryFileAction => ({
	type: ActionTypes.GET_ENTRY_FILE,
	payload
});

export const getEntryFile =
	(id: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_ENTRY_FILE, dispatch });

		try {
			activity.begin({ payload: id });

			const {
				projects: { projectId },
				entries: {
					metadata: { entryId }
				},
				revisions: {
					metadata: { revisionId }
				}
			} = getState().data;

			if (projectId) {
				const fileResponse: EntryFileResponse = await context.api.data
					.entries()
					.getEntryFile(id, projectId, revisionId ? revisionId : entryId ?? '');

				const file = parseGetFileResponse(fileResponse, id);

				dispatch(getEntryFileAction({ file }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: id });
		} finally {
			activity.end();
		}
	};

const getEntryFilesAction = (payload: ActionPayload<GetEntryFilesAction>): GetEntryFilesAction => ({
	type: ActionTypes.GET_ENTRY_FILES,
	payload
});

export const getEntryFiles =
	(fileIds: string[], entryId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_ENTRY_FILES, dispatch });

		try {
			activity.begin({ payload: entryId });

			const {
				projects: { projectId }
			} = getState().data;

			if (projectId && entryId) {
				const filesResponse: EntryFilesResponse = await context.api.data
					.entries()
					.getEntryFiles(fileIds, projectId, entryId);

				const files = parseGetFilesResponse(filesResponse);

				dispatch(getEntryFilesAction({ files }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: entryId });
		} finally {
			activity.end();
		}
	};

export const addLocalEntryFileAction = (
	payload: ActionPayload<SaveLocalEntryFileAction>
): SaveLocalEntryFileAction => ({
	type: ActionTypes.ADD_LOCAL_ENTRY_FILE,
	payload
});

/**
 * Sends request to store given entry file and replaces/mutates
 * second paramenter 'row' to include the new id of the stored file
 * @param fileToStore
 * @param entryId
 * @param row
 */
export const storeEntryFile =
	(fileToStore: FileToStore, entryId: string, row: EntryValues): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.STORE_ENTRY_FILE_REQ, dispatch });

		try {
			activity.begin({ payload: fileToStore.variableName });

			const {
				data: {
					projects: { projectId }
				}
			} = getState();

			const storeResponse: StringMap = await context.api.data
				.entries()
				.storeFile(Number(projectId), entryId, fileToStore);

			// mutate row and insert the new file id value in the dataset row
			row[fileToStore.variableName] = storeResponse[fileToStore.variableName];
		} catch (e: any) {
			activity.error({ error: e.message, payload: fileToStore.variableName });
		} finally {
			activity.end();
		}
	};

/**
 * Sends request to store entry files that are in toStore list from redux state and
 * replaces/mutates second paramenter 'row' to include the new ids of the stored files
 * @param entryId
 * @param row
 */
export const storeEntryFiles =
	(entryId: string, row: EntryValues): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.STORE_ENTRY_FILES_REQ, dispatch });

		const {
			data: {
				projects: { projectId },
				entries: {
					files: { toStore, byId }
				}
			}
		} = getState();

		if (toStore && toStore.length) {
			try {
				activity.begin({ payload: toStore });

				const filesToStore: FileToStore[] = [];
				let filesTotalSize = 0;

				toStore.forEach(id => {
					const entryFile = byId[id] as LocalEntryFile;

					if (entryFile) {
						filesTotalSize = filesTotalSize + entryFile.size;
						filesToStore.push({
							variableName: entryFile.variableName,
							fileName: entryFile.fileName,
							base64Bytes: entryFile.base64
						});
					}
				});

				if (filesTotalSize < ENTRY_FILE_SIZE_LIMIT) {
					// if files size is not too large, store all files with one call
					const storeResponse: StringMap = await context.api.data
						.entries()
						.storeFiles(Number(projectId), entryId, filesToStore);

					// mutate row and insert the new file ids values in the dataset row
					Object.values(filesToStore).forEach(file => {
						if (storeResponse[file.variableName]) {
							row[file.variableName] = storeResponse[file.variableName];
						}
					});
				} else {
					// if size of the files is too large, store them one by one
					// cannot do calls in parallel because they mutate the same entry row
					for (const file of filesToStore) {
						await dispatch(storeEntryFile(file, entryId, row));
					}
				}
			} catch (e: any) {
				activity.error({ error: e.message, payload: toStore });
			} finally {
				activity.end();
			}
		}
	};

export const deleteLocalEntryFileAction = (
	payload: ActionPayload<DeleteLocalEntryFileAction>
): DeleteLocalEntryFileAction => ({
	type: ActionTypes.DELETE_LOCAL_ENTRY_FILE,
	payload
});

export const setEntryStatus = (
	payload: ActionPayload<SetEntryStatusAction>
): SetEntryStatusAction => ({
	type: ActionTypes.SET_ENTRY_STATUS,
	payload
});

export const deleteAllLocalEntryFilesAction = (): DeleteAllLocalEntryFilesAction => ({
	type: ActionTypes.DELETE_ALL_LOCAL_ENTRY_FILES
});

export const createEntrySurvey =
	({ entryValues }: { entryValues: EntryValues }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.CREATE_ENTRY_SURVEY, dispatch });

		try {
			activity.begin();

			const {
				data: {
					variables: { byProjectId: variablesByProjectId },
					patients: { surveyParams },
					entries: { files }
				}
			} = getState();

			if (surveyParams.manual) {
				const { projectId, distributionEntryId, hashString, hashKey } = surveyParams.manual;

				const variablesMap = variablesByProjectId[projectId].initial.variables.byName;
				const validEntryValues = prepareValidEntryValues(entryValues);
				const apiEntryValues = parseFormValuesForAPI(validEntryValues, variablesMap);

				const filesToStore: FileToStore[] = [];

				files.toStore.forEach(fileId => {
					const entryFile = files.byId[fileId] as LocalEntryFile;

					if (entryFile) {
						const fileToStore: FileToStore = {
							variableName: entryFile.variableName,
							fileName: entryFile.fileName,
							base64Bytes: entryFile.base64
						};

						filesToStore.push(fileToStore);
					}
				});

				await context.api.data.entries().createEntrySurvey({
					projectId: Number(projectId),
					distributionEntryId: Number(distributionEntryId),
					hashString,
					hashKey,
					observationData: apiEntryValues,
					...(filesToStore.length > 0 && { files: filesToStore })
				});
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

/**
 * This action will get all the necessary data for patient survey
 * - variables
 * - groups
 * - forms
 */
export const getSurveyData = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_SURVEY_DATA, dispatch });

	try {
		activity.begin();

		const {
			data: {
				patients: { surveyParams }
			}
		} = getState();

		if (surveyParams.manual) {
			const { projectId, distributionEntryId, hashString, hashKey } = surveyParams.manual;

			/**
			 * GET VARIABLES DATA, FORMS AND DEPENDENCIES
			 */
			const { variablesData, formsData, dependenciesData } = await context.api.data
				.entries()
				.getSurveyData({
					projectId: Number(projectId),
					distributionEntryId: Number(distributionEntryId),
					hashString,
					hashKey
				});

			batch(() => {
				dispatch(getVariablesAction({ projectId, variablesData }));
				dispatch(getFormsAction({ projectId, formsData }));
				dispatch(getDependenciesAction({ projectId, dependenciesData }));
			});
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const rebuildEntriesAction = (
	payload: ActionPayload<RebuildEntriesTableAction>
): RebuildEntriesTableAction => ({
	type: ActionTypes.REBUILD_ENTRIES,
	payload
});

/**
 * Rebuilds table structure (columns and rows) based on variables data
 */
export const rebuildEntries = (): Thunk => (dispatch, getState) => {
	const {
		variables: { projectId, byProjectId: variablesByProjectId },
		statuses: { byProjectId: statusesByProjectId }
	} = getState().data;

	if (projectId && variablesByProjectId[projectId]) {
		const variablesData = buildVariablesDataFromStoreData(
			variablesByProjectId[projectId].initial
		);

		const hasStatusColumn = statusesByProjectId[projectId].names.length > 0;

		const columns = buildEntriesTableColumns(variablesData, { hasStatusColumn });

		dispatch(rebuildEntriesAction({ columns }));
	}
};

/**
 * Mark Dataset page for data refetch on access
 */
export const setRefetchEntries = (): SetRefetchEntriesAction => ({
	type: ActionTypes.SET_REFETCH_ENTRIES
});

export const resetEntryFetchedData = (
	payload: ActionPayload<ResetEntryFetchedDataAction>
): ResetEntryFetchedDataAction => ({
	type: ActionTypes.RESET_ENTRY_FETCHED_DATA,
	payload
});

export const setEntriesTableVisibleColumns = (
	payload: ActionPayload<SetEntriesTableVisibleColumnsAction>
): SetEntriesTableVisibleColumnsAction => ({
	type: ActionTypes.SET_ENTRIES_TABLE_VISIBLE_COLUMNS,
	payload
});

const getEntryDraftAction = (payload: ActionPayload<GetEntryDraftAction>): GetEntryDraftAction => ({
	type: ActionTypes.GET_ENTRY_DRAFT,
	payload
});

export const getEntryDraft = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_ENTRY_DRAFT, dispatch });

	try {
		activity.begin();

		const {
			projectId,
			metadata: { entryId }
		} = getState().data.entries;

		if (projectId) {
			let entryDraft: EntryDraft = null;

			// UPDATE ENTRY ROUTE
			if (entryId !== null) {
				const output = await context.api.data.entries().getEntryDraft({
					projectId: Number(projectId),
					entryId
				});

				entryDraft = output.entryDraft;
			}
			// CREATE ENTRY ROUTE
			else {
				const output = await context.api.data.entries().getNewEntryDraft({
					projectId: Number(projectId)
				});

				entryDraft = output.entryDraft;
			}

			const storeVariablesData = getState().data.variables.byProjectId[projectId].initial;

			const draft = entryDraft
				? {
						...entryDraft,
						values: parseEntries(
							//@ts-ignore entries interface mismatch
							[{ ...entryDraft?.values }],
							storeVariablesData.variables.byName
						)[0]
				  }
				: null;

			dispatch(getEntryDraftAction({ entryDraft: draft, entryId }));
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const saveEntryDraftAction = (
	payload: ActionPayload<SaveEntryDraftAction>
): SaveEntryDraftAction => ({
	type: ActionTypes.SAVE_ENTRY_DRAFT,
	payload
});

export const saveEntryDraft =
	(
		input: { entryValues: EntryValues; draftEntryId?: string; organizationId?: string },
		callbacks?: {
			onUniqueError?(variableNames: string[]): void;
		}
	): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.SAVE_ENTRY_DRAFT, dispatch });

		const { entryValues, draftEntryId, organizationId } = input;

		try {
			activity.begin();

			const {
				entries: {
					projectId,
					metadata: { entryId }
				},
				variables: { byProjectId: variablesByProjectId }
			} = getState().data;

			if (projectId) {
				const variablesMap = variablesByProjectId[projectId].initial.variables.byName;
				const apiEntryValues = parseFormValuesForAPI(entryValues, variablesMap);

				let entryDraft: EntryDraft = null;

				// UPDATE ENTRY ROUTE
				if (entryId !== null) {
					const output = await context.api.data.entries().saveEntryDraft(
						{
							projectId: Number(projectId),
							datasetentryid: entryId,
							observationData: apiEntryValues,
							...(draftEntryId !== undefined && {
								draftEntryRevisionId: draftEntryId
							})
						},
						{
							onUniqueError: callbacks?.onUniqueError
						}
					);

					entryDraft = output.entryDraft;
				}
				// CREATE ENTRY ROUTE
				else {
					const output = await context.api.data.entries().saveNewEntryDraft(
						{
							projectId: Number(projectId),
							observationData: apiEntryValues,
							...(organizationId && { organizationId: Number(organizationId) }),
							...(draftEntryId !== undefined && { draftEntryId })
						},
						{
							onUniqueError: callbacks?.onUniqueError
						}
					);

					entryDraft = output.entryDraft;
				}

				/**
				 * ASSIGN DRAFT VALUES
				 *
				 * TODO: MAYBE BACKEND CAN FIX THE RESPONSE AND SEND ONLY THE VALUES
				 * (AVOID ALL SYSTEM GENERATED BEING IN THE SAME DICT)
				 */
				if (entryDraft) entryDraft.values = entryValues;

				dispatch(saveEntryDraftAction({ entryDraft, entryId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const resetCreateEntryDraftAction = (): ResetCreateEntryDraftAction => ({
	type: ActionTypes.RESET_CREATE_ENTRY_DRAFT
});

export const getSeriesEntriesAction = (
	payload: ActionPayload<GetSeriesEntriesAction>
): GetSeriesEntriesAction => ({
	type: ActionTypes.GET_SERIES_ENTRIES,
	payload
});

export const getSeriesEntries =
	(setName: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.GET_SERIES_ENTRIES, dispatch });

		try {
			activity.begin({ payload: setName });

			const {
				entries: {
					projectId,
					rows: {
						byId: { entries: entriesById }
					},
					metadata: { entryId }
				},
				variables: { byProjectId }
			} = getState().data;

			if (projectId && entryId) {
				const owner = entriesById[entryId].ownedbyuser;

				const { entries } = await context.api.data.entries().getSeriesEntries({
					projectId: Number(projectId),
					parentDatasetEntryId: entryId,
					set: {
						setName
					},
					sorting: {
						variableName: 'lastmodifieddate',
						direction: 'ASC'
					}
				});

				// populate entries with Names for enteredbyuser and ownedbyuser
				await fetchAndAddNamesFromIds(entries, dispatch, getState, context, owner);

				const variablesMap = byProjectId[projectId].initial.variables.byName;

				dispatch(
					getSeriesEntriesAction({
						projectId,
						entryId,
						setName,
						entries: parseEntries(entries, variablesMap)
					})
				);
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: setName });
		} finally {
			activity.end();
		}
	};

const createSeriesEntryAction = (
	payload: ActionPayload<CreateSeriesEntryAction>
): CreateSeriesEntryAction => ({
	type: ActionTypes.CREATE_SERIES_ENTRY,
	payload
});

export const createSeriesEntry =
	(
		input: {
			setName: string;
			entryValues: EntryValues;
			organizationId?: string | undefined;
			options?: {
				setEntryId?: boolean;
			};
		},
		callbacks?: {
			onUniqueError?(variableNames: string[]): void;
		}
	): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.CREATE_SERIES_ENTRY, dispatch });

		const {
			organizationId,
			setName,
			entryValues,
			options = {
				setEntryId: false
			}
		} = input;

		try {
			activity.begin({ payload: setName });

			const {
				entries: {
					projectId,
					rows: {
						byId: { entries: entriesById }
					},
					metadata: { entryId: parentEntryId }
				},
				variables: { byProjectId: variablesByProjectId }
			} = getState().data;

			if (projectId && parentEntryId) {
				const { variables, variableSets } = variablesByProjectId[projectId].initial;

				const variableSet = variableSets.byName[setName];

				const owner = entriesById[parentEntryId].ownedbyuser;

				const variablesMap = variables.byName;
				const validEntryValues = prepareValidEntryValues(entryValues);
				const apiEntryValues = parseFormValuesForAPI(validEntryValues, variablesMap);

				const { entry } = await context.api.data.entries().createSeriesEntry(
					{
						projectId: Number(projectId),
						mainSetRevisionId: parentEntryId,
						...(organizationId && { organizationId: Number(organizationId) }),
						set: {
							setName
						},
						observationData: apiEntryValues
					},
					{
						onUniqueError: callbacks?.onUniqueError
					}
				);

				if (variableSetHasDefinedAggregation(variableSet)) {
					dispatch(getLatestEntrySimple());
				}

				const { setEntryId } = options;

				const storeVariablesData = variablesByProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);
				const variableSetVariablesData = buildVariableSetVariablesData({
					setName,
					variablesData
				});
				const columns = buildEntriesTableColumns(variableSetVariablesData);

				const [parsedEntry] = parseEntries([entry], variablesMap);
				// populate entries with Names for enteredbyuser and ownedbyuser
				await fetchAndAddNamesFromIds([parsedEntry], dispatch, getState, context, owner);

				dispatch(
					createSeriesEntryAction({
						projectId,
						parentEntryId,
						setName,
						entry: parsedEntry,
						columns,
						setEntryId
					})
				);
				dispatch(rebuildVariablesDataCategories({ entry }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: setName });
		} finally {
			activity.end();
		}
	};

// CONFLICTED
const setSeriesConflictedDataAction = (
	payload: ActionPayload<SetSeriesConflictedDataAction>
): SetSeriesConflictedDataAction => ({
	type: ActionTypes.SET_SERIES_CONFLICTED_DATA,
	payload
});

export const setSeriesConflictedData =
	(data: Nullable<ConflictedData>): Thunk =>
	async dispatch => {
		const activity = createActivity({ type: ActionTypes.SET_SERIES_CONFLICTED_DATA, dispatch });

		try {
			activity.begin();

			dispatch(setSeriesConflictedDataAction(data));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const updateSeriesEntryAction = (
	payload: ActionPayload<UpdateSeriesEntryAction>
): UpdateSeriesEntryAction => ({
	type: ActionTypes.UPDATE_SERIES_ENTRY,
	payload
});

export const updateSeriesEntry =
	(
		input: {
			setName: string;
			entryId: string;
			entryValues: EntryValues;
			options?: {
				setEntryId?: boolean;
			};
		},
		callbacks?: {
			onUniqueError?(variableNames: string[]): void;
		}
	): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_SERIES_ENTRY, dispatch });

		const {
			setName,
			entryId,
			entryValues,
			options = {
				setEntryId: false
			}
		} = input;

		try {
			activity.begin({ payload: setName });

			const {
				entries: {
					projectId,
					subEntries,
					metadata: { entryId: parentEntryId },
					rows: { prevEntry }
				},
				variables: { byProjectId: variablesByProjectId }
			} = getState().data;

			if (projectId && parentEntryId) {
				const { variables, variableSets } = variablesByProjectId[projectId].initial;

				const variableSet = variableSets.byName[setName];

				const oldEntry =
					subEntries.byEntryId.current[parentEntryId].bySetName[setName].rows.byId[
						entryId
					];

				const previousConflictData = subEntries.byEntryId.conflicted[entryId];
				let entryIdToUpdate = previousConflictData?.metadata?.datasetentryid ?? entryId;
				// CHECK CONFLICTED DATA (if it already exists it means we are merging current conflicted data and should skip rebuilding conflicts)
				if (
					isEmpty(previousConflictData?.statuses) &&
					isEmpty(previousConflictData?.values)
				) {
					const { entry: unparsedPrevEntry } = await context.api.data
						.entries()
						.getLatestEntry({
							datasetentryid: entryId,
							projectId: Number(projectId),
							set: { setName }
						});
					entryIdToUpdate = unparsedPrevEntry.datasetentryid;

					const [prevEntry] = parseEntries([unparsedPrevEntry], variables.byName);

					const storeVariablesData = variablesByProjectId[projectId].initial;
					const variablesData = buildVariablesDataFromStoreData(storeVariablesData);
					const variableSetVariablesData = buildVariableSetVariablesData({
						setName,
						variablesData
					});
					const columns = buildEntriesTableColumns(variableSetVariablesData);

					// COMPARE PREVIOUS VS CURRENT(NOT SUBMITTED) TO GET 'UNSYNCED' CHANGES
					const unsyncedValues = objectDifference(oldEntry, prevEntry) as Entry;

					const changedValues = withoutEntryMetadata(unsyncedValues);
					const conflictedData = getConflictedData({
						prevValues: changedValues,
						currentValues: entryValues
					});

					if (conflictedData?.values) {
						dispatch(
							setSeriesConflictedDataAction({
								...conflictedData,
								metadata: {
									user: prevEntry.enteredbyuser,
									date: prevEntry.lastmodifieddate,
									datasetentryid: prevEntry.datasetentryid
								}
							})
						);

						dispatch(
							getConflictEntryAction({
								columns,
								entry: prevEntry,
								entryStatus: null,
								projectId
							})
						);
						return;
					}
				}

				const conflictedEntry = prevEntry ?? oldEntry;

				const variablesMap = variables.byName;
				const partialEntryValues = preparePartialEntryValues(conflictedEntry, entryValues);
				const apiEntryValues = parseFormValuesForAPI(partialEntryValues, variablesMap);

				const { entry: updatedEntry } = await context.api.data.entries().updateSeriesEntry(
					{
						projectId: Number(projectId),
						datasetentryid: entryIdToUpdate,
						set: {
							setName
						},
						observationData: apiEntryValues
					},
					{
						onUniqueError: callbacks?.onUniqueError
					}
				);

				await fetchAndAddNamesFromIds([updatedEntry], dispatch, getState, context);

				if (variableSetHasDefinedAggregation(variableSet)) {
					dispatch(getLatestEntrySimple());
				}

				const { setEntryId } = options;

				const storeVariablesData = variablesByProjectId[projectId].initial;
				const variablesData = buildVariablesDataFromStoreData(storeVariablesData);
				const variableSetVariablesData = buildVariableSetVariablesData({
					setName,
					variablesData
				});
				const columns = buildEntriesTableColumns(variableSetVariablesData);

				const [parsedUpdatedEntry] = parseEntries([updatedEntry], variables.byName);

				dispatch(
					updateSeriesEntryAction({
						projectId,
						parentEntryId,
						setName,
						oldEntryId: entryId,
						entry: parsedUpdatedEntry,
						columns,
						setEntryId
					})
				);
				dispatch(rebuildVariablesDataCategories({ entry: updatedEntry }));
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: setName });
		} finally {
			activity.end();
		}
	};

const deleteSeriesEntryAction = (
	payload: ActionPayload<DeleteSeriesEntryAction>
): DeleteSeriesEntryAction => ({
	type: ActionTypes.DELETE_SERIES_ENTRY,
	payload
});

export const deleteSeriesEntry =
	({ setName, entryId }: { setName: string; entryId: string }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.DELETE_SERIES_ENTRY, dispatch });

		try {
			activity.begin({ payload: setName });

			const {
				entries: {
					projectId,
					metadata: { entryId: parentEntryId }
				},
				variables: { byProjectId: variablesByProjectId }
			} = getState().data;

			if (projectId && parentEntryId) {
				const { variableSets } = variablesByProjectId[projectId].initial;

				const variableSet = variableSets.byName[setName];

				await context.api.data.entries().deleteSeriesEntry({
					projectId: Number(projectId),
					datasetentryid: entryId,
					set: {
						setName
					}
				});

				if (variableSetHasDefinedAggregation(variableSet)) {
					dispatch(getLatestEntrySimple());
				}

				dispatch(
					deleteSeriesEntryAction({
						projectId,
						parentEntryId,
						setName,
						entryId
					})
				);
			}
		} catch (e: any) {
			activity.error({ error: e.message, payload: setName });
		} finally {
			activity.end();
		}
	};

export const getSeriesEntriesCountAction = (
	payload: ActionPayload<GetSeriesEntriesCountAction>
): GetSeriesEntriesCountAction => ({
	type: ActionTypes.GET_SERIES_ENTRIES_COUNT,
	payload
});

export const getSeriesEntriesCount = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_SERIES_ENTRIES_COUNT,
		dispatch
	});

	try {
		activity.begin();

		const {
			entries: {
				projectId,
				metadata: { entryId }
			}
		} = getState().data;

		if (projectId && entryId) {
			const { entriesCount } = await context.api.data.entries().getSeriesEntriesCount({
				projectId: Number(projectId),
				datasetentryid: entryId
			});

			dispatch(
				getSeriesEntriesCountAction({
					projectId,
					entryId,
					entriesCount
				})
			);
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const setSelectedSeriesEntryAction = (
	payload: ActionPayload<SetSelectedSeriesEntryAction>
): SetSelectedSeriesEntryAction => ({
	type: ActionTypes.SET_SELECTED_SERIES_ENTRY,
	payload
});

export const setSelectedSeriesEntry =
	(selected: SelectedSeriesEntry): Thunk =>
	async dispatch => {
		const activity = createActivity({
			type: ActionTypes.SET_SELECTED_SERIES_ENTRY,
			dispatch
		});

		try {
			activity.begin();
			dispatch(setSelectedSeriesEntryAction({ selected }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const setEntriesTableErrorFilters = (
	payload: ActionPayload<SetEntriesTableErrorsFilterAction>
): SetEntriesTableErrorsFilterAction => ({
	type: ActionTypes.SET_ENTRIES_TABLE_ERRORS_FILTER,
	payload
});

export const addNamesFromUserIdsAction = (
	payload: ActionPayload<AddNamesFromUserIdsAction>
): AddNamesFromUserIdsAction => ({
	type: ActionTypes.ADD_NAMES_FROM_USER_IDS,
	payload
});

/**
 * Modifies the 'entries' array and populates fields for enteredbyuserwithname and ownedbyuserwithname
 * Fetches users' names from the api using ids: enteredbyuser and ownedbyuser
 */
async function fetchAndAddNamesFromIds(
	entries: Array<Entry>,
	dispatch: ThunkDispatch<ApplicationState, ThunkContext, Action<any>>,
	getState: () => ApplicationState,
	context: ThunkContext,
	seriesOwner?: string
) {
	const existingNamesFromUserIds = getState().data.entries.metadata.namesFromUserIds;
	try {
		// calling getNamesFromUserIds to get user names that we don't already have in the store
		const userIds = extractIdsWithoutNames(entries, existingNamesFromUserIds);
		const newNamesFromUserIds =
			userIds && userIds.length
				? await context.api.account.subscription().getNamesFromUserIds(userIds)
				: {};
		if (newNamesFromUserIds)
			dispatch(addNamesFromUserIdsAction({ namesFromUserIds: newNamesFromUserIds }));

		// populate entries with enteredbyuserwithname and ownedbyuserwithname
		const allNames = { ...existingNamesFromUserIds, ...newNamesFromUserIds };

		entries.forEach(entry => {
			entry.enteredbyuserwithname = allNames[entry.enteredbyuser] ?? entry.enteredbyuser;
			entry.ownedbyuserwithname =
				(seriesOwner && allNames[seriesOwner]) ??
				allNames[entry.ownedbyuser] ??
				entry.ownedbyuser;
		});
	} catch (e) {
		// in case of an error, set the parameters to have the value of user id and continue
		entries.forEach(entry => {
			if (!entry.enteredbyuserwithname)
				entry.enteredbyuserwithname =
					existingNamesFromUserIds[entry.enteredbyuser] ?? entry.enteredbyuser;
			if (!entry.ownedbyuserwithname)
				entry.ownedbyuserwithname =
					existingNamesFromUserIds[entry.ownedbyuserwithname] ??
					entry.ownedbyuserwithname;
		});
	}
}
