import { makeRequest } from '../makeRequest';
import {
	VariableGroup,
	Variable,
	CategoryMultipleVariable,
	CategoryFixedVariable,
	CategoryNonFixedVariable,
	CategoryMultipleNonFixedVariable
} from '../../types';
import { useQuery } from '@tanstack/react-query';
import { decodeURIComponentSafe } from 'helpers/generic';

type Order = { variable: string; group?: never } | { group: string; variable?: never };

export type VariablesData = {
	variables: Record<string, Variable>;
	order: Order[];
	groups: Record<string, VariableGroup>;
};

export const useVariablesQuery = ({ projectId }: { projectId: string }) => {
	return useQuery({
		queryKey: ['variables', projectId],
		queryFn: async () => {
			const result = await makeRequest<VariablesData>({
				method: 'getVariablesData',
				service: 'stats',
				data: {
					projectId: parseInt(projectId)
				}
			});

			return {
				...result,
				variables: Object.fromEntries(
					Object.entries(result.variables).map(([key, variable]) => [
						key,
						decodeVariable(variable)
					])
				)
			};
		}
	});
};

export const decodeVariable = (variable: Variable): Variable => {
	switch (variable.variableType) {
		case 'category':
			if (variable.fixedCategories === 'yes') {
				return {
					...variable,
					description: decodeURIComponentSafe(variable.description),
					variableLabel: decodeURIComponentSafe(variable.variableLabel),
					allowedCategories: variable.allowedCategories.map(category => ({
						...category,
						label: decodeURIComponentSafe(category.label),
						description: decodeURIComponentSafe(category.description),
						value: decodeURIComponentSafe(category.value)
					}))
				} as CategoryFixedVariable;
			}

			return {
				...variable,
				description: decodeURIComponentSafe(variable.description),
				variableLabel: decodeURIComponentSafe(variable.variableLabel),
				categories: variable.categories.map(category => decodeURIComponentSafe(category))
			} as CategoryNonFixedVariable;

		case 'categoryMultiple':
			if (variable.fixedCategories === 'yes') {
				return {
					...variable,
					description: decodeURIComponentSafe(variable.description),
					variableLabel: decodeURIComponentSafe(variable.variableLabel),
					allowedCategories: variable.allowedCategories.map(category => ({
						...category,
						label: decodeURIComponentSafe(category.label),
						description: decodeURIComponentSafe(category.description),
						value: decodeURIComponentSafe(category.value)
					}))
				} as CategoryMultipleVariable;
			}

			return {
				...variable,
				description: decodeURIComponentSafe(variable.description),
				variableLabel: decodeURIComponentSafe(variable.variableLabel),
				categories: variable.categories.map(category => decodeURIComponentSafe(category))
			} as CategoryMultipleNonFixedVariable;

		default:
			return {
				...variable,
				description: decodeURIComponentSafe(variable.description),
				variableLabel: decodeURIComponentSafe(variable.variableLabel)
			};
	}
};
