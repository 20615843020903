import { Columns, DensityPlotAnalysisV2 } from 'api/data/analyses';
import { isAnalysisInputValid } from 'helpers/analysis';
import { ActionTypes, VariablesDataSelectItems } from 'store/data/analyses';

import { DensityPlotConfigV2 } from './DensityPlotConfigV2';
import { DensityPlotViewV2 } from './DensityPlotViewV2';
import { InvalidAnalysis } from '..';
import { AnalysisContainer, AnalysisViewContainer } from '../UI';
import { VariablesData } from 'store/data/variables';
import { MediaQueries } from 'environment';
import {
	useFetchAnalysis,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useTranslation
} from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { withMemo } from 'helpers/HOCs';
import { Loader } from 'components/UI/Loader';

interface Props {
	analysis: DensityPlotAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
}

function Component({ analysis, variablesDataSelectItems, variablesData, exporting }: Props) {
	const { translate } = useTranslation();
	// custom refetch deps -> conditional approach
	const validInput = analysis.input.variables.numericVariable;

	const { loading } = useFetchAnalysis({
		id: analysis.id,
		actionType: ActionTypes.GET_DENSITY_PLOT_V2,
		...(validInput
			? { refetchDependencies: ['input.variables'] }
			: { refetchDependencies: [] }),
		initialFetchDependency: null // do not auto fetch initially!
	});
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen, setFullscreenAnalysis] = useFullscreenAnalysis();

	const analysisValidInput = isAnalysisInputValid(analysis);
	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const handleFullScreen = () => {
		if (
			(activeColumn !== Columns.OneColumn && !isMobileDevice) ||
			(activeColumn === Columns.OneColumn && isMobileDevice)
		) {
			setFullscreenAnalysis({ fullscreen: analysis.id });
		}
	};

	if (analysisValidInput) {
		return (
			<AnalysisContainer onClick={() => handleFullScreen()} activeColumn={activeColumn}>
				{!loading ? (
					<DensityPlotViewV2
						analysis={analysis}
						variablesData={variablesData}
						exporting={exporting}
						loading={loading}
						key={analysis.id}
					/>
				) : (
					<AnalysisViewContainer isConfigPanelOpen={analysis.options.configPanel.open}>
						<Loader primary />
					</AnalysisViewContainer>
				)}
				{(analysis.options.configPanel.open &&
					activeColumn === Columns.OneColumn &&
					!isMobileDevice) ||
				(analysis.options.configPanel.open && fullscreen) ? (
					<DensityPlotConfigV2
						analysis={analysis}
						variablesDataSelectItems={variablesDataSelectItems}
						loading={loading}
					/>
				) : null}
			</AnalysisContainer>
		);
	}

	return (
		<AnalysisContainer invalid>
			<InvalidAnalysis
				{...(!analysisValidInput
					? {
							headerMessage: translate(
								dict => dict.analysis.errors.invalidAnalysis.selectInputHeader
							)
					  }
					: {})}
				{...(!analysisValidInput
					? {
							bodyMessage: translate(
								dict => dict.analysis.errors.invalidAnalysis.selectInputBody
							)
					  }
					: {})}
				activeColumn={activeColumn}
				fullScreen={!!fullscreen}
				isConfigPanelOpen={analysis.options.configPanel.open}
				numeric
			/>
			{(analysis.options.configPanel.open &&
				activeColumn === Columns.OneColumn &&
				!isMobileDevice) ||
			(analysis.options.configPanel.open && (analysis.activeTab === 1 || fullscreen)) ? (
				<DensityPlotConfigV2
					analysis={analysis}
					variablesDataSelectItems={variablesDataSelectItems}
					loading={loading}
				/>
			) : null}
		</AnalysisContainer>
	);
}

export const DensityPlotV2 = withMemo(Component, [
	'analysis',
	'variablesData',
	'variablesDataSelectItems',
	'exporting'
]);
