import { Entry, EntryAccess, Status } from '../types';
import { makeRequest } from './makeRequest';
import { useQuery } from '@tanstack/react-query';

type GetDataEntryResponse = {
	entry: Entry;
	entryAccess: EntryAccess;
	statuses?: Status;
	latestEntryId: string;
};

export const useGetDataEntryQuery = ({
	entryId,
	projectId
}: {
	entryId: string;
	projectId: string;
}) => {
	return useQuery({
		queryKey: ['dataEntry', entryId],
		queryFn: () =>
			makeRequest<GetDataEntryResponse>({
				method: 'getDataEntry',
				service: 'data-entry',
				data: {
					datasetentryid: entryId,
					projectId
				}
			})
	});
};
