import styled, { css } from 'styled-components';

interface UserAvatarProps {
	size: number;
	background: string;
	cursor?: string;
	faded?: boolean;
}

export const UserAvatar = styled.div<UserAvatarProps>`
	${({ cursor, size, background }) => css`
		cursor: ${cursor};
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		justify-content: center;

		background-color: ${background};

		width: ${size}rem;
		height: ${size}rem;
		min-width: ${size}rem;
		max-width: ${size}rem;
		min-height: ${size}rem;
		max-height: ${size}rem;
	`}
	${({ faded }) =>
		faded &&
		css`
			opacity: 0.5;
		`}
`;
