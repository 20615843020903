import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';
import { CheckboxGroup } from '../component/CheckboxGroup';
import { useFilteringDependencies } from './useFilteringDependencies';
import { CategoryMultipleFixedFormItem } from '../data/useProjectData';
import { useEffect, useMemo } from 'react';
import { intersection } from '../utils/setUtils';

interface Props {
	control: FormControl;
	item: CategoryMultipleFixedFormItem;
}

export const CategoryMultipleFixedVariableInput = ({ item, control }: Props) => {
	const variable = item.variable;

	const { field, fieldState } = useController({
		name: item.variable.variableName,
		control
	});

	const filteredCategories = useFilteringDependencies({
		variable,
		dependencies: item.dependencies,
		control
	});

	const options = useMemo(
		() =>
			variable.allowedCategories
				.filter(category => filteredCategories.has(category.value))
				.map(category => ({
					label: category.label,
					value: category.value
				})),
		[variable.allowedCategories, filteredCategories]
	);

	useEffect(() => {
		if (variable.entryType === 'calculated') {
			return;
		}
		const selectedValues = new Set(field.value);
		const allowedValues = new Set(options.map(option => option.value));
		const legallySelectedValues = intersection(selectedValues, allowedValues);
		if (selectedValues.size !== legallySelectedValues.size) {
			field.onChange(Array.from(legallySelectedValues));
		}
	}, [options, field.value]);

	if (variable.optimizeForManyValues) {
		return (
			<CreatableSelect
				className="col-span-full md:col-span-1"
				label={variable.variableLabel}
				placeholder={variable.obligatory ? 'Select at least one' : 'Select'}
				values={field.value ? field.value.map((v: any) => ({ label: v, value: v })) : []}
				items={options}
				error={fieldState.error?.message?.toString()}
				onBlur={field.onBlur}
				LabelSuffix={<OptionalDescriptionTooltip description={variable.description} />}
				onValuesSelected={values => {
					field.onChange(values);
				}}
				required={variable.obligatory}
				hasMultipleValues
				allowCreate={false}
			/>
		);
	}

	return (
		<CheckboxGroup
			className="col-span-full"
			categories={options}
			variable={variable}
			allowCreate={false}
			control={control}
		/>
	);
};
