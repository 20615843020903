import produce from 'immer';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
	Columns,
	CrosstabAnalysisV2,
	CrosstabResultsV2,
	CrosstabVariablesV2,
	DataModel
} from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { Svgs } from 'environment';
import { VariablesDataSelectItems } from 'store/data/analyses';

import { ConfigContainer } from '../UI';
import { DropdownToggle } from 'components/UI/Dropdown/DropdownToggle';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { Dropdown } from 'components/UI/Dropdown';
import { Flex } from 'components/UI/Flex';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Switch } from 'components/UI/Interactables/Switch';
import {
	buildVariablesDataLocation,
	initVariablesData,
	isVariableOrderItem
} from 'helpers/variables';
import {
	useTranslation,
	useUpdateAnalysis,
	useRefetchAnalyses,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useAnalysisActiveTab,
	useAnalysisConfigPanel,
	useFilters,
	useVariables,
	useVariablesDataSelectItems
} from 'hooks/store';
import { useDebounce, usePrevious } from 'hooks/utils';
import { Nullable, SelectItem } from 'types/index';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';
import { VariablesData } from 'store/data/variables';

interface Props {
	analysis: CrosstabAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function CrosstabConfigV2({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();
	const [shouldRefetchAnalyses] = useRefetchAnalyses();
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();

	const {
		id,
		input: { variables, statistics, dataModel, series }
	} = analysis;

	const [activeTab, setActiveTab] = useAnalysisActiveTab(id);
	const [values, setValues] = useState(variables);
	const [stats, setStats] = useState(statistics);

	const [
		{ isConfigPanelOpen, isParamsOpen, isChartTypesOpen },
		{ openParameters, openChartTypes }
	] = useAnalysisConfigPanel(analysis.id);
	const [{ areFiltersOpen }] = useFilters();

	const [{ data: variablesData }] = useVariables();

	const { variablesMap, variableSetsMap, variableSets, groupsMap } = variablesData;

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const setVariablesData: VariablesData = useMemo(() => {
		if (!series || !variableSetsMap[series]) return initVariablesData();

		return Object.values(variableSetsMap[series].setOrder).reduce(
			(acc, data) => {
				if (isVariableOrderItem(data)) {
					acc.variablesMap[data.variable] = variablesMap[data.variable];
					acc.order.push({ variable: data.variable });
				} else {
					groupsMap[data.group].variablesBelongingToGroup.forEach(variable => {
						acc.variablesMap[variable] = variablesMap[variable];
						acc.order.push({ variable: variable });
					});
					acc.groupsMap[data.group] = groupsMap[data.group];
				}
				return acc;
			},
			{
				groupsMap: {},
				order: [],
				variableSetsMap: {},
				variablesMap: {}
			} as VariablesData
		);
	}, [series, variableSetsMap, variablesMap]);

	const { selectItemsMap: mainSelectItemsMap, categorySelectItems: mainCategorySelectItems } =
		variablesDataSelectItems;

	const { selectItemsMap: seriesSelectItemsMap, categorySelectItems: seriesCategorySelectItems } =
		useVariablesDataSelectItems(setVariablesData, { series, omitVariables: [] });

	useEffect(() => {
		if (!isEqual(variables, values)) setValues(variables);
	}, [variables]);

	useEffect(() => {
		if (!isEqual(statistics, stats)) setStats(statistics);
	}, [statistics]);

	useDebounce(
		() => {
			if (isEqual(variables, values)) return;

			const updatedAnalysis = produce(analysis, draft => {
				draft.input.variables = values;
				draft.input.statistics = {
					fisherV2: false,
					chiSquareV2: false,
					mcNemarV2: false
				};
			});

			updateAnalysis({ analysis: updatedAnalysis });
		},
		[values, analysis],
		ANALYSIS_DEBOUNCE_TIME
	);

	useDebounce(
		() => {
			if (isEqual(statistics, stats)) return;

			const updatedAnalysis = produce(analysis, draft => {
				draft.input.statistics = stats;
			});

			updateAnalysis({ analysis: updatedAnalysis });
		},
		[stats, analysis],
		ANALYSIS_DEBOUNCE_TIME
	);

	const prevShouldRefetchAnalyses = usePrevious(shouldRefetchAnalyses);
	useEffect(() => {
		if (
			prevShouldRefetchAnalyses !== undefined &&
			prevShouldRefetchAnalyses !== shouldRefetchAnalyses
		) {
			setStats({ fisherV2: false, chiSquareV2: false, mcNemarV2: false });
		}
	}, [shouldRefetchAnalyses]);

	useEffect(() => {
		if (!isFishersOrMcNemarEnabled(analysis.output.dataset)) {
			setStats(state => ({
				...state,
				...(statistics.fisherV2 ? { fisherV2: false } : {}),
				...(statistics.mcNemarV2 ? { mcNemarV2: false } : {})
			}));
		}
	}, [analysis.output.dataset, statistics]);

	function onSelectDataModel(dataModel: Nullable<string>) {
		const newAnalysis: CrosstabAnalysisV2 = {
			...analysis,
			input: {
				...analysis.input,
				...(dataModel === DataModel.main ? { series: undefined } : {}),
				dataModel: dataModel as DataModel | null,
				variables: {
					columnVariable: null,
					rowVariable: null
				},
				statistics: {
					chiSquareV2: false,
					fisherV2: false,
					mcNemarV2: false
				}
			}
		};

		updateAnalysis({
			analysis: newAnalysis
		});

		setValues({
			rowVariable: null,
			columnVariable: null
		});
	}

	function onSelectSeries(series: Nullable<string>) {
		if (!series) return;

		const newAnalysis: typeof analysis = { ...analysis, input: { ...analysis.input, series } };

		updateAnalysis({
			analysis: {
				...newAnalysis,
				input: {
					...newAnalysis.input,
					variables: {
						rowVariable: null,
						columnVariable: null
					}
				}
			}
		});

		setValues({
			columnVariable: null,
			rowVariable: null
		});
	}

	function onSelectVariable(key: keyof CrosstabVariablesV2, variableName: Nullable<string>) {
		if (!variableName) {
			setValues(values => ({
				...values,
				[key]: null
			}));
			return;
		}
		const setName = variablesLocation[variableName]?.setName;
		setValues(values => ({
			...values,
			[key]: {
				name: variableName,
				...(setName ? { series: setName } : {})
			}
		}));
	}

	const dataModelSelectItems: SelectItem[] = [
		{
			label: translate(dict => dict.analysis.generic.mainLevel),
			value: DataModel.main
		},
		{
			label: translate(dict => dict.analysis.generic.singleSeries),
			value: DataModel.series
		}
	];

	const variableSetSelectItems: SelectItem[] = variableSets.map(set => ({
		label: set.setLabel,
		value: set.setName
	}));

	const selectItemsMap = { ...mainSelectItemsMap, ...seriesSelectItemsMap };

	const selectItems = useMemo(() => {
		if (series) return seriesCategorySelectItems;
		return mainCategorySelectItems;
	}, [series]);

	return (
		<ConfigContainer
			data-testid={`${analysis.type}-config-container`}
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as CrosstabAnalysisV2} />
			)}

			{/* Chart type */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.ChartType
				)}
				open={!!isChartTypesOpen}
				onToggle={() =>
					openChartTypes({ analysisId: analysis.id, chartType: !isChartTypesOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6, top: 1.6 }} style={{ width: '100%' }} notLastChild>
					<Dropdown
						toggleComponent={({ ref, open, toggle }) => (
							<DropdownToggle
								ref={ref}
								open={open}
								titleComponent={
									activeTab ? (
										<Flex>
											{activeTab === 0 ? (
												<>
													<Icon
														svg={Svgs.ViewTable}
														key={0}
														active={activeTab === 0}
														marginOffset={{ right: 1 }}
														variant={v => v.buttonActive}
													/>
													<Typography.Paragraph>
														{translate(
															({ analysis }) =>
																analysis.analyses.crosstab.view
																	.table
														)}
													</Typography.Paragraph>
												</>
											) : activeTab === 1 ? (
												<>
													<Icon
														svg={Svgs.GroupedView}
														key={1}
														active={activeTab === 1}
														marginOffset={{ right: 1 }}
													/>
													<Typography.Paragraph>
														{translate(
															({ analysis }) =>
																analysis.analyses.crosstab.view
																	.grouped
														)}
													</Typography.Paragraph>
												</>
											) : activeTab === 2 ? (
												<>
													<Icon
														svg={Svgs.StackedView}
														key={2}
														active={activeTab === 2}
														marginOffset={{ right: 1 }}
													/>
													<Typography.Paragraph>
														{translate(
															({ analysis }) =>
																analysis.analyses.crosstab.view
																	.stacked
														)}
													</Typography.Paragraph>
												</>
											) : (
												<>
													<Icon
														svg={Svgs.SunburstView}
														key={3}
														active={activeTab === 3}
														marginOffset={{ right: 1 }}
													/>
													<Typography.Paragraph>
														{translate(
															({ analysis }) =>
																analysis.analyses.crosstab.view
																	.sunburst
														)}
													</Typography.Paragraph>
												</>
											)}
										</Flex>
									) : (
										<Flex>
											<Icon
												svg={Svgs.ViewTable}
												key={0}
												active={activeTab === 0}
												marginOffset={{ right: 1 }}
											/>
											<Typography.Paragraph>
												{translate(
													({ analysis }) =>
														analysis.analyses.crosstab.view.table
												)}
											</Typography.Paragraph>
										</Flex>
									)
								}
								toggle={toggle}
							/>
						)}
						button
					>
						<Dropdown.Item
							key={0}
							onClick={() => setActiveTab({ analysisId: id, activeTab: 0 })}
							active={activeTab === 0}
						>
							<Flex>
								<Icon
									svg={Svgs.ViewTable}
									key={0}
									active={activeTab === 0}
									marginOffset={{ right: 1 }}
								/>
								<Typography.Paragraph>
									{translate(
										({ analysis }) => analysis.analyses.crosstab.view.table
									)}
								</Typography.Paragraph>
							</Flex>
						</Dropdown.Item>

						<Dropdown.Item
							active={activeTab === 1}
							key={1}
							onClick={() => setActiveTab({ analysisId: id, activeTab: 1 })}
						>
							<Flex>
								<Icon
									svg={Svgs.GroupedView}
									key={1}
									active={activeTab === 1}
									marginOffset={{ right: 1 }}
								/>
								<Typography.Paragraph>
									{translate(
										({ analysis }) => analysis.analyses.crosstab.view.grouped
									)}
								</Typography.Paragraph>
							</Flex>
						</Dropdown.Item>

						<Dropdown.Item
							active={activeTab === 2}
							key={2}
							onClick={() => setActiveTab({ analysisId: id, activeTab: 2 })}
						>
							<Flex>
								<Icon
									svg={Svgs.StackedView}
									key={2}
									active={activeTab === 2}
									marginOffset={{ right: 1 }}
								/>

								<Typography.Paragraph>
									{translate(
										({ analysis }) => analysis.analyses.crosstab.view.stacked
									)}
								</Typography.Paragraph>
							</Flex>
						</Dropdown.Item>

						<Dropdown.Item
							active={activeTab === 3}
							key={3}
							onClick={() => setActiveTab({ analysisId: id, activeTab: 3 })}
						>
							<Flex>
								<Icon
									svg={Svgs.SunburstView}
									key={3}
									active={activeTab === 3}
									marginOffset={{ right: 1 }}
								/>

								<Typography.Paragraph>
									{translate(
										({ analysis }) => analysis.analyses.crosstab.view.sunburst
									)}
								</Typography.Paragraph>
							</Flex>
						</Dropdown.Item>
					</Dropdown>
				</Gap>
			</CollapsibleCard>

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<CreatableSelect
						label={translate(({ analysis }) => analysis.generic.dataModel)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === dataModel)}
						onValueSelected={onSelectDataModel}
						canClear={false}
					/>
					{dataModel === DataModel.series && (
						<CreatableSelect
							label={translate(({ analysis }) => analysis.generic.series)}
							items={variableSetSelectItems}
							value={variableSetSelectItems.find(item => item.value === series)}
							onValueSelected={onSelectSeries}
							canClear={false}
						/>
					)}
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(({ analysis }) => analysis.analyses.crosstab.config.rows)}
						items={selectItems}
						isItemDisabled={item => item.value === values.columnVariable?.name}
						disabled={!dataModel || (dataModel === DataModel.series && !series)}
						value={selectItemsMap[values.rowVariable?.name ?? '']}
						onValueSelected={rowVariable =>
							onSelectVariable('rowVariable', rowVariable)
						}
						canClear={false}
					/>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.crosstab.config.columns
						)}
						disabled={!dataModel || (dataModel === DataModel.series && !series)}
						items={selectItems}
						isItemDisabled={item => item.value === values.rowVariable?.name}
						value={selectItemsMap[values.columnVariable?.name ?? '']}
						onValueSelected={columnVariable =>
							onSelectVariable('columnVariable', columnVariable)
						}
						canClear={false}
					/>

					{/* STATISTICS */}
					<Switch
						label={translate(({ analysis }) => analysis.statistics.fisher.name)}
						on={stats.fisherV2}
						// 8818 (TODO)
						disabled={
							!dataModel ||
							(dataModel === DataModel.series && !series) ||
							!isFishersOrMcNemarEnabled(analysis.output.dataset)
						}
						onChange={() =>
							setStats(state => ({ ...state, fisherV2: !state.fisherV2 }))
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.mcNemar.name)}
						on={stats.mcNemarV2}
						disabled={
							!dataModel ||
							(dataModel === DataModel.series && !series) ||
							!isFishersOrMcNemarEnabled(analysis.output.dataset)
						}
						onChange={() =>
							setStats(state => ({ ...state, mcNemarV2: !state.mcNemarV2 }))
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.chiSquare.name)}
						on={stats.chiSquareV2}
						onChange={() =>
							setStats(state => ({ ...state, chiSquareV2: !state.chiSquareV2 }))
						}
						disabled={
							!dataModel ||
							(dataModel === DataModel.series && !series) ||
							!values.rowVariable ||
							!values.columnVariable
						}
					/>
				</Gap>
			</CollapsibleCard>

			{/* FORMATTING */}
			<AnalysisFormatting analysis={analysis} hasChartOptions={activeTab !== 0} isEnabled />
		</ConfigContainer>
	);
}

// enabled only if both axis have 2 values (total not counted)
function isFishersOrMcNemarEnabled(dataset: CrosstabResultsV2) {
	const { columnLabels, rowLabels } = dataset.data ?? { rowLabels: [], columnLabels: [] };

	// offset +1 for total column/row
	if (columnLabels.length === 3 && rowLabels.length === 3) {
		return true;
	}

	return false;
}
