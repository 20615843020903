import { makeRequest } from './makeRequest';
import { useQuery } from '@tanstack/react-query';

type NamesFromUserIds = Record<string, string>;
type GetNamesFromUserIdsResponse = {
	namesFromUserIds: NamesFromUserIds;
};

export const useGetNamesFromUserIds = (
	{
		userIds
	}: {
		userIds: string[];
	},
	{ enabled }: { enabled?: boolean }
) => {
	return useQuery({
		queryKey: ['namesFromUserIds', userIds],
		enabled,
		queryFn: () =>
			makeRequest<GetNamesFromUserIdsResponse>({
				method: 'getNamesFromUserIds',
				service: 'user',
				data: {
					userIds
				}
			})
	});
};
