import { useEffect, useMemo, useState } from 'react';

import { UserCard } from 'components/UI/UserCard';
import {
	SubscriptionUser,
	UpdateEnterpriseUser,
	UserLicenceLabels,
	UserLicenceModel,
	UserRole
} from 'store/account/subscription';

import { DeleteSubscriptionUserModal } from '../DeleteSubscriptionUserModal';
import { UserRoleSelector } from '../../Utils';
import { EditEnterpriseUserForm } from './EditEnterpriseUserForm';
import { ResetSubscriptionUserPasswordModal } from '../ResetSubscriptionUserPasswordModal';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Button } from 'components/UI/Interactables/Button';
import { Modal } from 'components/UI/Modal';
import { Spacer } from 'components/UI/Spacer';
import {
	getAddUserValidationSchema,
	getLicenceModelIdFromSubscriptionQuantities
} from 'helpers/subscription';
import {
	useTranslation,
	useUpdateEnterpriseUser,
	useChangeUserLicenceModel,
	useLicenceModels,
	useSubscription,
	useSubscriptionBundleFlag
} from 'hooks/store';
import { useReactForm } from 'hooks/ui';
import { useCompletedAction, usePrevious } from 'hooks/utils';

export enum EditSubscriptionUserTab {
	Information = 0,
	Licence = 1,
	Roles = 2
}

export type FormFields = Omit<
	SubscriptionUser,
	'imageURL' | 'subscriptionStatus' | 'isOwner' | 'licenceModel'
> & { licenceModel: UserLicenceModel };

interface Props {
	user: SubscriptionUser | null;
	onClose: () => void;
	selectedTab?: EditSubscriptionUserTab;
}

export function EditSubscriptionUserModal({
	user,
	onClose,
	selectedTab = EditSubscriptionUserTab.Information
}: Props) {
	const { translate } = useTranslation();

	const { subscriptionBundleFlag } = useSubscriptionBundleFlag();

	const [deleteUser, setDeleteUser] = useState(false);

	const [resetPassword, setResetPassword] = useState(false);

	const [currentTab, setCurrentTab] = useState<number>(selectedTab);

	const [{ loading: loadingUpdateEnterpriseUser }, updateEnterpriseUser] =
		useUpdateEnterpriseUser();

	const [{ loading: changingLicenceType, error: errorChangingLicenceType }, changeLicenceType] =
		useChangeUserLicenceModel();

	const [
		{
			data: {
				availableLicenceOptions,
				allLicenceOptions,
				licenceQuantitiesById,
				licenceModelsMetadataById
			}
		}
	] = useLicenceModels();

	const licenceOptions = useMemo(
		() =>
			user && user.isOwner
				? availableLicenceOptions.concat([
						{
							label: translate(
								() => UserLicenceLabels[UserLicenceModel.OneOwnedOneShared]
							),
							value: UserLicenceModel.OneOwnedOneShared
						}
				  ])
				: availableLicenceOptions,
		[availableLicenceOptions, user]
	);

	const [
		{
			data: {
				subscriptionTypes: { isLedidiEnterprise },
				userTypesAndRoles: { ledidiEnterprise }
			}
		}
	] = useSubscription();

	const hasLedidiEnterpriseAdminRole =
		ledidiEnterprise.isOrganizationAdmin ||
		ledidiEnterprise.isSuperAdmin ||
		ledidiEnterprise.isAdmin;

	const initialValues: FormFields = {
		userid: user ? user.userid : '',
		emailAddress: user ? user.emailAddress : '',
		userFirstName: user ? user.userFirstName : '',
		userSirName: user ? user.userSirName : '',
		country: user ? user.country : '',
		city: user ? user.city : '',
		phoneNumber: user ? user.phoneNumber : '',
		workplace: user ? user.workplace : '',
		position: user ? user.position : '',
		department: user ? user.department : '',
		userRole: user ? user.userRole : undefined,
		licenceModel: user ? user.licenceModel : UserLicenceModel.Full
	};

	const editUserValidationSchema = getAddUserValidationSchema({ translate });

	const {
		Form,
		FormProvider,
		formProviderProps,
		Controller,
		setValue,
		handleSubmit,
		isDirty: formHasChanges,
		control
	} = useReactForm({
		initialValues,
		validationSchema: editUserValidationSchema
	});

	const handleUpdateUser = handleSubmit(data => {
		if (user) {
			if (isLedidiEnterprise) {
				if (subscriptionBundleFlag) {
					const licenceModel = user.licenceModel;
					let licenceModelId;

					if (licenceModel && licenceQuantitiesById)
						licenceModelId = getLicenceModelIdFromSubscriptionQuantities(
							licenceModel,
							licenceQuantitiesById,
							licenceModelsMetadataById
						);
					updateEnterpriseUser({
						...data,
						licenceModelId,
						emailAddress: user.emailAddress,
						workplace: user.workplace ?? '',
						userRole: data.userRole ?? UserRole.User
					});
				} else {
					updateEnterpriseUser({
						...data,
						emailAddress: user.emailAddress,
						workplace: user.workplace ?? '',
						userRole: data.userRole ?? UserRole.User
					});
				}
			} else {
				changeLicenceType({
					licenceModel: user.licenceModel,
					userId: user.userid
				});
			}
		}
	});

	useCompletedAction(changingLicenceType, errorChangingLicenceType, closeModal);

	useEffect(() => {
		if (user) {
			setValue('userid', user.userid, {
				shouldDirty: true
			});
			setValue('emailAddress', user.emailAddress, {
				shouldDirty: false
			});
			setValue('userFirstName', user.userFirstName, {
				shouldDirty: false
			});
			setValue('userSirName', user.userSirName, {
				shouldDirty: false
			});
			setValue('country', user.country, {
				shouldDirty: false
			});
			setValue('phoneNumber', user.phoneNumber, {
				shouldDirty: false
			});
			setValue('workplace', user.workplace, {
				shouldDirty: false
			});
			setValue('position', user.position, {
				shouldDirty: false
			});
			setValue('department', user.department, {
				shouldDirty: false
			});
			setValue('licenceModel', user.licenceModel, {
				shouldDirty: false
			});
			setValue('userRole', user.userRole, {
				shouldDirty: false
			});
		}
	}, [user]);

	function getLicenceOption(value: string) {
		return allLicenceOptions.find(licence => licence.value === value);
	}

	function onConfirm() {
		if (formHasChanges) handleUpdateUser();
		else closeModal();
	}

	function closeModal() {
		setCurrentTab(EditSubscriptionUserTab.Information);
		onClose();
	}

	function onDeleteUserClosed(deleted?: boolean) {
		setDeleteUser(false);
		if (deleted) closeModal();
	}

	const userCardComponent = useMemo(() => {
		return user ? (
			<UserCard.Details
				userId={user.userid}
				userData={{
					emailAddress: user.emailAddress,
					userFirstName: user.userFirstName,
					userSirName: user.userSirName,
					position: user.position,
					phoneNumber: user.phoneNumber,
					city: user.city,
					country: user.country,
					workplace: user.workplace ?? '',
					department: user.department
				}}
				showDetails={!isLedidiEnterprise}
			/>
		) : undefined;
	}, [user, isLedidiEnterprise]);
	/**
	 *
	 * @param name Close modal after loading is finished in case User is being updated
	 * @param value
	 */
	const prevChangeLicenceLoading = usePrevious(changingLicenceType);
	const prevLoadingUpdateEnterpriseUser = usePrevious(loadingUpdateEnterpriseUser);
	useEffect(() => {
		if (
			(prevChangeLicenceLoading && !changingLicenceType) ||
			(prevLoadingUpdateEnterpriseUser && !loadingUpdateEnterpriseUser)
		)
			closeModal();
	}, [changingLicenceType, loadingUpdateEnterpriseUser]);

	function handleValue(name: keyof Omit<UpdateEnterpriseUser, 'licenceModelId'>, value: string) {
		setValue(name, value, { shouldDirty: true });
	}

	const tabsLabels = [
		translate(dict => dict.accountUM.editSubscriptionUser.tabInformation),
		translate(dict => dict.accountUM.editSubscriptionUser.tabLicence)
	];

	if (hasLedidiEnterpriseAdminRole) {
		tabsLabels.push(translate(dict => dict.accountUM.editSubscriptionUser.tabRoles));
	}

	return (
		<Modal
			visible={!!user}
			title={translate(dict => dict.accountUM.editSubscriptionUser.editModalTitle)}
			primary={{
				label: formHasChanges
					? translate(dict => dict.buttons.update)
					: translate(dict => dict.buttons.done),
				loading: changingLicenceType || loadingUpdateEnterpriseUser,
				warning: true,
				onClick: changingLicenceType || loadingUpdateEnterpriseUser ? undefined : onConfirm
			}}
			neutral={{
				label: translate(dict => dict.buttons.cancel),
				onClick: changingLicenceType || loadingUpdateEnterpriseUser ? undefined : closeModal
			}}
			tabs={{
				labels: tabsLabels,
				active: currentTab,
				onClick: (tabIndex: number) => setCurrentTab(tabIndex)
			}}
			onClose={closeModal}
			close
		>
			{user && (
				<>
					{currentTab === EditSubscriptionUserTab.Information &&
						(isLedidiEnterprise ? (
							<>
								{userCardComponent}
								<FormProvider {...formProviderProps}>
									<Form onSubmit={handleUpdateUser}>
										<EditEnterpriseUserForm
											onResetPassword={() => setResetPassword(true)}
										/>
									</Form>
								</FormProvider>
							</>
						) : (
							userCardComponent
						))}

					{currentTab === EditSubscriptionUserTab.Licence && (
						<>
							<Spacer size={s => s.m} />
							<Form onSubmit={handleUpdateUser}>
								<Controller
									control={control}
									name="licenceModel"
									render={({ field: { value } }) => (
										<CreatableSelect
											value={getLicenceOption(value)}
											label={translate(
												({ accountUM }) =>
													accountUM.userDetails.licenceModelSelect
											)}
											items={licenceOptions}
											onValueSelected={value =>
												setValue(
													'licenceModel',
													value as UserLicenceModel,
													{
														shouldDirty: true
													}
												)
											}
											canClear={false}
											scrollIntoView
											disabled={
												!subscriptionBundleFlag || !licenceOptions.length
											}
											required
										/>
									)}
								/>
							</Form>
							<Spacer size={s => s.m} />
							{!isLedidiEnterprise && (
								<Button
									title={translate(
										dict =>
											dict.accountUM.editSubscriptionUser.removeUserLicence
									)}
									variant={v => v.outline}
									onClick={() => {
										setDeleteUser(true);
									}}
								/>
							)}
						</>
					)}

					{currentTab === EditSubscriptionUserTab.Roles && (
						<>
							<Spacer size={s => s.m} />
							<Form onSubmit={handleUpdateUser}>
								<Controller
									control={control}
									name="userRole"
									render={({ field: { value: selectedUserRole } }) => (
										<UserRoleSelector
											selected={selectedUserRole as UserRole}
											onSelect={selected => handleValue('userRole', selected)}
											isOwner={user.isOwner}
										/>
									)}
								/>
							</Form>
						</>
					)}

					{user.userid && deleteUser && (
						<DeleteSubscriptionUserModal
							onClose={onDeleteUserClosed}
							userId={user.userid}
							userName={user.userFirstName + ' ' + user.userSirName}
							licenceModel={user.licenceModel}
						/>
					)}

					{user && resetPassword && (
						<ResetSubscriptionUserPasswordModal
							onClose={() => setResetPassword(false)}
							userId={resetPassword ? user.userid : null}
						/>
					)}
				</>
			)}
		</Modal>
	);
}
