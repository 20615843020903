import { makeRequest } from './makeRequest';
import { DependencyOperator } from '../types';
import { useQuery } from '@tanstack/react-query';
import { decodeURIComponentSafe } from 'helpers/generic';

export const useGetVariableDependenciesQuery = ({ projectId }: { projectId: string }) => {
	return useQuery({
		queryKey: ['variable-dependencies', projectId],
		queryFn: () =>
			makeRequest<DependencyData>({
				method: 'getVariableDependencies',
				service: 'data-entry',
				data: {
					projectId: parseInt(projectId)
				}
			}),
		select: data => {
			return {
				...data,
				dependencies: data.dependencies.map(dependency => {
					switch (dependency.dependencyType) {
						case 'Visibility':
							return {
								dependencyName: dependency.dependencyName,
								dependencyType: dependency.dependencyType,
								supplierVariableName: dependency.supplierVariableName,
								dependantVariables: dependency.dependantVariables.map(
									dependantVariable => ({
										dependantVariableName:
											dependantVariable.dependantVariableName,
										operator: dependantVariable.operator,
										supplierValueCondition: decodeURIComponentSafe(
											dependantVariable.supplierValueCondition
										)
									})
								)
							};
						case 'Filtering':
							return {
								dependencyName: dependency.dependencyName,
								dependencyType: dependency.dependencyType,
								supplierVariableName: dependency.supplierVariableName,
								dependantVariables: dependency.dependantVariables.map(
									dependantVariable => ({
										dependantVariableName:
											dependantVariable.dependantVariableName,
										operator: dependantVariable.operator,
										supplierValueCondition: decodeURIComponentSafe(
											dependantVariable.supplierValueCondition
										),
										filteredValues: dependantVariable.filteredValues.map(
											value => decodeURIComponentSafe(value)
										)
									})
								)
							};
					}
				})
			};
		}
	});
};

export type VisibilityDependant = {
	dependantVariableName: string;
	operator: DependencyOperator;
	supplierValueCondition: string;
};

export type FilteringDependant = {
	dependantVariableName: string;
	operator: DependencyOperator;
	supplierValueCondition: string;
	filteredValues: string[];
};

export type DependantVariable = VisibilityDependant | FilteringDependant;

export type VisibilityDependency = {
	dependencyType: 'Visibility';
	dependencyName: string;
	supplierVariableName: string;
	dependantVariables: VisibilityDependant[];
};

export type FilteringDependency = {
	dependencyType: 'Filtering';
	dependencyName: string;
	supplierVariableName: string;
	dependantVariables: FilteringDependant[];
};

export type Dependency = VisibilityDependency | FilteringDependency;

export type DependencyData = {
	active: boolean;
	dependencies: Dependency[];
};
