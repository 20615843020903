import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Avatar, AvatarSizes } from 'components/UI/Interactables/Avatar/Avatar';

interface UserCardBasicProps {
	userId: string | null;
	userData: {
		emailAddress: string;
		userFirstName?: string;
		userSirName?: string;
	};
	avatarSize?: (size: typeof AvatarSizes) => AvatarSizes;
}

/**
 * Set userId to null if user is pending
 * @param param0
 * @returns
 */
export function UserCardBasic({
	userId,
	userData: { userFirstName, userSirName, emailAddress },
	avatarSize
}: UserCardBasicProps) {
	return (
		<Flex overflowHidden align={a => a.center}>
			<Avatar
				userId={userId}
				data={{
					userFirstName,
					userSirName
				}}
				size={avatarSize ?? (s => s.m)}
			/>
			<Flex column justify={j => j.center} paddingOffset={{ left: 1.2 }} overflowHidden>
				<Typography.Paragraph ellipsis>
					{!userId ? emailAddress : `${userFirstName} ${userSirName}`}
				</Typography.Paragraph>
				{userId && <Typography.Caption ellipsis>{emailAddress}</Typography.Caption>}
			</Flex>
		</Flex>
	);
}
