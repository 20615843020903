import countries from 'i18n-iso-countries';
import countriesEnLocale from 'i18n-iso-countries/langs/en.json';
import { Flex } from 'components/UI/Flex';
import { Spacer } from 'components/UI/Spacer';
import { Colors, MediaQueries, Svgs } from 'environment';
import { UserTag } from '../UserTag';
import { Icon } from 'components/UI/Icons';
import { Avatar } from 'components/UI/Interactables/Avatar';
import { Typography } from 'components/UI/Typography';
import { useTranslation } from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { UserCard } from '../UserCard';

countries.registerLocale(countriesEnLocale);

interface UserCardDetailsProps {
	userId: string | null;
	userData: {
		emailAddress: string;
		userFirstName?: string;
		userSirName?: string;
		position?: string;
		phoneNumber?: string;
		city?: string;
		country?: string;
		workplace?: string;
		department?: string;
	};
	showHeader?: boolean;
	showDetails?: boolean;
	isSelf?: boolean;
	isOwner?: boolean;
	cardLayout?: (type: typeof CardLayout) => CardLayout;
}

enum CardLayout {
	Default,
	TransferUser
}

/**
 * showPartial only shows: emailAddress, phoneNumber, address, department, workplace and ommits first part: avatar, first name, last name, position
 * @param param0
 * @returns
 */
export function UserCardDetails({
	userId,
	userData: {
		emailAddress,
		userFirstName,
		userSirName,
		position,
		city,
		country,
		department,
		workplace,
		phoneNumber
	},
	showHeader = true,
	showDetails = true,
	isSelf,
	isOwner,
	cardLayout
}: UserCardDetailsProps) {
	const { translate } = useTranslation();

	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`,
		false
	);

	const layout = cardLayout ? cardLayout(CardLayout) : CardLayout.Default;

	function getField(value?: string) {
		return (
			<Typography.Paragraph color={!value ? Colors.text.disabled : undefined}>
				{!value ? translate(({ errors }) => errors.general.notAvailable) : value}
			</Typography.Paragraph>
		);
	}

	function getAddress() {
		if (city && country) return `${city}, ${countries.getName(country, 'en')}`;
		if (city) return city;
		if (country) return country;
	}

	return (
		<>
			{layout === CardLayout.Default && (
				<>
					{showHeader && (
						<>
							<Flex>
								<Avatar
									userId={userId}
									data={{
										userFirstName,
										userSirName
									}}
									size={s => (!isMobileDevice ? s.l : s.m)}
								/>
								<Flex column justify={j => j.center} paddingOffset={{ left: 2.4 }}>
									<Flex align={a => a.center}>
										{isMobileDevice ? (
											<Typography.H6 breakWord>
												{userFirstName && userSirName ? (
													<>
														{userFirstName} {userSirName}
													</>
												) : (
													emailAddress
												)}
											</Typography.H6>
										) : (
											<Typography.H3 breakWord>
												{userFirstName && userSirName ? (
													<>
														{userFirstName} {userSirName}
													</>
												) : (
													emailAddress
												)}
											</Typography.H3>
										)}

										{isSelf && (
											<UserTag
												marginOffset={{ left: 2.4 }}
												title={translate(({ tags }) => tags.you)}
											/>
										)}
										{isOwner && (
											<UserTag
												marginOffset={{ left: 0.8 }}
												title={translate(({ tags }) => tags.owner)}
											/>
										)}
										{!userId && (
											<UserTag
												marginOffset={{ left: 0.8 }}
												type={t => t.Pending}
												title={translate(({ tags }) => tags.pending)}
											/>
										)}
									</Flex>
									<Typography.Paragraph color={Colors.text.caption}>
										{position}
									</Typography.Paragraph>
								</Flex>
							</Flex>
							<Spacer size={s => s.m} />
						</>
					)}

					{showDetails && (
						<>
							<Flex
								align={a => a.start}
								marginOffset={{ left: isMobileDevice ? 0 : 5.6 }}
							>
								<Icon
									svg={Svgs.User}
									size={s => s.l}
									colors={{ color: Colors.text.disabled }}
								/>
								<Flex column marginOffset={{ left: 2.4 }}>
									<Typography.Hint>
										{translate(
											({ accountUM }) => accountUM.userDetails.emailAddress
										)}
									</Typography.Hint>
									{getField(emailAddress)}

									<Spacer size={s => s.xs} />

									<Typography.Hint>
										{translate(
											({ accountUM }) => accountUM.userDetails.physicalAddress
										)}
									</Typography.Hint>
									{getField(getAddress())}

									<Spacer size={s => s.xs} />

									<Typography.Hint>
										{translate(
											({ accountUM }) => accountUM.userDetails.phoneNumber
										)}
									</Typography.Hint>
									{getField(phoneNumber)}
								</Flex>
							</Flex>

							<Spacer size={s => s.m} />

							<Flex
								align={a => a.start}
								marginOffset={{ left: isMobileDevice ? 0 : 5.6 }}
							>
								<Icon
									svg={Svgs.Workplace}
									size={s => s.l}
									colors={{ color: Colors.text.disabled }}
								/>
								<Flex column marginOffset={{ left: 2.4 }}>
									<Typography.Hint>
										{translate(
											({ accountUM }) => accountUM.userDetails.workplace
										)}
									</Typography.Hint>
									{getField(workplace)}

									<Spacer size={s => s.xs} />

									<Typography.Hint>
										{translate(
											({ accountUM }) => accountUM.userDetails.department
										)}
									</Typography.Hint>
									{getField(department)}
								</Flex>
							</Flex>
						</>
					)}
				</>
			)}
			{layout === CardLayout.TransferUser && (
				<>
					{showHeader && (
						<UserCard.Basic
							userId={userId}
							userData={{
								userFirstName: userFirstName,
								userSirName: userSirName,
								emailAddress: emailAddress
							}}
						/>
					)}

					<Flex column justify={j => j.center} paddingOffset={{ top: 2.4 }}>
						<Typography.Hint>
							{translate(({ accountUM }) => accountUM.userDetails.emailAddress)}
						</Typography.Hint>
						{getField(emailAddress)}
						<Spacer size={s => s.xs} />

						<Typography.Hint>
							{translate(({ accountUM }) => accountUM.userDetails.physicalAddress)}
						</Typography.Hint>
						{getField(getAddress())}
						<Spacer size={s => s.xs} />

						<Typography.Hint>
							{translate(({ accountUM }) => accountUM.userDetails.phoneNumber)}
						</Typography.Hint>
						{getField(phoneNumber)}
						<Spacer size={s => s.xs} />

						<Typography.Hint>
							{translate(({ accountUM }) => accountUM.userDetails.workplace)}
						</Typography.Hint>
						{getField(workplace)}
						<Spacer size={s => s.xs} />

						<Typography.Hint>
							{translate(({ accountUM }) => accountUM.userDetails.department)}
						</Typography.Hint>
						{getField(department)}
					</Flex>
				</>
			)}
		</>
	);
}
