import { Control, useController, useForm } from 'react-hook-form';
import {
	Variable,
	Entry,
	CategoryNonFixedVariable,
	CategoryMultipleNonFixedVariable,
	CategoryFixedVariable,
	CategoryMultipleFixedVariable
} from './types';
import clsx from 'clsx';
import { FormItem, ProjectData, VariableFormItem } from './data/useProjectData';
import {
	defaultEmptyValueForVariables,
	parseBackendValues
} from './utils/parse-backend-values/parseBackendValues';
import { Asterisk } from 'components/UI/Asterisk';
import { DateInput } from './component/DateInput';
import { TimeDurationInput } from './inputs/time-duration/TimeDurationInput';
import { Label } from './component/Label';
import { InputType } from 'types';
import { Input } from 'components/UI/Inputs/Input';
import { CheckboxLabel } from './component/CheckboxGroup';

interface Props {
	initialEntry?: Entry;
	projectData: ProjectData;
	contentRef?: React.RefObject<HTMLFormElement>;
}

export const PrintEntryForm = ({ projectData, initialEntry, contentRef }: Props) => {
	const { control } = useForm<Entry>({
		mode: 'onBlur',
		defaultValues: initialEntry
			? parseBackendValues({ variables: projectData.variables, entry: initialEntry })
			: defaultEmptyValueForVariables(projectData.variables)
	});

	return (
		<form
			className="grid grid-cols-2 px-10 gap-10 relative mb-52 lg:w-[756px] lg:mx-auto"
			ref={contentRef}
		>
			{projectData.formItems.map(item => (
				<FormItemComponent
					key={`${item.type}_${
						item.type === 'variable' ? item.variable.variableName : item.group.groupName
					}`}
					item={item}
					control={control}
				/>
			))}
		</form>
	);
};

const FormItemComponent = ({ item, control }: { item: FormItem; control: FormControl }) => {
	switch (item.type) {
		case 'variable': {
			return <VariableInput control={control} variable={item.variable} />;
		}
		case 'group': {
			return (
				<GroupContainer
					groupName={item.group.groupName}
					groupLabel={item.group.groupLabel}
					formItems={item.formItems}
					control={control}
				/>
			);
		}
	}
};

const GroupContainer = ({
	groupName,
	groupLabel,
	formItems,
	control
}: {
	groupName: string;
	groupLabel: string;
	formItems: VariableFormItem[];
	control: FormControl;
}) => {
	return (
		<div key={groupName} className="p-6 col-span-full rounded-lg shadow-normal flex flex-col">
			<h2 className="text-base font-semibold">{groupLabel}</h2>

			<div className={clsx('grid grid-cols-2 py-[10px] gap-10 lg:gap-y-10')}>
				{formItems.map(item => {
					switch (item.type) {
						case 'variable': {
							return (
								<VariableInput
									key={item.variable.variableName}
									control={control}
									variable={item.variable}
								/>
							);
						}
					}
				})}
			</div>
		</div>
	);
};

export type FormControl = Control<Entry, any>;

const CREATE_NEW_OPTION = { label: '______________', value: '', description: '' };

const VariableInput = ({
	variable: _variable,
	control
}: {
	variable: Variable;
	control: FormControl;
}) => {
	const variable: Variable = { ..._variable, description: '' };
	const { field } = useController({ control, name: variable.variableName });

	switch (variable.variableType) {
		case 'string':
			return (
				<div className="flex flex-col col-span-full">
					<div className="flex items-center gap-1">
						<Label
							htmlFor={variable.variableName}
							label={variable.variableLabel}
							required={variable.obligatory}
						/>
					</div>

					<textarea
						value={field.value}
						id={variable.variableName}
						className="rounded-md border border-gray-400 p-3 pt-[12px] text-base resize-none min-h-[40px]"
						disabled={variable.entryType === 'calculated'}
						onChange={doNothing}
					/>
				</div>
			);

		case 'float':
		case 'integer': {
			return (
				<Input
					value={field.value}
					className="col-span-full md:col-span-1"
					onChange={doNothing}
					type={InputType.Text}
					label={variable.variableLabel}
					required={variable.obligatory}
					disabled={variable.entryType === 'calculated'}
				/>
			);
		}

		case 'category': {
			const options = getCategoryOptions(variable);

			return (
				<fieldset className="flex flex-col">
					<div className="flex gap-1">
						<legend className="font-semibold text-sm">{variable.variableLabel}</legend>

						{variable.obligatory && <Asterisk />}
					</div>

					<div className="flex gap-3 flex-wrap mt-4">
						{options.map(option => {
							return (
								<div key={option.value} className="flex gap-1 items-center">
									<input
										value={option.value}
										onChange={() => {
											// do nothing, handled in onClick
										}}
										onClick={() => {
											if (field.value === option.value) {
												field.onChange('');
												return;
											}

											field.onChange(option.value);
										}}
										checked={field.value === option.value}
										type="radio"
									/>

									<label className="text-base">{option.label}</label>
								</div>
							);
						})}
					</div>
				</fieldset>
			);
		}

		case 'categoryMultiple': {
			const options = getCategoryOptions(variable);

			return (
				<fieldset className="flex flex-col gap-3 flex-wrap mt-4">
					<div className="flex gap-1">
						<legend className="font-semibold text-base">
							{variable.variableLabel}
						</legend>

						{variable.obligatory && <Asterisk />}
					</div>

					<div className="mt-3 flex gap-4 flex-wrap">
						{options.map(category => {
							const isSelected = field.value?.includes(category.value);

							return (
								<CheckboxLabel
									key={category.value}
									isSelected={isSelected}
									label={category.label}
								/>
							);
						})}
					</div>
				</fieldset>
			);
		}

		case 'date': {
			return (
				<DateInput
					type="date"
					variable={variable}
					disabled={variable.entryType === 'calculated'}
					description=""
					value={field.value}
					onChange={doNothing}
				/>
			);
		}

		case 'datetime': {
			return (
				<DateInput
					value={field.value}
					type="datetime-local"
					variable={variable}
					disabled={variable.entryType === 'calculated'}
					description=""
					onChange={doNothing}
				/>
			);
		}

		case 'file': {
			return null;
		}

		case 'userDefinedUnique': {
			return (
				<Input
					value={field.value}
					type={InputType.Text}
					label={variable.variableLabel}
					required={variable.obligatory}
					readOnly={variable.uniquenessType !== 'Manual'}
					onChange={doNothing}
				/>
			);
		}

		case 'timeDuration': {
			return (
				<TimeDurationInput
					value={field.value}
					maxTimeUnit={variable.durationFormat.maxTimeUnit}
					minTimeUnit={variable.durationFormat.minTimeUnit}
					label={variable.variableLabel}
					required={variable.obligatory}
					onChange={doNothing}
					onBlur={doNothing}
					onError={doNothing}
				/>
			);
		}
	}

	// @ts-ignore
	console.error('Unhandled variable type: ', variable.variableType);
};

// Helper function to avoid eslint errors
function doNothing(): void {
	/* do nothing */
}

function getCategoryOptions(
	variable:
		| CategoryNonFixedVariable
		| CategoryFixedVariable
		| CategoryMultipleNonFixedVariable
		| CategoryMultipleFixedVariable
): { label: string; value: string }[] {
	if (variable.fixedCategories === 'no') {
		return [
			...variable.categories.map(category => ({ label: category, value: category })),
			CREATE_NEW_OPTION
		];
	}

	return variable.allowedCategories.map(category => ({
		label: category.label,
		value: category.value
	}));
}
