import { Control, useController, useWatch } from 'react-hook-form';
import { VariableFormItem } from '../data/useProjectData';
import { isRuleSatisfied } from '../utils/dependencyRules';
import { Entry, isVisibilityDependencyRule } from '../types';
import { useEffect } from 'react';
import { isEqual } from 'lodash';
import { backendValueToFormValue } from '../utils/parse-backend-values/backendValueToFormValue';

export const VisibilityRuleTrigger = ({
	variableFormItem: item,
	children,
	control
}: {
	variableFormItem: VariableFormItem;
	children: React.ReactNode;
	control: Control<Entry>;
}) => {
	const visibilityDependencies = item.dependencies.filter(isVisibilityDependencyRule);
	const form = useController({ control, name: item.variable.variableName });
	const supplierValues = useWatch({
		control,
		name: visibilityDependencies.map(rule => rule.supplierVariable.variableName)
	});

	const isVisible =
		visibilityDependencies.length === 0 ||
		visibilityDependencies.some((rule, index) =>
			isRuleSatisfied({ rule, supplierValue: supplierValues[index] })
		);

	useEffect(() => {
		if (item.variable.entryType === 'calculated') {
			return;
		}
		const emptyValue = backendValueToFormValue({ value: null, variable: item.variable });
		if (!isVisible && !isEqual(form.field.value, emptyValue)) {
			form.field.onChange(emptyValue);
		}
	}, [isVisible]);

	if (!isVisible) {
		return null;
	}

	return <>{children}</>;
};
