import type { Action } from 'types/store/types';
import { LanguageType, type GenericMap, type DeepPartial } from 'types/index';
import { BillingPeriodUnit, LedidiPlanCurrency } from '../payments';
import { ApiAccountDetails } from 'api/account/subscription';

export enum ActionTypes {
	// USER
	GET_ACCOUNT = 'data/accounts/GET_ACCOUNT',
	UPDATE_ACCOUNT = 'data/accounts/UPDATE_ACCOUNT',

	// SUBSCRIPTION
	GET_SUBSCRIPTION_API_KEY = 'data/accountUM/GET_SUBSCRIPTION_API_KEY',
	GET_SUBSCRIPTION = 'data/accountUM/GET_SUBSCRIPTION',
	ADD_SUBSCRIPTION_USER = 'data/accountUM/ADD_SUBSCRIPTION_USER',
	REMOVE_SUBSCRIPTION_USER = 'data/accountUM/REMOVE_SUBSCRIPTION_USER',
	VERIFY_USER_EXISTS = 'data/accountUM/VERIFY_USER_EXISTS',
	SET_SUBSCRIPTION_USERS_SEARCH_TERM = 'data/accountUM/SET_SUBSCRIPTION_USERS_SEARCH_TERM',
	CHANGE_USER_LICENCE_MODEL = 'data/accountUM/CHANGE_USER_LICENCE_MODEL',
	GET_ORGANIZATIONS = 'data/accounts/GET_ORGANIZATIONS',
	GET_LICENCE_MODELS = 'data/accounts/GET_LICENCE_MODELS',

	// SUBSCRIPTION INVITATIONS
	GET_PENDING_INVITATION = 'data/accountUM/GET_PENDING_INVITATION',
	RESPOND_TO_SUBSCRIPTION_INVITATION = 'data/accountUM/RESPOND_TO_SUBSCRIPTION_INVITATION',
	CANCEL_SUBSCRIPTION_USER_INVITATION = 'data/accountUM/CANCEL_SUBSCRIPTION_USER_INVITATION'
}

export enum UserLicenceModel {
	OneOwnedOneShared = '1OWNED1SHARED',
	Trial = 'TRIAL',
	Full = 'FULL',
	Collaborator = 'COLLABORATOR',
	NoLicence = 'NOLICENCE'
}

export enum UserLicenceModelId {
	CollaboratorEURMonthly = 'ledidi-collaborator-licence-EUR-Monthly',
	CollaboratorEURYearly = 'ledidi-collaborator-licence-EUR-Yearly',
	CollaboratorNOKMonthly = 'ledidi-collaborator-licence-NOK-Monthly',
	CollaboratorNOKYearly = 'ledidi-collaborator-licence-NOK-Yearly',
	PrimeEURMonthly = 'ledidi-prime-licence-EUR-Monthly',
	PrimeEURYearly = 'ledidi-prime-licence-EUR-Yearly',
	PrimeNOKMonthly = 'ledidi-prime-licence-NOK-Monthly',
	PrimeNOKYearly = 'ledidi-prime-licence-NOK-Yearly'
}

export type LicenceModelQuantities = GenericMap<number>;

export const UserLicenceLabels = {
	[UserLicenceModel.Full]: 'accountUM.licenceModels.full',
	[UserLicenceModel.Collaborator]: 'accountUM.licenceModels.collaborator',
	[UserLicenceModel.OneOwnedOneShared]: 'accountUM.licenceModels.oneOwnedOneShared',
	[UserLicenceModel.Trial]: 'accountUM.licenceModels.trial',
	[UserLicenceModel.NoLicence]: 'accountUM.licenceModels.nolicence'
};

export const UserLicenceDescriptions = {
	[UserLicenceModel.Full]: 'accountUM.licenceModelsDescriptions.full',
	[UserLicenceModel.Collaborator]: 'accountUM.licenceModelsDescriptions.collaborator',
	[UserLicenceModel.OneOwnedOneShared]: 'accountUM.licenceModels.oneOwnedOneShared',
	[UserLicenceModel.Trial]: 'accountUM.licenceModels.trial',
	[UserLicenceModel.NoLicence]: ''
};

export enum SubscriptionStatus {
	Pending = 'pending',
	Active = 'active',
	Inactive = 'inactive',
	ChangePlanInProgress = 'change_plan_in_progress'
}

export enum UserStatus {
	Active = 'ACTIVE',
	Inactive = 'INACTIVE',
	Invited = 'INVITED_TEMP'
}

export enum SubscriptionName {
	LedidiFree = 'ledidiFree',
	LedidiFreeTrial = 'ledidiFreeTrial',
	LedidiCollaborator = 'ledidiCollaborator',
	LedidiCore = 'ledidiCore',
	LedidiEnterprise = 'ledidiEnterprise'
}

export enum UserRole {
	OrganizationAdmin = 'organizationAdmin',
	SuperAdmin = 'superAdmin',
	Admin = 'admin',
	User = 'user'
}

export const UserRoleLabel = {
	[UserRole.OrganizationAdmin]: 'accountUM.userRoles.organizationAdmin',
	[UserRole.SuperAdmin]: 'accountUM.userRoles.superAdmin',
	[UserRole.Admin]: 'accountUM.userRoles.admin',
	[UserRole.User]: 'accountUM.userRoles.user'
};

export const UserStatusLabel = {
	[UserStatus.Active]: 'accountUM.userStatus.active',
	[UserStatus.Inactive]: 'accountUM.userStatus.inactive',
	[UserStatus.Invited]: 'accountUM.userStatus.invited'
};

export const SubscriptionNameLabel = {
	[SubscriptionName.LedidiEnterprise]:
		'accountUM.subscription.subscriptionNames.ledidiEnterprise',
	[SubscriptionName.LedidiCore]: 'accountUM.subscription.subscriptionNames.ledidiCore',
	[SubscriptionName.LedidiCollaborator]:
		'accountUM.subscription.subscriptionNames.ledidiCollaborator',
	[SubscriptionName.LedidiFree]: 'accountUM.subscription.subscriptionNames.ledidiFree',
	[SubscriptionName.LedidiFreeTrial]: 'accountUM.subscription.subscriptionNames.ledidiFreeTrial'
};

export interface UserPreferences {
	chosenLanguageCode: LanguageType;
	dateTimeFormat: DateTimeFormat;
}

export type DateTimeFormat = '12' | '24';

export interface AccountDetails extends Omit<ApiAccountDetails, 'licenseModel' | 'organization'> {
	licenceModel: UserLicenceModel;
}

export interface Organization {
	organizationId: number;
	organizationName: string;
}

export interface SubscriptionDetails {
	subscriptionId: string;
	subscriptionName: SubscriptionName;
	licenceQuantity?: number;
	availableLicenceQuantity?: number;
	licenceQuantities?: LicenceModelQuantities; // for Bundle support
	availableLicenceQuantities?: LicenceModelQuantities; // for Bundle support
	ownerFirstName: string;
	ownerLastName: string;
	ownerId: string;
	refundableCredits: number;
	isPayedMonthly: boolean;
}

export type SubscriptionUser = {
	userid: string;
	emailAddress: string;

	userFirstName: string;
	userSirName: string;
	country: string;
	city: string;
	phoneNumber: string;

	position: string;
	department: string;
	organization?: string;
	subOrganization?: string;
	workplace?: string;
	licenceModel: UserLicenceModel;
	licenceModelId?: string;
	subscriptionStatus: SubscriptionStatus;
	isOwner: boolean;
	status?: UserStatus;
	userRole?: UserRole;
};

export interface AddSubscriptionUser {
	emailAddress: string;
	userFirstName?: string;
	userSirName?: string;
	country?: string;
	city?: string;
	phoneNumber?: string;
	workplace?: string;
	position?: string;
	licenceModel?: UserLicenceModel;
	licenceModelId?: string;
	department?: string;
	userRole?: UserRole;
}

export interface UpdateEnterpriseUser {
	userid: string;
	emailAddress: string;
	userFirstName: string;
	userSirName: string;
	country: string;
	city: string;
	phoneNumber: string;
	workplace: string;
	position: string;
	department: string;
	licenceModel: UserLicenceModel;
	licenceModelId?: string;
	userRole: UserRole;
}

export type UserBasicInfo = {
	userid: string;
	userFirstName: string;
	userSirName: string;
	emailAddress: string;
};

export type SubscriptionUsersById = GenericMap<SubscriptionUser>;

export interface LicenceModels {
	allLicenceModels: UserLicenceModel[];
	licenceModelsMetadataById: GenericMap<SubscriptionLicenceModel>;
}

export interface SubscriptionLicenceModel {
	licenceModel: UserLicenceModel;
	price: number;
	currency: LedidiPlanCurrency;
	billingUnit: BillingPeriodUnit;
}

export interface State {
	accountDetails: AccountDetails | null;
	subscriptionDetails: SubscriptionDetails | null;
	subscriptionApiKey: string | null;
	subscriptionStatus: SubscriptionStatus | null;
	isNonSubscriber: boolean;
	subscriptionUsers: {
		byId: SubscriptionUsersById;
		searchTerm: string;
	};
	organizations?: Organization[];
	licenceModels: LicenceModels & { fetched: boolean };
	hasMetadataDefinition?: boolean;
}

export type GetAccountAction = Action<
	ActionTypes.GET_ACCOUNT,
	{
		account: AccountDetails;
	}
>;

export type UpdateAccountAction = Action<
	ActionTypes.UPDATE_ACCOUNT,
	{
		accountDetails: DeepPartial<AccountDetails>;
	}
>;

export type GetSubscriptionAction = Action<
	ActionTypes.GET_SUBSCRIPTION,
	{
		subscriptionDetails: SubscriptionDetails | null;
		subscriptionUsersById: SubscriptionUsersById;
		isNonSubscriber: boolean;
		hasMetadataDefinition?: boolean;
	}
>;

export type AddSubscriptionUserAction = Action<
	ActionTypes.ADD_SUBSCRIPTION_USER,
	{
		user: SubscriptionUser;
	}
>;

export type CancelUserSubscriptionInvitationAction = Action<
	ActionTypes.CANCEL_SUBSCRIPTION_USER_INVITATION,
	{
		userId: string;
		licenceModel: UserLicenceModel;
	}
>;

export type RemoveSubscriptionUserAction = Action<
	ActionTypes.REMOVE_SUBSCRIPTION_USER,
	{
		userId: string;
		licenceModel: UserLicenceModel;
	}
>;

export type ChangeUserLicenceModelAction = Action<
	ActionTypes.CHANGE_USER_LICENCE_MODEL,
	{
		userId: string;
		licenceModel: UserLicenceModel;
	}
>;

export type GetOrganizationsAction = Action<
	ActionTypes.GET_ORGANIZATIONS,
	{
		organizations: Organization[];
	}
>;

export type GetLicenceModelsAction = Action<
	ActionTypes.GET_LICENCE_MODELS,
	{
		licenceModels: LicenceModels;
	}
>;

export type SetSubscriptionUsersSearchTermAction = Action<
	ActionTypes.SET_SUBSCRIPTION_USERS_SEARCH_TERM,
	{
		term: string;
	}
>;

export type GetSubscriptionApiKeyAction = Action<
	ActionTypes.GET_SUBSCRIPTION_API_KEY,
	{
		apiKey: string;
	}
>;

export type Actions =
	| GetAccountAction
	| UpdateAccountAction
	| GetSubscriptionAction
	| AddSubscriptionUserAction
	| CancelUserSubscriptionInvitationAction
	| RemoveSubscriptionUserAction
	| GetOrganizationsAction
	| GetLicenceModelsAction
	| ChangeUserLicenceModelAction
	| SetSubscriptionUsersSearchTermAction
	| GetSubscriptionApiKeyAction;
