import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import { Columns, DataModel, FrequenciesAnalysisV2 } from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { Svgs } from 'environment';

import { ConfigContainer } from '../UI';

import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { TabsWithIcon } from 'components/UI/Tabs';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';

import {
	useAnalysesActiveColum,
	useAnalysisActiveTab,
	useAnalysisConfigPanel,
	useFilters,
	useFullscreenAnalysis,
	useTranslation,
	useUpdateAnalysis,
	useVariables,
	useVariablesDataSelectItems
} from 'hooks/store';
import { useDebounce } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';
import {
	buildSeriesLevelVariableData,
	buildVariablesDataLocation,
	mergeSelectItems
} from 'helpers/variables';
import { Nullable, SelectItem } from 'types';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	analysis: FrequenciesAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function FrequenciesConfigV2({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();
	const [fullscreen] = useFullscreenAnalysis();

	const updateAnalysis = useUpdateAnalysis();
	const {
		id,
		input: { variables, series, dataModel }
	} = analysis;

	const [activeTab, setActiveTab] = useAnalysisActiveTab(id);

	const [values, setValues] = useState(variables);

	const [{ data: variablesData }] = useVariables();

	const { variableSets } = variablesData;

	const setVariablesData = buildSeriesLevelVariableData(variablesData, series);

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	const seriesVariableSelectItems = useVariablesDataSelectItems(setVariablesData, {
		series,
		omitVariables: []
	});

	const { selectItemsMap: mainSelectItemsMap, selectItems: mainSelectItems } =
		variablesDataSelectItems;
	const { selectItemsMap: seriesSelectItemsMap, selectItems: seriesSelectItems } =
		seriesVariableSelectItems;

	const [
		{ isConfigPanelOpen, isParamsOpen, isChartTypesOpen },
		{ openParameters, openChartTypes }
	] = useAnalysisConfigPanel(analysis.id);
	const [{ areFiltersOpen }] = useFilters();

	const [activeColumn] = useAnalysesActiveColum();

	const selectItems = useMemo(() => {
		if (dataModel === DataModel.main) {
			return mergeSelectItems(mainSelectItems, variablesData, [
				VariableType.Category,
				VariableType.CategoryMultiple
			]);
		}

		return mergeSelectItems(seriesSelectItems, variablesData, [
			VariableType.Category,
			VariableType.CategoryMultiple
		]);
	}, [variablesDataSelectItems, seriesSelectItems, dataModel]);

	useEffect(() => {
		if (!isEqual(variables, values)) {
			setValues(variables);
		}
	}, [variables]);

	useDebounce(
		() => {
			if (!isEqual(variables, values)) {
				const updatedAnalysis: FrequenciesAnalysisV2 = {
					...analysis,
					input: { ...analysis.input, variables: values }
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values],
		ANALYSIS_DEBOUNCE_TIME
	);

	const dataModelSelectItems: SelectItem[] = [
		{
			label: translate(dict => dict.analysis.generic.mainLevel),
			value: DataModel.main
		},
		{
			label: translate(dict => dict.analysis.generic.singleSeries),
			value: DataModel.series
		}
	];

	const variableSetSelectItems: SelectItem[] = variableSets.map(set => ({
		label: set.setLabel,
		value: set.setName
	}));

	function onSelectVariable(variableName: Nullable<string>) {
		if (!variableName) {
			setValues(values => ({
				...values,
				categoryVariable: null
			}));
			return;
		}
		const setName = variablesLocation[variableName]?.setName;
		setValues(values => ({
			...values,
			categoryVariable: {
				name: variableName,
				...(setName ? { series: setName } : {})
			}
		}));
	}

	function onSelectDataModel(dataModel: Nullable<string>) {
		const newAnalysis: FrequenciesAnalysisV2 = {
			...analysis,
			input: {
				...analysis.input,
				...(dataModel === DataModel.main ? { series: undefined } : {}),
				dataModel: dataModel as DataModel | null,
				variables: {
					categoryVariable: null
				}
			}
		};

		updateAnalysis({
			analysis: newAnalysis
		});
	}

	function onSelectSeries(series: Nullable<string>) {
		if (!series) return;

		const newAnalysis: typeof analysis = { ...analysis, input: { ...analysis.input, series } };

		updateAnalysis({
			analysis: {
				...newAnalysis,
				input: {
					...newAnalysis.input,
					variables: {
						categoryVariable: null
					}
					// ...(series !== prevSeries ? rebuildVariables(newAnalysis) : {})
				}
			}
		});

		setValues({
			categoryVariable: null
		});
	}

	const selectItemsMap = {
		...mainSelectItemsMap,
		...seriesSelectItemsMap
	};

	return (
		<ConfigContainer
			data-testid={`${analysis.type}-config-container`}
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && isConfigPanelOpen && (
				<AnalysisOptionsHeader analysis={analysis as FrequenciesAnalysisV2} />
			)}

			{/* Chart type */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.ChartType
				)}
				open={!!isChartTypesOpen}
				onToggle={() =>
					openChartTypes({ analysisId: analysis.id, chartType: !isChartTypesOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6, top: 1.6 }} style={{ width: '100%' }} notLastChild>
					<TabsWithIcon
						labels={[
							translate(({ analysis }) => analysis.analyses.frequencies.view.table),
							translate(({ analysis }) => analysis.analyses.frequencies.view.columns),
							translate(({ analysis }) => analysis.analyses.frequencies.view.pie)
						]}
						icons={[Svgs.ViewTable, Svgs.GroupedView, Svgs.ChartPie]}
						startIndex={activeTab}
						onChangeTabs={active => setActiveTab({ analysisId: id, activeTab: active })}
						maxWidth={36}
					/>
				</Gap>
			</CollapsibleCard>
			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.dataModel
						)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === dataModel)}
						onValueSelected={onSelectDataModel}
						canClear={false}
					/>
					{dataModel === DataModel.series && (
						<CreatableSelect
							label={translate(
								({ analysis }) => analysis.analyses.correlationsV2.config.series
							)}
							items={variableSetSelectItems}
							value={variableSetSelectItems.find(item => item.value === series)}
							onValueSelected={onSelectSeries}
							canClear={false}
						/>
					)}
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.frequencies.config.categories
						)}
						disabled={!dataModel || (dataModel === DataModel.series && !series)}
						items={selectItems}
						value={selectItemsMap[values.categoryVariable?.name ?? '']}
						onValueSelected={onSelectVariable}
						canClear={false}
					/>
				</Gap>
			</CollapsibleCard>
			{/* FORMATTING */}
			<AnalysisFormatting hasChartOptions={activeTab !== 0} analysis={analysis} isEnabled />
		</ConfigContainer>
	);
}
