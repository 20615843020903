import { Flex } from 'components/UI/Flex';
import { Avatar } from 'components/UI/Interactables/Avatar';
import { Typography } from 'components/UI/Typography';

import { UserTag } from '../UserTag';

interface UserCardProfileProps {
	userId: string;
	userData: {
		userFirstName: string;
		userSirName: string;
		position: string;
		department?: string;
		subscription?: string;
	};
	isMobileDevice?: boolean;
}

export function UserCardProfile({
	userId,
	userData: { userFirstName, userSirName, position, department, subscription },
	isMobileDevice
}: UserCardProfileProps) {
	return (
		<Flex>
			<Avatar
				userId={userId}
				data={{ userFirstName, userSirName }}
				size={s => (isMobileDevice ? s.m : s.l)}
			/>
			<Flex column justify={j => j.center} paddingOffset={{ left: 2.4 }}>
				{isMobileDevice ? (
					<Typography.H6 breakWord>
						{userFirstName} {userSirName}
					</Typography.H6>
				) : (
					<Typography.H5 breakWord>
						{userFirstName} {userSirName}
					</Typography.H5>
				)}
				<Typography.Paragraph>{position}</Typography.Paragraph>
				{department && <Typography.Caption>{department}</Typography.Caption>}
				{subscription && (
					<UserTag
						title={subscription}
						type={t => t.VibrantGreen}
						marginOffset={{ top: 0.4 }}
					/>
				)}
			</Flex>
		</Flex>
	);
}
