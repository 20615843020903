import { useState } from 'react';

import { apiFactory } from 'api';
import { ApiAddedSubscriptionUser } from 'api/account/subscription';
import { ApiOperation } from 'hooks/store/types';

import {
	ActionTypes,
	addSubscriptionUserAction,
	UserLicenceModel,
	SubscriptionStatus,
	UserStatus,
	SubscriptionUser,
	AddSubscriptionUser
} from 'store/account/subscription';
import { LedidiStatusCode } from 'types/index';
import { useDispatch, useStatic } from 'hooks/utils';
import { useAPI } from 'hooks/api';
import { useLicenceModels } from './useLicenceModels';
import { getLicenceModelIdFromSubscriptionQuantities } from 'helpers/subscription';
import { useSubscriptionBundleFlag } from './useSubscriptionBundleFlag';

type UseAddSubscriptionUserData = {
	ledidiStatusCode: LedidiStatusCode | null;
};

export function useAPIAddSubscriptionUser(): ApiOperation<
	UseAddSubscriptionUserData,
	(user: AddSubscriptionUser) => void
> {
	const dispatch = useDispatch();

	const [getUserData, setUserData] = useStatic<AddSubscriptionUser>({
		emailAddress: ''
	});

	const [data, setData] = useState<UseAddSubscriptionUserData>({
		ledidiStatusCode: null
	});

	const [
		{
			data: { licenceModelsMetadataById, licenceQuantitiesById }
		}
	] = useLicenceModels();

	const { subscriptionBundleFlag } = useSubscriptionBundleFlag();

	const [{ error, loading, fetched }, addUser, resetAddUser] = useAPI<ApiAddedSubscriptionUser>({
		promiseFn: () => promiseFn(getUserData()),
		lazy: true,
		initialData: { ledidiStatusCode: '' },
		resetData: {
			onFetch: true
		},
		handleError: {
			showNotification: true,
			translateMessage: false
		},
		onSuccess: onAddUserSuccess,
		activityType: ActionTypes.ADD_SUBSCRIPTION_USER
	});

	function promiseFn(input: AddSubscriptionUser) {
		if (!subscriptionBundleFlag)
			return apiFactory().account.subscription().addSubscriptionUser(input);
		else {
			const licenceModel = input.licenceModel;
			let licenceModelId;

			if (licenceModel && licenceQuantitiesById)
				licenceModelId = getLicenceModelIdFromSubscriptionQuantities(
					licenceModel,
					licenceQuantitiesById,
					licenceModelsMetadataById
				);

			return apiFactory()
				.account.subscription()
				.addSubscriptionUser({ ...input, licenceModelId });
		}
	}

	function handler(user: AddSubscriptionUser) {
		addUser(() => promiseFn(user));
		setUserData(user);
	}

	function onAddUserSuccess(data: ApiAddedSubscriptionUser) {
		// in case user was added or is in pending state, add it to Redux
		if (
			data.ledidiStatusCode === LedidiStatusCode.SubscriptionNewUserAdded ||
			data.ledidiStatusCode === LedidiStatusCode.SubscriptionUserInvited
		) {
			const userData = getUserData();

			const userToAdd: SubscriptionUser = {
				emailAddress: userData.emailAddress,
				userid: data.userid ?? '',
				userFirstName: userData.userFirstName ?? '',
				userSirName: userData.userSirName ?? '',
				country: userData.country ?? '',
				workplace: userData.workplace ?? '',
				phoneNumber: userData.phoneNumber ?? '',
				licenceModel: userData.licenceModel ?? UserLicenceModel.Full,
				isOwner: false,
				city: userData.city ?? '',
				department: userData.department ?? '',
				position: userData.position ?? '',
				userRole: userData.userRole,
				status:
					data.ledidiStatusCode === LedidiStatusCode.SubscriptionUserInvited
						? undefined
						: UserStatus.Active,
				subscriptionStatus:
					data.ledidiStatusCode === LedidiStatusCode.SubscriptionUserInvited
						? SubscriptionStatus.Pending
						: SubscriptionStatus.Active
			};

			dispatch(addSubscriptionUserAction({ user: userToAdd }));
		}

		const status = data.ledidiStatusCode as LedidiStatusCode;
		setData({ ledidiStatusCode: status });
	}

	return [{ data, error, loading, fetched }, handler, resetAddUser];
}
