import { useCallback, useEffect, useMemo } from 'react';

import {
	AnalysisVariable,
	Columns,
	ComparePairedAnalysisV2,
	ComparePairedDataModels,
	ComparePairedStatisticsV2,
	ComparePairedVariablesV2
} from 'api/data/analyses';

import { VariablesDataSelectItems } from 'store/data/analyses';
import { Nullable, SelectItem } from 'types/index';
import { ConfigContainer } from '../../UI';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Gap } from 'components/UI/Gap';
import { Switch } from 'components/UI/Interactables/Switch';
import {
	buildSeriesLevelVariableData,
	buildVariablesDataLocation,
	mergeSelectItems
} from 'helpers/variables';
import {
	useTranslation,
	useUpdateAnalysis,
	useFullscreenAnalysis,
	useAnalysisConfigPanel,
	useAnalysesActiveColum,
	useFilters,
	useVariables,
	useVariablesDataSelectItems
} from 'hooks/store';
import { useDebounce, useMutableState } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../../AnalysisOptions/AnalysisOptionsHeader';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { isEqual } from 'lodash';
import { useGroupingVariablesSelectItems } from 'hooks/store/data/analysis/useGroupingVariablesSelectItems';
import { isAnalysisInputValid } from 'helpers/analysis';
import { VariableType } from 'types/data/variables/constants';

interface Props {
	analysis: ComparePairedAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

const DEFAULT_VARIABLES: ComparePairedVariablesV2 = {
	beforeVariable: null,
	afterVariable: null,
	pairIdentifier: null,
	groupVariable: null,
	numericVariable: null
};

const DEFAULT_STATS: ComparePairedStatisticsV2 = {
	pairedTTestV2: false,
	pairedWilcoxonV2: false
};

export function ComparePairedConfigV2({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();
	const [{ areFiltersOpen }] = useFilters();

	const updateAnalysis = useUpdateAnalysis();
	const [fullscreen] = useFullscreenAnalysis();

	const {
		input: analysisInput,
		options: { configPanel }
	} = analysis;

	const analysisInputValid = isAnalysisInputValid(analysis);

	const [draftAnalysisInput, setDraftAnalysisInput] = useMutableState(analysisInput);

	const [{ isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(analysis.id);

	const [activeColumn] = useAnalysesActiveColum();

	const { statistics: inputStatistics, dataModel, series } = draftAnalysisInput;

	const [{ data: variablesData }] = useVariables();

	const { variableSets } = variablesData;

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	// SYNC `draftAnalysisInput` STATE
	useEffect(() => {
		if (!isEqual(draftAnalysisInput, analysisInput)) setDraftAnalysisInput(analysisInput);
	}, [analysisInput]);

	useDebounce(
		() => {
			if (!isEqual(draftAnalysisInput, analysis.input)) {
				updateAnalysis({
					analysis: {
						...analysis,
						input: {
							...analysis.input,
							...draftAnalysisInput
						}
					} as ComparePairedAnalysisV2
				});
			}
		},
		[draftAnalysisInput],
		ANALYSIS_DEBOUNCE_TIME
	);

	const {
		selectItemsMap: mainSelectItemsMap,
		selectItems: mainSelectItems,
		integerSelectItems: mainIntegerSelectItems,
		stringSelectItems: mainTextSelectItems
	} = variablesDataSelectItems;

	const setVariablesData = buildSeriesLevelVariableData(variablesData, series);
	const seriesVariableSelectItems = useVariablesDataSelectItems(setVariablesData, {
		series,
		omitVariables: []
	});
	const {
		selectItemsMap: seriesSelectItemsMap,
		selectItems: seriesSelectItems,
		integerSelectItems: seriesIntegerSelectItems,
		stringSelectItems: seriesTextSelectItems
	} = seriesVariableSelectItems;

	const groupingSelectItems = useGroupingVariablesSelectItems({
		series,
		variablesData
	});

	const selectItemsMap = { ...mainSelectItemsMap, ...seriesSelectItemsMap };

	const dataType = {
		is: {
			singleEntry: dataModel === ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT,
			multipleEntry: dataModel === ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT,
			usingSeries: dataModel === ComparePairedDataModels.USING_SERIES
		},
		label: {
			[ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT]: translate(
				({ analysis }) => analysis.analyses.comparePaired.config.singleEntryDataType
			),
			[ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT]: translate(
				({ analysis }) => analysis.analyses.comparePaired.config.twoEntriesDataType
			),
			[ComparePairedDataModels.USING_SERIES]: translate(
				({ analysis }) => analysis.analyses.comparePaired.config.seriesEntryDataType
			)
		}
	};

	const dataModelSelectItems: SelectItem[] = [
		{
			label: dataType.label[ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT],
			value: ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT
		},
		{
			label: dataType.label[ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT],
			value: ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT
		},
		{
			label: dataType.label[ComparePairedDataModels.USING_SERIES],
			value: ComparePairedDataModels.USING_SERIES
		}
	];

	const onSelectVariable = useCallback(
		(key: keyof ComparePairedVariablesV2, variableName: Nullable<string>) => {
			if (!variableName) {
				return setDraftAnalysisInput(state => {
					state.variables[key] = null;
					state.statistics = DEFAULT_STATS;
				});
			}

			const setName = variablesLocation[variableName]?.setName;
			setDraftAnalysisInput(state => {
				state.variables[key] = {
					name: variableName,
					...(setName ? { series: setName } : {})
				} as AnalysisVariable;
				state.statistics = DEFAULT_STATS;
			});
		},
		[draftAnalysisInput]
	);

	function onSelectDataModel(dataModel: Nullable<string>) {
		const newAnalysis: ComparePairedAnalysisV2 = {
			...analysis,
			input: {
				...analysis.input,
				series: undefined,
				dataModel: dataModel as ComparePairedDataModels | null,
				variables: DEFAULT_VARIABLES,
				statistics: DEFAULT_STATS
			}
		};

		updateAnalysis({
			analysis: newAnalysis
		});
	}

	function onSelectSeries(series: Nullable<string>) {
		if (!series) return;

		const newAnalysis: typeof analysis = {
			...analysis,
			input: {
				...analysis.input,
				series,
				variables: DEFAULT_VARIABLES,
				statistics: DEFAULT_STATS
			}
		};

		updateAnalysis({
			analysis: {
				...newAnalysis
			}
		});
	}

	const variableSetSelectItems: SelectItem[] = variableSets.map(set => ({
		label: set.setLabel,
		value: set.setName
	}));

	const numericSelectItems = useMemo(() => {
		if (series) {
			return mergeSelectItems(seriesSelectItems, variablesData, [
				VariableType.Float,
				VariableType.Integer,
				VariableType.TimeDuration
			]);
		}
		return mergeSelectItems(mainSelectItems, variablesData, [
			VariableType.Float,
			VariableType.Integer,
			VariableType.TimeDuration
		]);
	}, [series, mainSelectItems, seriesSelectItems]);

	const integerSelectItems = useMemo(() => {
		if (series) return seriesIntegerSelectItems;

		return mainIntegerSelectItems;
	}, [mainIntegerSelectItems, seriesIntegerSelectItems, series]);

	const textSelectItems = useMemo(() => {
		if (series) return seriesTextSelectItems;

		return mainTextSelectItems;
	}, [mainTextSelectItems, seriesTextSelectItems, series]);

	const selectedVariableNames = {
		[ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT]: [
			...(draftAnalysisInput.variables.beforeVariable
				? [draftAnalysisInput.variables.beforeVariable]
				: []),
			...(draftAnalysisInput.variables.afterVariable
				? [draftAnalysisInput.variables.afterVariable]
				: [])
		].map(d => d.name),
		[ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT]: [
			...(draftAnalysisInput.variables.numericVariable
				? [draftAnalysisInput.variables.numericVariable]
				: []),
			...(draftAnalysisInput.variables.groupVariable
				? [draftAnalysisInput.variables.groupVariable]
				: []),
			...(draftAnalysisInput.variables.pairIdentifier
				? [draftAnalysisInput.variables.pairIdentifier]
				: [])
		].map(d => d.name),
		[ComparePairedDataModels.USING_SERIES]: [
			...(draftAnalysisInput.variables.numericVariable
				? [draftAnalysisInput.variables.numericVariable]
				: []),
			...(draftAnalysisInput.variables.groupVariable
				? [draftAnalysisInput.variables.groupVariable]
				: [])
		].map(d => d.name)
	};

	return (
		<ConfigContainer
			data-testid={`${analysis.type}-config-container`}
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && configPanel.open && (
				<AnalysisOptionsHeader analysis={analysis as ComparePairedAnalysisV2} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<CreatableSelect
						label={translate(({ analysis }) => analysis.generic.dataModel)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === dataModel)}
						onValueSelected={onSelectDataModel}
						canClear={false}
					/>
					{dataModel === ComparePairedDataModels.USING_SERIES && (
						<CreatableSelect
							label={translate(({ analysis }) => analysis.generic.series)}
							items={variableSetSelectItems}
							value={variableSetSelectItems.find(item => item.value === series)}
							onValueSelected={onSelectSeries}
							canClear={false}
						/>
					)}
					{/* VARIABLE INPUTS */}
					{dataType.is.singleEntry ? (
						<>
							<CreatableSelect
								value={
									selectItemsMap[
										draftAnalysisInput.variables.beforeVariable?.name ?? ''
									]
								}
								label={translate(
									dict =>
										dict.analysis.analyses.comparePaired.config.beforeVariable
								)}
								items={numericSelectItems}
								onValueSelected={value => onSelectVariable('beforeVariable', value)}
								isItemDisabled={item =>
									selectedVariableNames[
										ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT
									].includes(item.value)
								}
							/>

							<CreatableSelect
								value={
									selectItemsMap[
										draftAnalysisInput.variables.afterVariable?.name ?? ''
									]
								}
								label={translate(
									dict =>
										dict.analysis.analyses.comparePaired.config.afterVariable
								)}
								items={numericSelectItems}
								onValueSelected={value => onSelectVariable('afterVariable', value)}
								isItemDisabled={item =>
									selectedVariableNames[
										ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT
									].includes(item.value)
								}
							/>
						</>
					) : (
						<></>
					)}
					{dataType.is.multipleEntry ? (
						<>
							{/** ONLY MAIN LEVEL VARIABLES */}
							<CreatableSelect
								label={translate(
									dict =>
										dict.analysis.analyses.comparePaired.config.numericVariable
								)}
								items={numericSelectItems}
								onValueSelected={value =>
									onSelectVariable('numericVariable', value)
								}
								value={
									selectItemsMap[
										draftAnalysisInput.variables.numericVariable?.name ?? ''
									]
								}
								isItemDisabled={item =>
									selectedVariableNames[
										ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT
									].includes(item.value)
								}
							/>

							<CreatableSelect
								label={translate(
									dict =>
										dict.analysis.analyses.comparePaired.config.groupVariable
								)}
								value={
									selectItemsMap[
										draftAnalysisInput.variables.groupVariable?.name ?? ''
									]
								}
								items={groupingSelectItems}
								onValueSelected={value => onSelectVariable('groupVariable', value)}
								isItemDisabled={item =>
									selectedVariableNames[
										ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT
									].includes(item.value)
								}
							/>

							<CreatableSelect
								label={translate(
									dict =>
										dict.analysis.analyses.comparePaired.config.pairIdentifier
								)}
								value={
									selectItemsMap[
										draftAnalysisInput.variables.pairIdentifier?.name ?? ''
									]
								}
								items={[...integerSelectItems, ...textSelectItems]}
								onValueSelected={value => onSelectVariable('pairIdentifier', value)}
								isItemDisabled={item =>
									selectedVariableNames[
										ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT
									].includes(item.value)
								}
							/>
						</>
					) : (
						<></>
					)}
					{dataType.is.usingSeries ? (
						<>
							{/** ONLY MAIN LEVEL VARIABLES */}
							<CreatableSelect
								label={translate(
									dict =>
										dict.analysis.analyses.comparePaired.config.numericVariable
								)}
								value={
									selectItemsMap[
										draftAnalysisInput.variables.numericVariable?.name ?? ''
									]
								}
								items={numericSelectItems}
								onValueSelected={value =>
									onSelectVariable('numericVariable', value)
								}
								isItemDisabled={item =>
									selectedVariableNames[
										ComparePairedDataModels.USING_SERIES
									].includes(item.value)
								}
							/>

							<CreatableSelect
								label={translate(
									dict =>
										dict.analysis.analyses.comparePaired.config.groupVariable
								)}
								value={
									selectItemsMap[
										draftAnalysisInput.variables.groupVariable?.name ?? ''
									]
								}
								items={groupingSelectItems}
								onValueSelected={value => onSelectVariable('groupVariable', value)}
								isItemDisabled={item =>
									selectedVariableNames[
										ComparePairedDataModels.USING_SERIES
									].includes(item.value)
								}
							/>
						</>
					) : (
						<></>
					)}

					{/* STATISTICS */}
					<Switch
						label={translate(({ analysis }) => analysis.statistics.pairedTTest.name)}
						on={inputStatistics.pairedTTestV2}
						disabled={!analysisInputValid}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.pairedTTestV2 = !state.statistics.pairedTTestV2;
							})
						}
					/>
					<Switch
						label={translate(({ analysis }) => analysis.statistics.pairedWilcoxon.name)}
						on={inputStatistics.pairedWilcoxonV2}
						disabled={!analysisInputValid}
						onChange={() =>
							setDraftAnalysisInput(state => {
								state.statistics.pairedWilcoxonV2 =
									!state.statistics.pairedWilcoxonV2;
							})
						}
					/>
				</Gap>
			</CollapsibleCard>
		</ConfigContainer>
	);
}
