import { makeRequest } from './makeRequest';
import { useQuery } from '@tanstack/react-query';

type Result = {
	statusCode: string;

	project: {
		organizations: {
			organizationId: string;
			organizationName: string;

			organizationCollaborators: {
				userId: string;
			}[];
		}[];
	};
};

export const useGetProjectOrganizationCollaboratorsQuery = ({
	projectId
}: {
	projectId: string;
}) => {
	return useQuery({
		queryKey: ['projectOrganizationCollaborators', projectId],
		queryFn: () =>
			makeRequest<Result>({
				method: 'getProjectOrganizationCollaborators',
				service: 'projects',
				data: {
					project: {
						projectId: parseInt(projectId)
					}
				}
			})
	});
};
