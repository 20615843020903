import { Control, useForm, Resolver, FieldErrors, useFormState, useWatch } from 'react-hook-form';
import { Variable, Entry } from './types';
import { zodResolver } from '@hookform/resolvers/zod';
import { toZodSchema } from './utils/zodUtils';
import { StringVariableInput } from './inputs/StringVariableInput';
import { NumericVariableInput } from './inputs/NumericVariableInput';
import { CategoryVariableInput } from './inputs/CategoryNonFixedVariableInput';
import { CategoryMultipleVariableInput } from './inputs/CategoryMultipleNonFixedVariableInput';
import { DateVariableInput } from './inputs/DateVariableInput';
import { DateTimeVariableInput } from './inputs/DateTimeVariableInput';
import { FileVariableInput } from './inputs/FileVariableInput';
import { UserDefinedUniqueVariableInput } from './inputs/UserDefinedUniqueInput';
import { TimeDurationVariableInput } from './inputs/TimeDurationVariableInput';
import { useState } from 'react';
import { Icon } from 'components/UI/Icons';
import { Colors, Svgs } from 'environment';
import clsx from 'clsx';
import { useRef } from 'react';
import {
	FormItem,
	isCategoryFixedFormItem,
	isCategoryMultipleFixedFormItem,
	ProjectData,
	VariableFormItem
} from './data/useProjectData';
import {
	defaultEmptyValueForVariables,
	parseBackendValues
} from './utils/parse-backend-values/parseBackendValues';
import { DiffedField, getDirtyFields } from './utils/formUtils';
import { ConfirmDiffModal } from './confirm-diff-modal/ConfirmDiffModal';
import { PersonalDataWarning } from './smart-components/PersonalDataWarning';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePrompt } from 'hooks/navigation/usePrompt';
import { useTracking } from 'app/tracking/TrackingProvider';
import { VisibilityRuleTrigger } from './inputs/DependencyVisibilityTrigger';
import { CategoryMultipleFixedVariableInput } from './inputs/CategoryMultipleFixedVariableInput';
import { CategoryFixedVariableInput } from './inputs/CategoryFixedVariableInput';

type OnSubmit = (entry: Entry) => Promise<Entry | undefined | void>;
interface Props {
	initialEntry?: Entry;
	onSubmit: OnSubmit;
	Header: React.ReactNode;
	projectData: ProjectData;
	submitting?: boolean;
}

export const EntryForm = ({ projectData, initialEntry, onSubmit, Header, submitting }: Props) => {
	const overrideErrors = useRef<Record<string, string | undefined>>({});
	const { control, handleSubmit, setError, getValues, formState } = useForm<Entry>({
		mode: 'onBlur',
		resolver: combineResolvers(
			zodResolver(toZodSchema(projectData.variables)),
			overrideErrorsResolver(overrideErrors)
		),
		defaultValues: initialEntry
			? parseBackendValues({ variables: projectData.variables, entry: initialEntry })
			: defaultEmptyValueForVariables(projectData.variables)
	});

	const { defaultValues } = formState;

	const handleOverrideError = (name: string, error: string | undefined) => {
		overrideErrors.current[name] = error;
		setError(name, { message: error, type: 'custom' });
	};

	const anyOblogatory = projectData.variables.some(v => v.obligatory);
	return (
		<>
			{!submitting && (
				<DiffModalNavigationIntercept control={control} variables={projectData.variables} />
			)}

			<form
				data-testid="entry-form"
				className="grid grid-cols-2 px-10 gap-10 relative mb-52 lg:w-[756px] lg:mx-auto"
				onSubmit={handleSubmit(data => {
					const dirtyFields = getDirtyFields(getValues(), defaultValues || {});
					if (dirtyFields.length === 0) {
						return;
					}
					const dirtyData = Object.fromEntries(
						Object.entries(data).filter(([key]) => dirtyFields.includes(key))
					);
					onSubmit(dirtyData);
				})}
			>
				{Header}

				<PersonalDataWarning />

				{projectData.formItems.map(item => (
					<FormItemComponent
						key={`${item.type}_${
							item.type === 'variable'
								? item.variable.variableName
								: item.group.groupName
						}`}
						item={item}
						control={control}
						onError={handleOverrideError}
					/>
				))}

				{anyOblogatory && (
					<p className="mt-4 text-sm col-span-full">
						Fields marked with an (*) are required
					</p>
				)}
			</form>
		</>
	);
};

const DiffModalNavigationIntercept = ({
	control,
	variables
}: {
	control: FormControl;
	variables: Variable[];
}) => {
	const { track } = useTracking();
	const navigate = useNavigate();

	const currentLocation = useLocation();
	const formState = useFormState({ control });
	const currentFormValues = useWatch({ control });

	const allowNavigation = useRef(false);
	const [nextNavLocation, setNextNavLocation] = useState<string>();

	const dirtyFields = getDirtyFields(currentFormValues, formState.defaultValues || {});

	const isDirty = dirtyFields.length > 0;

	const [showModal, setShowModal] = useState(false);

	// This is needed to intercept navigation from react router
	usePrompt(nextLocation => {
		const sameLocation = currentLocation.pathname === nextLocation.pathname;

		if (sameLocation) {
			return true;
		}

		// Edge case because we don't want to show the modal after creating an entry and navigating (this indicates that this componend should be rethought but idk how to do that right now. maybe allowing override of allowNavigation through props)
		const isOnCreatePage = currentLocation.pathname.includes('create');
		const goingToUpdatePage = nextLocation.pathname.includes('update');
		if (isOnCreatePage && goingToUpdatePage) {
			return true;
		}

		if (isDirty && !allowNavigation.current) {
			setShowModal(true);
			setNextNavLocation(nextLocation.pathname);
			return false;
		}

		return true;
	}, isDirty);

	const diff: Record<string, DiffedField> = {};
	for (const field of dirtyFields) {
		diff[field] = {
			from: formState.defaultValues?.[field],
			to: currentFormValues[field]
		};
	}

	if (!showModal || !isDirty) return null;
	return (
		<ConfirmDiffModal
			diff={diff}
			onClose={() => {
				setShowModal(false);
				track({
					eventName: 'entry_diff_cancelled'
				});
			}}
			onDelete={() => {
				track({
					eventName: 'entry_diff_discarded'
				});
				if (!nextNavLocation) {
					console.error('nextNavLocation is not set, this is not supposed to happen');
					return;
				}
				allowNavigation.current = true;
				navigate(nextNavLocation);
			}}
			variables={variables}
		/>
	);
};

const combineResolvers = (
	zodResolver: Resolver<Entry>,
	customResolver: Resolver<Entry>
): Resolver<Entry> => {
	return async (values: Entry, context, options) => {
		const [zodResults, customResults] = await Promise.all([
			zodResolver(values, context, options),
			customResolver(values, context, options)
		]);

		// TODO: MARTIN WRITE TESTS FOR THIS 🤘
		const hasErrors = !isEmptyObject(zodResults.errors) || !isEmptyObject(customResults.errors);

		return {
			values: hasErrors ? {} : zodResults.values, // zodResults.values is the values that passed the zod validation
			errors: {
				...zodResults.errors,
				...customResults.errors
			}
		};
	};
};

const isEmptyObject = (obj: Record<string, any>) => {
	return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const overrideErrorsResolver = (
	ref: React.MutableRefObject<Record<string, string | undefined>>
): Resolver<Entry> => {
	return async (values: Entry, _, __) => {
		const errors: FieldErrors<Entry> = {};

		for (const [name, error] of Object.entries(ref.current)) {
			if (error !== undefined) {
				errors[name] = { type: 'custom', message: error };
			}
		}

		return { values, errors };
	};
};

const FormItemComponent = ({
	item,
	control,
	onError
}: {
	item: FormItem;
	control: FormControl;
	onError: (name: string, error: string | undefined) => void;
}) => {
	switch (item.type) {
		case 'variable': {
			return (
				<VisibilityRuleTrigger control={control} variableFormItem={item}>
					<VariableInput control={control} item={item} onError={onError} />
				</VisibilityRuleTrigger>
			);
		}
		case 'group': {
			return (
				<GroupContainer
					groupName={item.group.groupName}
					groupLabel={item.group.groupLabel}
					formItems={item.formItems}
					onError={onError}
					control={control}
				/>
			);
		}
	}
};

const GroupContainer = ({
	groupName,
	groupLabel,
	formItems,
	onError,
	control
}: {
	groupName: string;
	groupLabel: string;
	formItems: VariableFormItem[];
	onError: (name: string, error: string | undefined) => void;
	control: FormControl;
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div key={groupName} className="p-6 col-span-full rounded-lg shadow-normal flex flex-col ">
			<button
				onClick={() => setIsOpen(!isOpen)}
				className="flex justify-between items-center"
				type="button"
			>
				<div className="flex gap-2 items-center self-stretch">
					<Icon
						onClick={() => setIsOpen(!isOpen)}
						svg={Svgs.ChevronDown}
						className={clsx('transition-all', isOpen && 'rotate-180')}
					/>

					<Icon size={s => s.s} svg={Svgs.Folder} />

					<h2 className="text-base font-semibold">{groupLabel}</h2>
				</div>

				<GroupStatusIcon
					control={control}
					variables={formItems
						.filter(item => item.type === 'variable')
						.map(item => item.variable)}
				/>
			</button>

			<div
				className={clsx(
					'grid grid-cols-2 py-[10px] gap-10 lg:gap-y-10',
					!isOpen && 'hidden'
				)}
			>
				{formItems.map(item => {
					switch (item.type) {
						case 'variable': {
							return (
								<FormItemComponent
									key={item.variable.variableName}
									item={item}
									control={control}
									onError={onError}
								/>
							);
						}
					}
				})}
			</div>
		</div>
	);
};

const GroupStatusIcon = ({
	control,
	variables
}: {
	control: FormControl;
	variables: Variable[];
}) => {
	const formState = useFormState({ control });

	const errors = formState.errors;

	const hasErrors = variables.some(v => !!errors[v.variableName]);

	if (hasErrors) {
		return (
			<Icon svg={Svgs.AlertCircle} colors={{ color: Colors.text.error }} size={s => s.m} />
		);
	}

	return null;
};

export type FormControl = Control<Entry, any>;

const VariableInput = ({
	item,
	control,
	onError
}: {
	item: VariableFormItem;
	control: FormControl;
	onError: (name: string, error: string | undefined) => void;
}) => {
	switch (item.variableType) {
		case 'string':
			return <StringVariableInput control={control} variable={item.variable} />;

		case 'float':
		case 'integer': {
			return <NumericVariableInput control={control} variable={item.variable} />;
		}

		case 'category': {
			if (isCategoryFixedFormItem(item)) {
				return <CategoryFixedVariableInput control={control} item={item} />;
			}
			return <CategoryVariableInput control={control} item={item} />;
		}

		case 'categoryMultiple': {
			if (isCategoryMultipleFixedFormItem(item)) {
				return <CategoryMultipleFixedVariableInput control={control} item={item} />;
			}
			return <CategoryMultipleVariableInput control={control} item={item} />;
		}

		case 'date': {
			return <DateVariableInput control={control} variable={item.variable} />;
		}

		case 'datetime': {
			return <DateTimeVariableInput control={control} variable={item.variable} />;
		}

		case 'file': {
			return (
				<FileVariableInput onError={onError} control={control} variable={item.variable} />
			);
		}

		case 'userDefinedUnique': {
			return <UserDefinedUniqueVariableInput control={control} variable={item.variable} />;
		}

		case 'timeDuration': {
			return (
				<TimeDurationVariableInput
					control={control}
					variable={item.variable}
					onError={onError}
				/>
			);
		}
	}

	// @ts-ignore
	console.error('Unhandled variable type: ', variable.variableType);

	return null;
};
