import type { ImportPageProps } from 'types/data/projects/import/types';

import { useEffect, useMemo, useState } from 'react';
import { VariableType } from 'types/data/variables/constants';
import {
	NarrowContainer,
	ColumnTitle,
	PreviousMappingButton,
	MatchColumnToMainVariableContainer
} from 'components/Projects/CreateAndImport';
import { StickyFooter } from 'components/UI/StickyFooter';

import { Colors, Svgs } from 'environment';
import { InputType, ImportType } from 'types/index';
import { PreviousMappingModal } from '../../PreviousMappingModal';
import {
	FlexCellIcon,
	FlexCellLabel,
	FlexCellNewData,
	FlexCellNewLabel,
	FlexCellType,
	FlexRow,
	List,
	TooltipContainer,
	ErrorContainer
} from './EntriesToDataset.style';
import { cloneDeep } from 'lodash';
import { ActionTypes } from 'store/data/projects';
import { Flex } from 'components/UI/Flex';
import { Icon } from 'components/UI/Icons';
import { Typography } from 'components/UI/Typography';
import { Switch } from 'components/UI/Interactables/Switch';
import { Input } from 'components/UI/Inputs/Input';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { InfoTooltip } from 'components/UI/Interactables/InfoTooltip';
import { useActivities, useTranslation } from 'hooks/store';
import { AssignProjectOrganizationModal } from 'components/Dataset/Modals';
import { TimeZoneOptions } from '../TimeZoneOptions/TimeZoneOptions';
import { Spacer } from 'components/UI/Spacer';
import {
	handleCustomDateFormatChange,
	handleVariableDateTypeChange,
	handleVariableTimeZoneChange,
	matchDateTimeFormat,
	showDateFormatPicker
} from 'helpers/projects/imports/importDateAndDatetime';
import {
	applyTimezoneToAllDatetimeVariables,
	importRequiresGlobalTimezoneSettings,
	variableHasSuggestedDateFormat
} from 'helpers/projects/imports/importTimezone';
import { DATE_FORMATS } from 'types/data/projects/import/constants';
import { useImportManager } from 'hooks/store/data/projects/import/useImportManager';
import { useEffectOnce, useMaybeOutsideClick } from 'hooks/utils';
import {
	getErroredPreviewVariableLocationMapById,
	shouldFocus
} from 'helpers/projects/imports/suggestedVariables';
import { Pagination } from 'components/UI/Pagination';
import { dynamicTranslate } from 'helpers/dynamicTranslate';
import {
	getDropdownTimeUnits,
	getTimeDurationValue,
	handleDurationFormatChange,
	handleSourceUnitChange,
	hasPrecisionLossVariables
} from 'helpers/projects/imports/importTimeDuration';
import { PrecissionLossWarning } from '../PrecissionLossWarning/PrecissionLossWarning';
import { IntervalDropdown } from '../../IntervalDropdown/IntervalDropdown';

export function EntriesToDataset({
	handleBack,
	handleFinishImport,
	handleApiImportErrors
}: ImportPageProps) {
	const [{ loading: loadingGenerateVariableNames }] = useActivities([
		ActionTypes.GENERATE_VARIABLE_NAMES
	]);

	const { translate } = useTranslation();

	const [showUnmatchedFields, setShowUnmatchedFields] = useState(false);
	const [showError, setShowError] = useState(true);

	const {
		data: {
			main: {
				dataTypeSelectItems,
				createNewVariable,
				initialNewVariables,
				dateFormatsSelectItems,
				dateTimeFormatsSelectItems,
				formattedVariables,
				globalTimezone,
				initialSuggestions,
				loadingVariables,
				mappedInitialVariables,
				mappedMainLevelVariables,
				previousMapping,
				timeZones,
				variablesWithDateFormat,
				formattedDropdownValues,
				duplicationLists,
				isBinary,
				isExcelOrigin,
				timeUnitSelectItems
			},
			series: {
				importVariableSetName,
				assignedOrganization,
				columnToMatchWithMainLevel,
				isImportVariableSet,
				showAssignOrganizationModal,
				userOrganizations,
				variableToMatchOnMainLevel
			},
			errors: { showOnlyErrors },
			refs: {
				dateInputRefs,
				dateTimeInputRefs,
				containerRefs,
				customFormatInputRefs,
				timeDurationSourceRefs,
				timeDurationFormatRefs,
				currentlyModifyingVarID,
				lastRefs
			}
		},
		handlers: {
			main: {
				canUpload,
				handleChangeGlobalTimezone,
				handleClearVariable,
				onVariablePreviewNameChanged,
				onVariableTypeChanged,
				onVariableLabelChange,
				requiresTimezoneSettings,
				setFormattedVariables,
				upload
			},
			series: {
				setDestinationSetName,
				handleSelectColumnToMatchWithMainLevel,
				setColumnToMatchWithMainLevel,
				setAssignedOrganizationId,
				setShowAssignOrganizationModal,
				setVariableToMatchOnMainLevel
			},
			errors: { setShowOnlyErrors, setErrors },
			refs: { getRefs, setCurrentlyModifyingVarID, setLastRefs }
		},
		pagination: {
			pageIndex,
			pageSize,
			pagesCount,
			shouldPaginate,
			page,
			changePage,
			changePageSize
		}
	} = useImportManager({
		selectedOption: ImportType.MoreEntriesToDataset,
		handleFinishImport,
		handleApiImportErrors
	});

	const suggestedErroredVariableLocationById = useMemo(
		() => getErroredPreviewVariableLocationMapById(formattedVariables, pageSize),
		[formattedVariables, pageSize]
	);

	const errorsCount = useMemo(() => {
		let count = 0;
		Object.values(suggestedErroredVariableLocationById).forEach(
			varErrors => (count += Object.keys(varErrors).length)
		);

		return count;
	}, [suggestedErroredVariableLocationById]);

	useMaybeOutsideClick(() => {
		if (currentlyModifyingVarID) {
			setErrors();
		}
	}, lastRefs);

	// Focus on the last touched variable if needed
	useEffect(() => {
		if (currentlyModifyingVarID) {
			const variable = formattedVariables.find(v => v.id === currentlyModifyingVarID);
			if (!variable) return;
			if (!shouldFocus(variable)) {
				if (variable.customDateFormat) {
					const customRef = customFormatInputRefs.current[currentlyModifyingVarID];
					setLastRefs([customRef]);
					return;
				}
				setCurrentlyModifyingVarID(null);
				return;
			}
			const refs = getRefs(variablesWithDateFormat, initialSuggestions);
			if (!refs) {
				setCurrentlyModifyingVarID(null);
				return;
			}
			if (refs.some(r => !r)) return;
			if (lastRefs.length !== refs.length || lastRefs.some((r, i) => r !== refs[i])) {
				setLastRefs(refs);
			}
			refs[0].focus();
		}
	}, [currentlyModifyingVarID, formattedVariables]);

	const [showPreviousMappingModal, setShowPreviousMappingModal] = useState(false);

	const {
		importType: previousImportType,
		formattedVariables: previousFormattedVariables,
		columnToMatchWithMainLevel: previousColumnToMatchWithMainLevel,
		variableToMatchOnMainLevel: previousVariableToMatchOnMainLevel
	} = previousMapping;

	const isPreviousImportType = previousImportType === ImportType.MoreEntriesToDataset;

	const availablePreviousFormattedVariables = previousFormattedVariables.filter(
		previousFormattedVariable =>
			formattedVariables.some(variable => variable.label === previousFormattedVariable.label)
	);
	const availablePreviousFormattedVariablesLabels = availablePreviousFormattedVariables.map(
		variable => variable.label
	);

	const canUsePreviousVariableToMatchOnMainLevel = mappedMainLevelVariables.some(
		variable =>
			variable.label === previousVariableToMatchOnMainLevel?.label &&
			variable.value === previousVariableToMatchOnMainLevel?.name
	);
	const canUsepreviousColumnToMatchWithMainLevel = initialNewVariables.some(
		variable => variable.previewVariableLabel === previousColumnToMatchWithMainLevel?.label
	);

	const getMainLevelMatching = isImportVariableSet
		? canUsePreviousVariableToMatchOnMainLevel && canUsepreviousColumnToMatchWithMainLevel
		: true;

	const canUsePreviousMapping =
		getMainLevelMatching &&
		isPreviousImportType &&
		availablePreviousFormattedVariables.length > 0;

	const hasPrecissionLoss = useMemo(
		() => hasPrecisionLossVariables(formattedVariables),
		[formattedVariables]
	);

	useEffectOnce(() => {
		if (isImportVariableSet && importVariableSetName) {
			setDestinationSetName(importVariableSetName);
		}
	});

	const isVariableUsed = (variableName: string) => {
		const isUsed = formattedVariables.find(
			element => element.previewVariableName === variableName
		);

		return isUsed ? true : false;
	};

	function loadPreviousMapping() {
		previousColumnToMatchWithMainLevel &&
			setColumnToMatchWithMainLevel(previousColumnToMatchWithMainLevel);
		previousVariableToMatchOnMainLevel &&
			setVariableToMatchOnMainLevel(previousVariableToMatchOnMainLevel);

		const newFormattedVariables = cloneDeep(formattedVariables);

		newFormattedVariables.forEach(item => {
			if (item.label === previousColumnToMatchWithMainLevel?.label) {
				const index = newFormattedVariables.indexOf(item);
				newFormattedVariables.splice(index, 1);
			}
		});
		const newVariables = newFormattedVariables.map(
			formattedVariable =>
				availablePreviousFormattedVariables.find(
					availablePreviousFormattedVariable =>
						availablePreviousFormattedVariable.label === formattedVariable.label
				) || formattedVariable
		);
		setFormattedVariables(newVariables);
	}

	const numberOfUnmatchedField = useMemo(() => {
		let number = 0;
		formattedVariables.forEach(formattedVariable => {
			if (
				formattedVariable.isNew &&
				((!formattedVariable.previewVariableLabel && formattedVariable.labelError) ||
					formattedVariable.previewVariableLabelError ===
						translate(
							({ projects }) =>
								projects.createAndImport.generics.previewVariables.noDuplicates
						))
			) {
				number = number + 1;
			}
		});
		return number;
	}, [formattedVariables]);

	const newFormattedVariables = useMemo(() => {
		if (!numberOfUnmatchedField && showUnmatchedFields) {
			return page;
		}
		if (showUnmatchedFields) {
			return page.filter(
				formattedVariable =>
					formattedVariable.isNew &&
					(formattedVariable.labelError || formattedVariable.previewVariableLabelError)
			);
		}
		return page;
	}, [page, showUnmatchedFields, numberOfUnmatchedField]);

	return (
		<>
			<NarrowContainer>
				{hasPrecissionLoss && (
					<>
						<PrecissionLossWarning />
						<Spacer size={s => s.s} />
					</>
				)}

				{importRequiresGlobalTimezoneSettings(formattedVariables) && (
					<TimeZoneOptions
						value={globalTimezone}
						onChange={handleChangeGlobalTimezone}
						applyToAll={() =>
							applyTimezoneToAllDatetimeVariables(
								formattedVariables,
								globalTimezone,
								setFormattedVariables,
								translate
							)
						}
					/>
				)}

				{numberOfUnmatchedField && showError ? (
					<ErrorContainer>
						<Flex>
							<Icon svg={Svgs.InformationRed} marginOffset={{ right: 0.8 }} />
							<Typography.Paragraph
								fontweight={f => f.bold}
								color={Colors.text.error}
							>
								{numberOfUnmatchedField}{' '}
								{translate(dict => dict.projects.createAndImport.unmatched)}
							</Typography.Paragraph>
						</Flex>
						<Icon
							svg={Svgs.Close}
							colors={{ color: Colors.text.error }}
							onClick={() => setShowError(false)}
							size={s => s.m}
						/>
					</ErrorContainer>
				) : null}

				{numberOfUnmatchedField ? (
					<Switch
						label={translate(dict => dict.projects.createAndImport.showUnmatched)}
						on={showUnmatchedFields}
						onChange={() => setShowUnmatchedFields(s => !s)}
						marginOffset={{ bottom: 1.6 }}
					/>
				) : null}

				{canUsePreviousMapping && (
					<Flex>
						<PreviousMappingButton
							variant={v => v.link}
							title={translate(
								({ projects }) =>
									projects.createAndImport.generics.previousMappingModal.title
							)}
							marginOffset={{ right: 2.4 }}
							onClick={() => setShowPreviousMappingModal(true)}
						/>
					</Flex>
				)}

				{isImportVariableSet && (
					<MatchColumnToMainVariableContainer>
						<FlexRow noMargin>
							<FlexCellLabel>
								<Typography.Caption fontweight={w => w.medium}>
									{translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.importVariableSet.mainFileColumn
									)}
								</Typography.Caption>
							</FlexCellLabel>
							<FlexCellIcon />
							<FlexCellType>
								<Typography.Caption fontweight={w => w.medium}>
									{translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.importVariableSet.mainVariableToMatch
									)}
								</Typography.Caption>
							</FlexCellType>
						</FlexRow>

						<FlexRow noMargin>
							<FlexCellLabel>
								<CreatableSelect
									canClear={false}
									className="select"
									items={mappedInitialVariables}
									scrollIntoView
									value={
										columnToMatchWithMainLevel
											? {
													label: columnToMatchWithMainLevel?.label,
													value: columnToMatchWithMainLevel?.label
											  }
											: {
													label: translate(
														dict =>
															dict.projectsPage.dataToEntries
																.columnName
													),
													value: translate(
														dict =>
															dict.projectsPage.dataToEntries
																.columnName
													)
											  }
									}
									onValueSelected={(_, index) =>
										handleSelectColumnToMatchWithMainLevel(index)
									}
								/>
							</FlexCellLabel>
							<FlexCellIcon>
								<Icon svg={Svgs.ArrowLongRight} />
							</FlexCellIcon>
							<FlexCellType>
								<CreatableSelect
									canClear={false}
									className="select"
									scrollIntoView
									value={
										variableToMatchOnMainLevel
											? {
													label: variableToMatchOnMainLevel.label,
													value: variableToMatchOnMainLevel.name
											  }
											: {
													label: translate(
														dict =>
															dict.projectsPage.dataToEntries
																.variableName
													),
													value: translate(
														dict =>
															dict.projectsPage.dataToEntries
																.variableName
													)
											  }
									}
									items={mappedMainLevelVariables}
									onValueSelected={(_, __, item) =>
										item &&
										item.value &&
										setVariableToMatchOnMainLevel({
											name: item.value,
											label: item.label
										})
									}
								/>
							</FlexCellType>
						</FlexRow>
					</MatchColumnToMainVariableContainer>
				)}

				<Flex>
					<FlexCellLabel style={{ marginBottom: '1.2rem' }}>
						<Flex justify={j => j.between}>
							<Flex>
								<Switch
									onChange={() => {
										setShowOnlyErrors(!showOnlyErrors);
									}}
									on={showOnlyErrors}
									dataTestId="show-only-errors"
								/>
								<Typography.Paragraph multiline>
									{translate(
										dict =>
											dict.projects.createAndImport.generics.previewVariables
												.onlyVariablesWithErrors
									)}
								</Typography.Paragraph>
							</Flex>
							{Object.values(suggestedErroredVariableLocationById).length > 0 && (
								<div>
									<Typography.Error
										style={{ fontSize: '1.4rem' }}
										id="detected-errors-warning"
									>
										{dynamicTranslate(
											`${translate(
												dict =>
													dict.projects.createAndImport.generics
														.previewVariables.foundUnresolved
											)} ${
												errorsCount === 1
													? translate(
															dict => dict.import.importReview.error
													  )
													: translate(
															dict => dict.import.importReview.errors
													  )
											}`,
											[errorsCount.toString()]
										)}
									</Typography.Error>
								</div>
							)}
						</Flex>
					</FlexCellLabel>
				</Flex>

				{shouldPaginate && (
					<Flex marginOffset={{ bottom: 1.6 }}>
						<Pagination
							totalCountLabel={translate(dict => dict.terms.variables)}
							pageIndex={pageIndex}
							pageSize={pageSize}
							pagesCount={pagesCount}
							changePage={changePage}
							changePageSize={changePageSize}
							totalCount={formattedVariables.length}
						/>
					</Flex>
				)}

				{newFormattedVariables.length ? (
					<FlexRow alignCenter>
						<FlexCellLabel>
							<ColumnTitle>
								{translate(
									({ projects }) =>
										projects.createAndImport.generics.entriesToDataset
											.columnTitle
								)}
							</ColumnTitle>
						</FlexCellLabel>
						<FlexCellIcon />
						<FlexCellType>
							<WithTooltip
								message={translate(
									({ projects }) =>
										projects.createAndImport.generics.entriesToDataset
											.dropdownTooltip
								)}
							>
								<ColumnTitle>
									{translate(
										({ projects }) =>
											projects.createAndImport.generics.entriesToDataset
												.dropdownsTitle
									)}
								</ColumnTitle>
							</WithTooltip>
						</FlexCellType>
					</FlexRow>
				) : null}
				<List>
					{newFormattedVariables.map(variable => (
						<FlexRow key={variable.id}>
							<FlexCellLabel
								id={
									variable.type === VariableType.Date
										? `preview-variable-cell-date-format-${variable.id}`
										: `preview-variable-cell-date-time-format-${variable.id}`
								}
							>
								<Input
									type={InputType.Text}
									id={`preview-variable-label-${variable.id}`}
									value={variable.label}
									placeholder={translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.variableNameHere
									)}
									disabled
								/>
								{[VariableType.Date, VariableType.DateTime].includes(
									variable.type as VariableType
								) && (
									<>
										{variableHasSuggestedDateFormat(
											variable,
											newFormattedVariables,
											isExcelOrigin
										) ? (
											<>
												<Spacer size={s => s.s} />
												<CreatableSelect
													scrollIntoView
													canClear={!!variable.dateFormat}
													_ref={el =>
														variable.type === VariableType.Date
															? el &&
															  (dateInputRefs.current[variable.id] =
																	el)
															: el &&
															  (dateTimeInputRefs.current[
																	variable.id
															  ] = el)
													}
													containerRef={el =>
														el &&
														(containerRefs.current[variable.id] = el)
													}
													id={
														variable.type === VariableType.Date
															? `preview-variable-date-format-${variable.id}`
															: `preview-variable-date-time-format-${variable.id}`
													}
													disabled={
														isExcelOrigin ||
														(variablesWithDateFormat.includes(
															variable.label
														) &&
															initialSuggestions?.find(
																v => v.name === variable.label
															)?.suggestedVariableType ===
																variable.type)
													}
													value={{
														label: matchDateTimeFormat(
															variable.dateFormat
														),
														value: matchDateTimeFormat(
															variable.dateFormat
														)
													}}
													items={
														variable.type === VariableType.Date
															? [
																	...dateFormatsSelectItems,
																	{
																		label: 'Custom',
																		value: 'Custom'
																	}
															  ]
															: [
																	...dateTimeFormatsSelectItems,
																	{
																		label: 'Custom',
																		value: 'Custom'
																	}
															  ]
													}
													onValueSelected={value => {
														value &&
															handleVariableDateTypeChange(
																variable.id,
																value,
																formattedVariables,
																setFormattedVariables,
																translate,
																globalTimezone
															);
														setCurrentlyModifyingVarID(variable.id);
													}}
													onClear={() => {
														handleVariableDateTypeChange(
															variable.id,
															'',
															formattedVariables,
															setFormattedVariables,
															translate,
															globalTimezone
														);
														setCurrentlyModifyingVarID(variable.id);
													}}
												/>
											</>
										) : (
											<>
												{!isExcelOrigin &&
													showDateFormatPicker(
														variable.type as VariableType,
														VariableType.Date,
														!!isExcelOrigin,
														isBinary
													) && (
														<>
															<Spacer size={s => s.s} />
															<FlexCellNewData
																stretch
																id={`preview-variable-cell-date-format-${variable.id}`}
															>
																<CreatableSelect
																	id={`preview-variable-date-format-${variable.id}`}
																	canClear={!!variable.dateFormat}
																	scrollIntoView
																	_ref={el =>
																		el &&
																		(dateInputRefs.current[
																			variable.id
																		] = el)
																	}
																	containerRef={el =>
																		el &&
																		(containerRefs.current[
																			variable.id
																		] = el)
																	}
																	disabled={
																		variablesWithDateFormat.includes(
																			variable.label
																		) &&
																		initialSuggestions?.find(
																			v =>
																				v.name ===
																				variable.label
																		)?.suggestedVariableType ===
																			variable.type
																	}
																	value={
																		variable.dateFormat
																			? {
																					value: matchDateTimeFormat(
																						variable.dateFormat
																					),
																					label: matchDateTimeFormat(
																						variable.dateFormat
																					)
																			  }
																			: {
																					label: translate(
																						dict =>
																							dict
																								.projectsPage
																								.dataToEntries
																								.selectDateFormat
																					),
																					value: translate(
																						dict =>
																							dict
																								.projectsPage
																								.dataToEntries
																								.selectDateFormat
																					)
																			  }
																	}
																	items={[
																		{
																			label: 'Custom',
																			value: 'Custom'
																		},
																		...dateFormatsSelectItems
																	]}
																	onValueSelected={value => {
																		value &&
																			handleVariableDateTypeChange(
																				variable.id,
																				value,
																				formattedVariables,
																				setFormattedVariables,
																				translate,
																				globalTimezone
																			);
																		setCurrentlyModifyingVarID(
																			variable.id
																		);
																	}}
																	onClear={() => {
																		handleVariableDateTypeChange(
																			variable.id,
																			'',
																			formattedVariables,
																			setFormattedVariables,
																			translate,
																			globalTimezone
																		);
																		setCurrentlyModifyingVarID(
																			variable.id
																		);
																	}}
																	error={
																		variable.dateFormat !==
																			DATE_FORMATS.Custom &&
																		variable.dateFormatError
																			? variable.dateFormatError
																			: ''
																	}
																/>
															</FlexCellNewData>
														</>
													)}

												{showDateFormatPicker(
													variable.type as VariableType,
													VariableType.DateTime,
													!!isExcelOrigin,
													isBinary
												) && (
													<>
														<>
															<Spacer size={s => s.s} />
															<FlexCellNewData
																stretch
																id={`preview-variable-cell-date-time-format-${variable.id}`}
															>
																<CreatableSelect
																	id={`preview-variable-date-time-format-${variable.id}`}
																	canClear={!!variable.dateFormat}
																	scrollIntoView
																	_ref={el =>
																		el &&
																		(dateTimeInputRefs.current[
																			variable.id
																		] = el)
																	}
																	containerRef={el =>
																		el &&
																		(containerRefs.current[
																			variable.id
																		] = el)
																	}
																	disabled={
																		variablesWithDateFormat.includes(
																			variable.label
																		) &&
																		initialSuggestions?.find(
																			v =>
																				v.name ===
																				variable.label
																		)?.suggestedVariableType ===
																			variable.type
																	}
																	value={
																		variable.dateFormat
																			? {
																					value: matchDateTimeFormat(
																						variable.dateFormat
																					),
																					label: matchDateTimeFormat(
																						variable.dateFormat
																					)
																			  }
																			: {
																					value: translate(
																						dict =>
																							dict
																								.projectsPage
																								.dataToEntries
																								.selectDateAndTime
																					),
																					label: translate(
																						dict =>
																							dict
																								.projectsPage
																								.dataToEntries
																								.selectDateAndTime
																					)
																			  }
																	}
																	items={[
																		{
																			label: 'Custom',
																			value: 'Custom'
																		},
																		...dateTimeFormatsSelectItems
																	]}
																	onValueSelected={value => {
																		value &&
																			handleVariableDateTypeChange(
																				variable.id,
																				value,
																				formattedVariables,
																				setFormattedVariables,
																				translate,
																				globalTimezone
																			);
																		setCurrentlyModifyingVarID(
																			variable.id
																		);
																	}}
																	onClear={() => {
																		handleVariableDateTypeChange(
																			variable.id,
																			'',
																			formattedVariables,
																			setFormattedVariables,
																			translate,
																			globalTimezone
																		);
																		setCurrentlyModifyingVarID(
																			variable.id
																		);
																	}}
																	error={
																		variable.dateFormat !==
																			DATE_FORMATS.Custom &&
																		variable.dateFormatError
																			? variable.dateFormatError
																			: ''
																	}
																/>
															</FlexCellNewData>
														</>
													</>
												)}
											</>
										)}
										{variable.dateFormat === DATE_FORMATS.Custom && (
											<>
												<Spacer size={s => s.s} />
												<Input
													type={InputType.Text}
													ref={el =>
														el &&
														(customFormatInputRefs.current[
															variable.id
														] = el as HTMLInputElement)
													}
													id={`preview-variable-custom-date-time-format-${variable.id}`}
													value={variable.customDateFormat}
													error={variable.dateFormatError}
													placeholder={translate(
														dict =>
															dict.projectsPage.dataToEntries
																.customDateFormat
													)}
													onChange={e => {
														handleCustomDateFormatChange(
															variable.id,
															e.target.value.toUpperCase(),
															formattedVariables,
															setFormattedVariables,
															translate,
															variable.type === VariableType.DateTime
														);
														setCurrentlyModifyingVarID(variable.id);
													}}
												/>
											</>
										)}
									</>
								)}

								{/* TIME DURATION SOURCE UNIT DROPDOWN */}
								{variable.type === VariableType.TimeDuration && (
									<>
										<Spacer size={s => s.s} />
										<CreatableSelect
											canClear={true}
											_ref={el =>
												el &&
												(timeDurationSourceRefs.current[variable.id] = el)
											}
											containerRef={el =>
												el &&
												(containerRefs.current[variable.id + '-source'] =
													el)
											}
											id={`preview-variable-time-duration-source-${variable.id}`}
											items={timeUnitSelectItems}
											placeholder={'Choose source unit'}
											onClear={() => {
												handleSourceUnitChange(
													variable.id,
													'',
													formattedVariables,
													setFormattedVariables,
													translate
												);
											}}
											onValueSelected={value => {
												value &&
													handleSourceUnitChange(
														variable.id,
														value,
														formattedVariables,
														setFormattedVariables,
														translate
													);
												setCurrentlyModifyingVarID(variable.id);
											}}
											value={
												variable.timeDurationSource
													? {
															label:
																variable.timeDurationSource
																	.charAt(0)
																	.toUpperCase() +
																variable.timeDurationSource.slice(
																	1
																),
															value: variable.timeDurationSource
													  }
													: null
											}
											error={variable.timeDurationSourceError}
										/>
									</>
								)}
							</FlexCellLabel>
							<FlexCellIcon>
								<Icon svg={Svgs.ArrowLongRight} />
							</FlexCellIcon>
							<FlexCellType>
								<FlexRow>
									{/** CREATE OR CHOOSE VARIABLE TO MATCH */}

									<CreatableSelect
										canClear={!!variable.previewVariableName}
										id={`preview-variable-match-or-create-dropdown-${variable.id}`}
										scrollIntoView
										disabled={loadingVariables}
										items={formattedDropdownValues.map(item =>
											isVariableUsed(item.value)
												? { ...item, disabled: true }
												: item
										)}
										value={
											variable.isNew
												? {
														value: createNewVariable,
														label: createNewVariable
												  }
												: {
														value: variable.previewVariableName,
														label: variable.previewVariableLabel
												  }
										}
										onValueSelected={(value, index) => {
											value &&
												onVariablePreviewNameChanged(
													variable.id,
													variable.previewVariableLabel,
													value,
													index
												);
										}}
										onClear={() => handleClearVariable(variable.id)}
										error={
											!variable.isNew
												? variable.previewVariableLabelError
												: undefined
										}
										placeholder={translate(
											({ projects }) =>
												projects.createAndImport.generics.previewVariables
													.createOrChooseVariable
										)}
									/>
								</FlexRow>
								{variable.isNew ? (
									<>
										<Spacer size={s => s.s} />
										<FlexRow noMargin>
											<FlexCellNewLabel>
												<Input
													type={InputType.Text}
													id={`preview-variable-label-${variable.id}`}
													value={variable.previewVariableLabel}
													error={variable.previewVariableLabelError}
													placeholder={translate(
														({ projects }) =>
															projects.createAndImport.generics
																.previewVariables.variableNameHere
													)}
													onChange={e => {
														onVariableLabelChange({
															varId: variable.id,
															value: e.target.value,
															isPreviewVariable: true,
															duplicationList: duplicationLists.find(
																duplications =>
																	duplications.includes(
																		variable.id
																	)
															)
														});
													}}
												/>
											</FlexCellNewLabel>
											<FlexCellNewData>
												<CreatableSelect
													id={`preview-variable-type-${variable.id}`}
													canClear={
														variable.type !== '' &&
														variable.type !== null
													}
													scrollIntoView
													allowCreate={false}
													required={true}
													value={
														variable.type !== '' &&
														variable.type !== null
															? {
																	label: translate(
																		dict =>
																			dict.variableLabels[
																				variable.type as VariableType
																			]
																	),
																	value: variable.type as VariableType
															  }
															: {
																	label: translate(
																		dict =>
																			dict.radioGroups
																				.valueRequired
																	),
																	value: translate(
																		dict =>
																			dict.radioGroups
																				.valueRequired
																	)
															  }
													}
													items={dataTypeSelectItems}
													onValueSelected={value => {
														onVariableTypeChanged(
															variable.id,
															value as VariableType
														);
													}}
													error={
														variable.type !== '' &&
														variable.type !== null
															? ''
															: translate(
																	dict =>
																		dict.radioGroups
																			.valueRequired
															  )
													}
												/>
											</FlexCellNewData>
										</FlexRow>
									</>
								) : null}

								{requiresTimezoneSettings(variable) && (
									<>
										<Spacer size={s => s.xs} />
										<CreatableSelect
											canClear={true}
											id={`global_timezone_select`}
											items={timeZones}
											placeholder={translate(
												dict =>
													dict.projects.createAndImport.generics
														.previewVariables.timeZonePlaceholder
											)}
											onClear={() =>
												handleVariableTimeZoneChange(
													variable.id,
													'',
													formattedVariables,
													setFormattedVariables,
													translate
												)
											}
											onValueSelected={value =>
												value &&
												handleVariableTimeZoneChange(
													variable.id,
													value,
													formattedVariables,
													setFormattedVariables,
													translate
												)
											}
											value={
												variable.timeZone && variable.timeZone.value
													? variable.timeZone
													: null
											}
											error={variable.timeZoneError}
										/>
									</>
								)}

								{/* TIME DURATION INTERVAL DROPDOWN*/}
								{variable.type === VariableType.TimeDuration && variable.isNew && (
									<>
										<Spacer size={s => s.s} />
										<IntervalDropdown
											id={`preview-variable-time-duration-interval-${variable.id}`}
											dropdownRef={el =>
												el &&
												(timeDurationFormatRefs.current[variable.id] = el)
											}
											containerRef={el =>
												el &&
												(containerRefs.current[variable.id + '-format'] =
													el)
											}
											value={getTimeDurationValue(variable, translate)}
											onSelect={item =>
												handleDurationFormatChange(
													variable.id,
													formattedVariables,
													item,
													setFormattedVariables,
													translate
												)
											}
											items={getDropdownTimeUnits(
												variable.durationFormat,
												timeUnitSelectItems
											)}
											error={variable.durationFormatError}
										/>
									</>
								)}
							</FlexCellType>
						</FlexRow>
					))}
				</List>
			</NarrowContainer>

			<StickyFooter
				primary={{
					label: translate(({ buttons }) => buttons.continue),
					loading: loadingGenerateVariableNames,
					disabled: canUpload(),
					onClick: () => upload()
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.back),
					onClick: handleBack
				}}
				maxWidth={65.2}
				zIndex={1006}
			/>

			{showPreviousMappingModal && (
				<PreviousMappingModal
					previousVariablesLabels={availablePreviousFormattedVariablesLabels}
					onConfirm={() => {
						loadPreviousMapping();
						setShowPreviousMappingModal(false);
					}}
					onClose={() => setShowPreviousMappingModal(false)}
				/>
			)}
			{/* ASSIGN GROUP MODAL */}
			<AssignProjectOrganizationModal
				visible={showAssignOrganizationModal}
				onContinue={() => {
					setShowAssignOrganizationModal(false);
				}}
				onClose={handleBack}
				organization={assignedOrganization}
				onSelect={id => setAssignedOrganizationId(id)}
				userOrganizations={userOrganizations}
			/>
		</>
	);
}

interface WithToolTipProps {
	message: string;
	children: React.ReactNode;
}

function WithTooltip({ children, message }: WithToolTipProps) {
	const [tooltipVisible, setTooltipVisible] = useState(false);

	return (
		<TooltipContainer
			onMouseEnter={() => setTooltipVisible(true)}
			// onMouseLeave={() => setTooltipVisible(false)}
		>
			<div>{children}</div>
			<InfoTooltip marginOffset={{ left: 1.4 }} iconVisible={tooltipVisible} text={message} />
		</TooltipContainer>
	);
}
