import { useMemo } from 'react';
import { VariableType } from 'types/data/variables/constants';
import { Dependency, DependencyType, SubRows, TableDependency } from 'store/data/dependencies';
import { SelectItem } from 'types/index';
import { Container } from './Builder.styles';

import { buildVariableSetVariablesData, buildVariablesRichData } from 'helpers/variables';
import { useVariablesData } from 'hooks/store';
import { DependenciesGrid } from '../DependenciesGrid';
import { DependenciesTable } from '../DependenciesTable';

interface Props {
	context: string | null;
	dependencies: Dependency[];
	areRulesExpanded: boolean;
	isGridView: boolean;
	scopeDependenciesData: { active: boolean; dependencies: Dependency[] };
	openModal: boolean;
	onClose: () => void;
	collapseAllRules: () => void;
}

export function Builder({
	context,
	dependencies,
	areRulesExpanded,
	isGridView,
	scopeDependenciesData,
	openModal,
	onClose,
	collapseAllRules
}: Props) {
	const variablesData = useVariablesData({ initial: true });

	const scopeVariables = useMemo(() => {
		let scopeVariablesData = variablesData;

		if (context !== null) {
			scopeVariablesData = buildVariableSetVariablesData({
				setName: context,
				variablesData
			});
		}

		const { variablesOutsideSets } = buildVariablesRichData(scopeVariablesData, {
			omit: {
				promGenerated: true,
				types: [VariableType.File, VariableType.Unique]
			}
		});

		return variablesOutsideSets;
	}, [variablesData, context]);

	// COMPUTED HERE FOR PERFORMANCE OPTIMISATIONS
	const dependencySuppliersSelectItems = useMemo(() => {
		const selectItems: SelectItem[] = [];

		scopeVariables
			.filter(variable => variable.type !== VariableType.TimeDuration)
			.forEach(variable => {
				const selectItem: SelectItem = {
					label: variable.label,
					value: variable.name
				};

				const isCategory = [VariableType.Category, VariableType.CategoryMultiple].includes(
					variable.type
				);
				const hasFixedCategories = variable.fixedCategories;

				if (isCategory && !hasFixedCategories) return;
				if (variable.personalData) return;

				selectItems.push(selectItem);
			});

		return selectItems;
	}, [scopeVariables]);

	// COMPUTED HERE FOR PERFORMANCE OPTIMISATIONS
	function getDependantVariablesSelectItems(dependency: Dependency | TableDependency | SubRows) {
		const isVisibilityCondition = dependency.dependencyType === DependencyType.Visibility;
		const isFilteringCondition = dependency.dependencyType === DependencyType.Filtering;

		const selectItems: SelectItem[] = [];

		scopeVariables.forEach(variable => {
			const selectItem = {
				label: variable.label,
				value: variable.name
			};

			let include = true;

			const isDependencySupplier = dependency.supplierVariableName === variable.name;

			if (isDependencySupplier) return;

			// VISIBILITY
			if (isVisibilityCondition) {
				include = !(variable.obligatory || variable.personalData);
			}

			// FILTERING
			if (isFilteringCondition) {
				const isCategory = [VariableType.Category, VariableType.CategoryMultiple].includes(
					variable.type
				);
				const hasFixedCategories = variable.fixedCategories;

				include = isCategory && hasFixedCategories;
			}

			if (include) selectItems.push(selectItem);
		});

		return selectItems;
	}

	return (
		<Container>
			{isGridView ? (
				<DependenciesGrid
					dependencies={dependencies}
					areRulesExpanded={areRulesExpanded}
					getDependantVariablesSelectItems={getDependantVariablesSelectItems}
					dependencySuppliersSelectItems={dependencySuppliersSelectItems}
				/>
			) : (
				<DependenciesTable
					areRulesExpanded={areRulesExpanded}
					dependencies={dependencies}
					getDependantVariablesSelectItems={getDependantVariablesSelectItems}
					dependencySuppliersSelectItems={dependencySuppliersSelectItems}
					context={context}
					scopeDependenciesData={scopeDependenciesData}
					openModal={openModal}
					onClose={onClose}
					collapseAllRules={collapseAllRules}
				/>
			)}
		</Container>
	);
}
