import { Columns, FrequenciesAnalysisV2 } from 'api/data/analyses';
import { isAnalysisInputValid } from 'helpers/analysis';
import { ActionTypes, VariablesDataSelectItems } from 'store/data/analyses';

import { FrequenciesViewV2 } from './FrequenciesViewV2/FrequenciesViewV2';
import { MediaQueries } from 'environment';
import { FrequenciesConfigV2 } from './FrequenciesConfigV2';

import { InvalidAnalysis } from '..';
import { AnalysisContainer, AnalysisViewContainer } from '../UI';
import { VariablesData } from 'store/data/variables';
import {
	useFetchAnalysis,
	useFullscreenAnalysis,
	useAnalysesActiveColum,
	useTranslation
} from 'hooks/store';
import { useMediaQuery } from 'hooks/utils';
import { withMemo } from 'helpers/HOCs';
import { Loader } from 'components/UI/Loader';

interface Props {
	analysis: FrequenciesAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
}

function Component({ analysis, variablesDataSelectItems, variablesData, exporting }: Props) {
	const { translate } = useTranslation();

	const [fullscreen, setFullscreenAnalysis] = useFullscreenAnalysis();

	const { loading } = useFetchAnalysis({
		id: analysis.id,
		actionType: ActionTypes.GET_FREQUENCIES_V2,
		initialFetchDependency: 'output.dataset.results'
	});

	const analysisValidInput = isAnalysisInputValid(analysis);
	const [activeColumn] = useAnalysesActiveColum();
	const isMobileDevice = useMediaQuery(
		`only screen and ${MediaQueries.minWidth.xs} and ${MediaQueries.maxWidth.sm}`
	);

	const handleFullscreen = () => {
		if (
			(activeColumn !== Columns.OneColumn && !isMobileDevice) ||
			(activeColumn === Columns.OneColumn && isMobileDevice)
		) {
			setFullscreenAnalysis({ fullscreen: analysis.id });
		}
	};

	if (analysisValidInput) {
		return (
			<AnalysisContainer onClick={() => handleFullscreen()} activeColumn={activeColumn}>
				{!loading ? (
					<FrequenciesViewV2
						analysis={analysis}
						variablesData={variablesData}
						loading={loading}
						exporting={exporting}
					/>
				) : (
					<AnalysisViewContainer isConfigPanelOpen={analysis.options.configPanel.open}>
						<Loader primary />
					</AnalysisViewContainer>
				)}

				{(analysis.options.configPanel.open &&
					activeColumn === Columns.OneColumn &&
					!isMobileDevice) ||
				(analysis.options.configPanel.open && fullscreen) ? (
					<FrequenciesConfigV2
						analysis={analysis}
						variablesDataSelectItems={variablesDataSelectItems}
						loading={loading}
					/>
				) : null}
			</AnalysisContainer>
		);
	}

	return (
		<AnalysisContainer invalid>
			<InvalidAnalysis
				{...(!analysisValidInput
					? {
							headerMessage: translate(
								dict => dict.analysis.errors.invalidAnalysis.selectInputHeader
							)
					  }
					: {})}
				{...(!analysisValidInput
					? {
							bodyMessage: translate(
								dict => dict.analysis.errors.invalidAnalysis.selectInputBody
							)
					  }
					: {})}
				activeColumn={activeColumn}
				fullScreen={!!fullscreen}
				isConfigPanelOpen={analysis.options.configPanel.open}
				category
			/>
			{(analysis.options.configPanel.open &&
				activeColumn === Columns.OneColumn &&
				!isMobileDevice) ||
			(analysis.options.configPanel.open && (analysis.activeTab === 1 || fullscreen)) ? (
				<FrequenciesConfigV2
					analysis={analysis}
					variablesDataSelectItems={variablesDataSelectItems}
					loading={loading}
				/>
			) : null}
		</AnalysisContainer>
	);
}

export const FrequenciesV2 = withMemo(Component, [
	'analysis',
	'variablesData',
	'variablesDataSelectItems',
	'exporting'
]);
