import { useMemo, useCallback } from 'react';
import { useVariablesQuery, VariablesData } from './useVariablesQuery/useVariablesQuery';
import { DependencyData, useGetVariableDependenciesQuery } from './useGetVariableDependenciesQuery';
import {
	DependencyRule,
	VariableGroup,
	Variable,
	VariableType,
	DateTimeVariable,
	DateVariable,
	FileVariable,
	FloatVariable,
	IntegerVariable,
	StringVariable,
	TimeDurationVariable,
	UserDefinedUniqueVariable,
	CategoryMultipleFixedVariable,
	CategoryFixedVariable,
	isCategoryFixedVariable,
	isCategoryMultipleFixedVariable,
	CategoryMultipleNonFixedVariable,
	CategoryNonFixedVariable
} from '../types';
import { toDependencyRules } from '../utils/dependencyRules';

export type GroupFormItem = {
	type: 'group';
	group: VariableGroup;
	formItems: VariableFormItem[];
};

export type FormItem = VariableFormItem | GroupFormItem;

export type ProjectData = {
	/** Form items represents the tree structure which we should render for this project and the order of the items */
	formItems: FormItem[];
	/** Variables are all the variables for this project */
	variables: Variable[];
};

export const toProjectData = (
	variablesData: VariablesData,
	dependenciesData: DependencyData
): ProjectData => {
	const formItems: FormItem[] = [];

	const dependencyRules: DependencyRule[] = toDependencyRules(
		Object.values(variablesData.variables),
		dependenciesData.active ? dependenciesData.dependencies : []
	);

	for (const item of variablesData.order) {
		if (item.variable && variablesData.variables[item.variable]) {
			formItems.push(
				backendVariableToVariableFormItem({
					variable: variablesData.variables[item.variable],
					dependencies: dependencyRules.filter(
						dependency => dependency.dependantVariable.variableName === item.variable
					)
				})
			);
			continue;
		}

		if (item.group && variablesData.groups[item.group]) {
			formItems.push({
				type: 'group',
				group: variablesData.groups[item.group],
				formItems: variablesData.groups[item.group].variablesBelongingToGroup.map(
					variableName => {
						const variable = variablesData.variables[variableName];
						return backendVariableToVariableFormItem({
							variable,
							dependencies: dependencyRules.filter(
								dependency =>
									dependency.dependantVariable.variableName === variableName
							)
						});
					}
				)
			});
			continue;
		}
		console.error('Unknown item in order', item);
	}

	return { formItems, variables: Object.values(variablesData.variables) };
};

export const useProjectData = ({ projectId }: { projectId: string }) => {
	const {
		data: variablesData,
		isLoading: variablesLoading,
		isFetching: variablesFetching,
		error: variablesError,
		refetch: refetchVariables
	} = useVariablesQuery({
		projectId
	});

	const {
		data: dependenciesData,
		isLoading: dependenciesLoading,
		isFetching: dependenciesFetching,
		error: dependenciesError,
		refetch: refetchDependencies
	} = useGetVariableDependenciesQuery({
		projectId
	});

	const processedData = useMemo(() => {
		if (variablesData && dependenciesData) {
			return toProjectData(variablesData, dependenciesData);
		}
	}, [variablesData, dependenciesData]);

	const refetch = useCallback(async () => {
		await Promise.all([refetchVariables(), refetchDependencies()]);
	}, [refetchVariables, refetchDependencies]);

	return {
		data: processedData,
		isLoading: variablesLoading || dependenciesLoading,
		isFetching: variablesFetching || dependenciesFetching,
		error: variablesError || dependenciesError,
		refetch
	};
};

type VariableFormItemBase = {
	type: 'variable';
	variableType: VariableType;
	variable: Variable;
	dependencies: DependencyRule[];
};

type IntegerFormItem = VariableFormItemBase & {
	variableType: 'integer';
	variable: IntegerVariable;
	dependencies: DependencyRule[];
};

type FloatFormItem = VariableFormItemBase & {
	variableType: 'float';
	variable: FloatVariable;
	dependencies: DependencyRule[];
};

type DateFormItem = VariableFormItemBase & {
	variableType: 'date';
	variable: DateVariable;
	dependencies: DependencyRule[];
};

type DateTimeFormItem = VariableFormItemBase & {
	variableType: 'datetime';
	variable: DateTimeVariable;
	dependencies: DependencyRule[];
};

type FileFormItem = VariableFormItemBase & {
	variableType: 'file';
	variable: FileVariable;
	dependencies: DependencyRule[];
};

type UserDefinedUniqueFormItem = VariableFormItemBase & {
	variableType: 'userDefinedUnique';
	variable: UserDefinedUniqueVariable;
	dependencies: DependencyRule[];
};

type TimeDurationFormItem = VariableFormItemBase & {
	variableType: 'timeDuration';
	variable: TimeDurationVariable;
	dependencies: DependencyRule[];
};

export type CategoryNonFixedFormItem = VariableFormItemBase & {
	variableType: 'category';
	fixedCategories: 'no';
	variable: CategoryNonFixedVariable;
	dependencies: DependencyRule[];
};

export const isCategoryNonFixedFormItem = (
	item: VariableFormItem
): item is CategoryNonFixedFormItem =>
	item.variableType === 'category' && item.fixedCategories === 'no';

export type CategoryFixedFormItem = VariableFormItemBase & {
	variableType: 'category';
	fixedCategories: 'yes';
	variable: CategoryFixedVariable;
	dependencies: DependencyRule[];
};

export const isCategoryFixedFormItem = (item: VariableFormItem): item is CategoryFixedFormItem =>
	item.variableType === 'category' && item.fixedCategories === 'yes';

export type CategoryMultipleNonFixedFormItem = VariableFormItemBase & {
	variableType: 'categoryMultiple';
	fixedCategories: 'no';
	variable: CategoryMultipleNonFixedVariable;
	dependencies: DependencyRule[];
};

export const isCategoryMultipleNonFixedFormItem = (
	item: VariableFormItem
): item is CategoryMultipleNonFixedFormItem =>
	item.variableType === 'categoryMultiple' && item.fixedCategories === 'no';

export type CategoryMultipleFixedFormItem = VariableFormItemBase & {
	variableType: 'categoryMultiple';
	fixedCategories: 'yes';
	variable: CategoryMultipleFixedVariable;
	dependencies: DependencyRule[];
};
export const isCategoryMultipleFixedFormItem = (
	item: VariableFormItem
): item is CategoryMultipleFixedFormItem =>
	item.variableType === 'categoryMultiple' && item.fixedCategories === 'yes';

type StringFormItem = VariableFormItemBase & {
	variableType: 'string';
	variable: StringVariable;
	dependencies: DependencyRule[];
};

export type VariableFormItem =
	| IntegerFormItem
	| FloatFormItem
	| DateFormItem
	| DateTimeFormItem
	| FileFormItem
	| UserDefinedUniqueFormItem
	| TimeDurationFormItem
	| CategoryNonFixedFormItem
	| CategoryFixedFormItem
	| CategoryMultipleNonFixedFormItem
	| CategoryMultipleFixedFormItem
	| StringFormItem;

export const backendVariableToVariableFormItem = ({
	dependencies,
	variable
}: {
	variable: Variable;
	dependencies: DependencyRule[];
}): VariableFormItem => {
	switch (variable.variableType) {
		case 'integer':
			return {
				type: 'variable',
				variableType: 'integer',
				variable,
				dependencies
			};

		case 'float':
			return {
				type: 'variable',
				variableType: 'float',
				variable: variable,
				dependencies
			};

		case 'date':
			return {
				type: 'variable',
				variableType: 'date',
				variable: variable,
				dependencies
			};

		case 'datetime':
			return {
				type: 'variable',
				variableType: 'datetime',
				variable: variable,
				dependencies
			};

		case 'file':
			return {
				type: 'variable',
				variableType: 'file',
				variable: variable,
				dependencies
			};

		case 'userDefinedUnique':
			return {
				type: 'variable',
				variableType: 'userDefinedUnique',
				variable: variable,
				dependencies
			};

		case 'timeDuration':
			return {
				type: 'variable',
				variableType: 'timeDuration',
				variable: variable,
				dependencies
			};

		case 'category':
			if (isCategoryFixedVariable(variable)) {
				return {
					type: 'variable',
					variableType: 'category',
					fixedCategories: 'yes',
					variable: variable,
					dependencies
				};
			}
			return {
				type: 'variable',
				variableType: 'category',
				fixedCategories: 'no',
				variable: variable,
				dependencies
			};

		case 'categoryMultiple':
			if (isCategoryMultipleFixedVariable(variable)) {
				return {
					type: 'variable',
					variableType: 'categoryMultiple',
					fixedCategories: 'yes',
					variable: variable,
					dependencies
				};
			}
			return {
				type: 'variable',
				variableType: 'categoryMultiple',
				fixedCategories: 'no',
				variable: variable,
				dependencies
			};

		case 'string':
			return {
				type: 'variable',
				variableType: 'string',
				variable: variable,
				dependencies
			};
	}
};
