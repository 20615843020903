// Basic type definitions
export type TimeUnit =
	| 'weeks'
	| 'days'
	| 'hours'
	| 'minutes'
	| 'seconds'
	| 'milliseconds'
	| 'microseconds';

// Common types
type BaseVariable = {
	variableName: string;
	variableLabel: string;
	obligatory: boolean;
	description: string;
	entryType?: 'entry' | 'calculated';
};

type FixedCategoryBase = {
	fixedCategories: 'yes';
	allowedCategories: CategoryOption[];
	optimizeForManyValues: boolean;
};

type NonFixedCategoryBase = {
	fixedCategories: 'no';
	categories: string[];
	optimizeForManyValues: boolean;
};

export type CategoryOption = {
	description: string;
	label: string;
	value: string;
	id: string;
};

type ValidationEnabled = {
	validationCases?: ValidationCase[];
};

// Specific variable types
export type CategoryNonFixedVariable = BaseVariable &
	NonFixedCategoryBase & {
		variableType: 'category';
	};
export const isCategoryFixedVariable = (variable: Variable): variable is CategoryFixedVariable =>
	variable.variableType === 'category' && variable.fixedCategories === 'yes';

export type CategoryFixedVariable = BaseVariable &
	FixedCategoryBase & {
		variableType: 'category';
	};
export const isCategoryNonFixedVariable = (
	variable: Variable
): variable is CategoryNonFixedVariable =>
	variable.variableType === 'category' && variable.fixedCategories === 'no';

export type CategoryVariable = CategoryNonFixedVariable | CategoryFixedVariable;

export type CategoryMultipleNonFixedVariable = BaseVariable &
	NonFixedCategoryBase & {
		variableType: 'categoryMultiple';
	};
export const isCategoryMultipleNonFixedVariable = (
	variable: Variable
): variable is CategoryMultipleNonFixedVariable =>
	variable.variableType === 'categoryMultiple' && variable.fixedCategories === 'no';

export type CategoryMultipleFixedVariable = BaseVariable &
	FixedCategoryBase & {
		variableType: 'categoryMultiple';
	};
export const isCategoryMultipleFixedVariable = (
	variable: Variable
): variable is CategoryMultipleFixedVariable =>
	variable.variableType === 'categoryMultiple' && variable.fixedCategories === 'yes';

export type CategoryMultipleVariable =
	| CategoryMultipleNonFixedVariable
	| CategoryMultipleFixedVariable;

export type StringVariable = BaseVariable & {
	variableType: 'string';
};

export type IntegerVariable = BaseVariable &
	ValidationEnabled & {
		variableType: 'integer';
	};

export type FloatVariable = BaseVariable &
	ValidationEnabled & {
		variableType: 'float';
	};

export type DateVariable = BaseVariable &
	ValidationEnabled & {
		variableType: 'date';
	};

export type DateTimeVariable = BaseVariable &
	ValidationEnabled & {
		variableType: 'datetime';
	};

export type FileVariable = BaseVariable & {
	variableType: 'file';
};

export type UserDefinedUniqueVariable = BaseVariable & {
	variableType: 'userDefinedUnique';
	uniquenessType: 'Manual' | 'Sequence' | 'UUID';
};

export type TimeDurationVariable = BaseVariable & {
	variableType: 'timeDuration';
	durationFormat: {
		maxTimeUnit: TimeUnit;
		minTimeUnit: TimeUnit;
	};
};

export type VariableGroup = {
	groupLabel: string;
	groupName: string;
	variablesBelongingToGroup: string[];
};

export type VariableGroups = Record<string, VariableGroup[]>;

// Union type for all variables
export type Variable =
	| CategoryNonFixedVariable
	| CategoryFixedVariable
	| CategoryMultipleNonFixedVariable
	| CategoryMultipleFixedVariable
	| StringVariable
	| IntegerVariable
	| FloatVariable
	| DateVariable
	| DateTimeVariable
	| FileVariable
	| UserDefinedUniqueVariable
	| TimeDurationVariable;

export type VariableType = Variable['variableType'];

// Validation types
export type ValidationCase = {
	validationExpression: ValidationExpression;
};

export type ValidationExpression = {
	operator: Operator;
	operands: Operand[];
};

export type Operator =
	| 'op1_or_op2'
	| 'op1_float_val'
	| 'op1_integer_val'
	| 'op1_date_val'
	| 'op1_greater_than_or_equals_op2'
	| 'op1_less_than_or_equals_op2'
	| 'op1_variable_ref'
	| 'no_op_null_val'
	| 'op1_equals_op2';

export type Operand = ValidationExpression | string | number | boolean;

// Entry types
export type Status = Record<string, string>;

export type Entry = {
	[key: string]: any;
};

/**
 * Including these types on the base Entry caused a lot of type errors inside of the EntryForm, so they need to be added explicitly when needed.
 */
export type OurInternalCustomEntryFields = {
	active: boolean;
	creationdate: string;
	datasetentryid: string;
	enteredbyuser: string;
	lastmodifieddate: string;
	original_id: string;
	ownedbyuser: string;
	parentsetrevisionid: string;
	userProjectOrgId: number;
};

export type EntryAccess = {
	delete: boolean;
	write: boolean;
};

export type DependencyOperator = '<' | '<=' | '=' | '>=' | '>';

export type VisibilityDependencyRule = {
	dependencyType: 'Visibility';
	supplierVariable: Variable;
	dependantVariable: Variable;
	operator: DependencyOperator;
	value: string;
};

export type FilteringDependencyRule = {
	dependencyType: 'Filtering';
	supplierVariable: Variable;
	dependantVariable: Variable;
	operator: DependencyOperator;
	value: string;
	filteredValues: string[];
};
export const isFilteringDependencyRule = (rule: DependencyRule): rule is FilteringDependencyRule =>
	rule.dependencyType === 'Filtering';
export const isVisibilityDependencyRule = (
	rule: DependencyRule
): rule is VisibilityDependencyRule => rule.dependencyType === 'Visibility';

export type DependencyRule = VisibilityDependencyRule | FilteringDependencyRule;
