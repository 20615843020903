import { Modal } from 'components/UI/Modal';
import { DiffedField, EntryValue } from '../utils/formUtils';
import { DateTime } from 'luxon';
import {
	CategoryMultipleVariable,
	CategoryVariable,
	isCategoryFixedVariable,
	isCategoryMultipleFixedVariable,
	Variable
} from '../types';
import { FrontendFormFile, isBackendFormFile, isFrontendFormFile } from '../utils/zodUtils';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/UI/Loader';
import { Button } from '../component/Button';
import { useFileInfoQuery } from '../data/useFileInfoQuery';

export type ConfirmDiffedFieldsModalData = {
	diffedFields: Record<string, DiffedField>;
	dirtyFields: Record<string, EntryValue>;
};

export const ConfirmDiffModal = ({
	onClose,
	diff,
	onConfirm,
	onDelete,
	submitting,
	variables
}: {
	onClose: () => void;
	diff?: Record<string, DiffedField>;
	onConfirm?: () => void;
	submitting?: boolean;
	variables: Variable[];
	onDelete: () => void;
}) => {
	if (!diff) return null;

	return (
		<Modal visible onClose={onClose} size={s => s.m} title="Please review the pending changes">
			<ul className="overflow-y-scroll flex flex-col gap-6 grow">
				{Object.entries(diff).map(([key, changedFieldDiff]) => {
					const variable = variables.find(variable => variable.variableName === key);

					let fieldType: FieldType = 'text';
					if (!variable) {
						console.error(`Variable for ${key} not found`);
					} else {
						fieldType = variableToFieldType(variable);
					}

					return (
						<li key={key} className="flex flex-col">
							<p className="text-base">{variable?.variableLabel || key}</p>

							<Diff
								changedFieldDiff={changedFieldDiff}
								fieldType={fieldType}
								variable={
									variable || {
										// This is not supposed to happen, providing a type safe solution so we dont have to make variable optional all the way through
										variableName: key,
										variableType: 'string',
										description: '',
										obligatory: false,
										variableLabel: key
									}
								}
							/>
						</li>
					);
				})}

				<div className="flex justify-between mt-auto">
					{onDelete && (
						<Button title="Discard and close" variant="danger" onClick={onDelete} />
					)}

					<div className="flex gap-2">
						<Button title="Continue editing" onClick={onClose} variant="secondary" />
						{onConfirm && (
							<Button title="Save changes" onClick={onConfirm} loading={submitting} />
						)}
					</div>
				</div>
			</ul>
		</Modal>
	);
};

export const Diff = ({
	variable,
	changedFieldDiff,
	fieldType
}: {
	fieldType: FieldType;
	changedFieldDiff: DiffedField;
	variable: Variable;
}) => {
	return (
		<div className="flex gap-6 mt-2 items-start">
			<div className="bg-error-500/10 rounded-lg p-4 text-error-700 line-through opacity-50">
				<FromValue
					changedFieldDiff={changedFieldDiff}
					fieldType={fieldType}
					variable={variable}
				/>
			</div>

			<ToValue to={changedFieldDiff.to} fieldType={fieldType} variable={variable} />
		</div>
	);
};

export type FieldType =
	| 'date'
	| 'datetime'
	| 'frontend-file'
	| 'backend-file'
	| 'category'
	| 'text';
export const variableToFieldType = (variable: Variable): FieldType => {
	const isDate = variable?.variableType === 'date';
	if (isDate) {
		return 'date';
	}

	const isDateTime = variable?.variableType === 'datetime';
	if (isDateTime) {
		return 'datetime';
	}

	const isFile = variable?.variableType === 'file';
	if (isFile) {
		return 'backend-file';
	}

	if (variable && isCategoryVariable(variable)) {
		return 'category';
	}

	return 'text';
};

const FromValue = ({
	changedFieldDiff,
	fieldType,
	variable
}: {
	changedFieldDiff: DiffedField;
	fieldType?: FieldType;
	variable: Variable;
}) => {
	const NoValue = <p className="text-sm font-semibold italic">No value</p>;

	if (changedFieldDiff.from instanceof Array) {
		if (changedFieldDiff.from.length > 0) {
			return (
				<ul>
					{Array.from(changedFieldDiff.from).map((value, index) => (
						<li key={index} className="font-semibold text-sm">
							{value}
						</li>
					))}
				</ul>
			);
		}
		return NoValue;
	}

	if (isBackendFormFile(changedFieldDiff.from)) {
		return (
			<BackendFileFromDiff
				variableName={variable.variableName}
				fileId={changedFieldDiff.from.fileId}
			/>
		);
	}

	if (changedFieldDiff.from) {
		return (
			<TextFromDiff
				changedFieldDiff={changedFieldDiff}
				variable={variable}
				fieldType={fieldType}
			/>
		);
	}

	return NoValue;
};

export const ToValue = ({
	to,
	fieldType,
	variable
}: {
	to: DiffedField['to'];
	fieldType?: FieldType;
	variable: Variable;
}) => {
	return (
		<div className="bg-success-500/10 rounded-lg p-4 text-success-700">
			<ToValueContent to={to} fieldType={fieldType} variable={variable} />
		</div>
	);
};

const ToValueContent = ({
	to,
	fieldType,
	variable
}: {
	to: DiffedField['to'];
	fieldType?: FieldType;
	variable: Variable;
}) => {
	if (!to) {
		return <p className="font-semibold text-sm italic">Deleted</p>;
	}

	if (to instanceof Array) {
		if (to.length === 0) {
			return <p className="text-sm font-semibold italic">No options selected</p>;
		} else {
			return (
				<ul>
					{Array.from(to).map((value, index) => (
						<li key={index} className="font-semibold text-sm">
							{value}
						</li>
					))}
				</ul>
			);
		}
	}

	if (isBackendFormFile(to)) {
		return <BackendFileFromDiff fileId={to.fileId} variableName={variable.variableName} />;
	}

	if (isFrontendFormFile(to)) {
		return <p className="font-semibold text-sm">{to.fileName}</p>;
	}

	return (
		<p className="font-semibold text-sm">
			{formatFieldValue({
				fieldValue: to,
				fieldType,
				variable
			})}
		</p>
	);
};

const formatFieldValue = ({
	fieldValue,
	fieldType,
	variable
}: {
	fieldValue: EntryValue;
	fieldType?: FieldType;
	variable?: Variable;
}) => {
	if (fieldType === 'frontend-file') {
		return (fieldValue as FrontendFormFile).fileName;
	}

	if (fieldType === 'date') {
		return DateTime.fromISO(fieldValue as string).toLocaleString(DateTime.DATE_MED);
	}

	if (fieldType === 'datetime') {
		return DateTime.fromISO(fieldValue as string).toLocaleString(DateTime.DATETIME_MED);
	}

	if (fieldType === 'category') {
		if (!variable) {
			console.error('Variable is required for category fields');
			return fieldValue;
		}

		if (!isCategoryVariable(variable)) {
			console.error(`Variable type ${variable.variableType} is not supported`);
			return fieldValue;
		}

		if (isCategoryFixedVariable(variable) || isCategoryMultipleFixedVariable(variable)) {
			const category = variable.allowedCategories.find(
				category => category.value === fieldValue
			);
			if (!category) {
				console.warn(new Error('Category not found in variable'), {
					fieldValue,
					variableName: variable.variableName
				});
				return fieldValue;
			}
			return category.label;
		}

		// e.g. when a category is added front end we don't have the label, so we use the added value
		return fieldValue;
	}

	return fieldValue;
};

const TextFromDiff = ({
	fieldType,
	changedFieldDiff,
	variable
}: {
	fieldType?: FieldType;
	changedFieldDiff: DiffedField;
	variable?: Variable;
}) => {
	return (
		<p className="font-semibold text-sm">
			{formatFieldValue({
				fieldValue: changedFieldDiff.from,
				fieldType,
				variable
			})}
		</p>
	);
};

const BackendFileFromDiff = ({
	fileId,
	variableName
}: {
	fileId: string;
	variableName: string;
}) => {
	const params = useParams();
	const projectId = params.projectId as string;
	const entryId = params.entryId as string;

	const fileInfoQuery = useFileInfoQuery({
		entryId,
		projectId,
		fileId
	});

	if (fileInfoQuery.isLoading) {
		return <Loader />;
	}

	let filename: string | undefined;
	if (fileInfoQuery.data?.doc.metadata.fileName) {
		filename = fileInfoQuery.data.doc.metadata.fileName;
	}

	return (
		<p
			data-testid={`diffed-backend-file-field_${variableName}`}
			className="font-semibold text-sm"
		>
			{filename || 'File not found'}
		</p>
	);
};

const isCategoryVariable = (
	variable: Variable
): variable is CategoryVariable | CategoryMultipleVariable => {
	return ['category', 'categoryMultiple'].includes(variable.variableType);
};
