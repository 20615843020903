import { Loader } from 'components/UI/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { EntryForm } from './EntryForm';
import { StrictMode, useEffect, useState } from 'react';
import { useInsertDataEntryMutation } from './data/useInsertDataEntryMutation';
import { useGetProjectOrganizationCollaboratorsQuery } from './data/useGetProjectOrganizationCollaboratorsQuery';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { User } from 'api/auth';
import { Amplify } from 'aws-amplify';
import { Icon } from 'components/UI/Icons';
import { Svgs } from 'environment';
import { Button } from './component/Button';
import { useProjectData } from './data/useProjectData';
import { FileForUpload } from './data/useStoreBase64FileMutation';
import { ROUTE_MAP } from './utils/routeMap';
import { ErrorModal } from './ErrorModal';
import { Entry } from './types';
import { FormFile, isFrontendFormFile } from './utils/zodUtils';
import { Modal } from 'components/UI/Modal';
import { useEntries } from 'hooks/store';
import { useTracking } from 'app/tracking/TrackingProvider';

export const CreateEntryPageV1_5 = () => {
	const navigate = useNavigate();
	const params = useParams();
	const projectId = params.projectId as string;

	const { track } = useTracking();

	const [_, fetchEntries] = useEntries({ lazy: true });

	const [currentUserId, setCurrentUserId] = useState<string>();
	useEffect(() => {
		async function asyncShit() {
			const user: User = await Amplify.Auth.currentAuthenticatedUser();

			setCurrentUserId(user.username);
		}

		asyncShit();
	}, []);

	const [selectedGroupId, setSelectedGroupId] = useState<string>();

	const projectDataQuery = useProjectData({
		projectId
	});

	const insertDataEntryMutation = useInsertDataEntryMutation();

	const loading = projectDataQuery.isLoading;
	const error = projectDataQuery.error;

	if (loading) {
		return (
			<div className="w-[100vw] h-[100vh]">
				<Loader center />
			</div>
		);
	}

	if (error || !projectDataQuery.data) {
		return (
			<div className="v-[100vw] h-[100vh] flex items-center justify-center flex-col">
				<Icon svg={Svgs.LedidiLogo} customSize={5} />

				<p className="text-base font-bold mt-20">
					Error loading data, please try again. Contact support if the problem persists.
				</p>

				<div className="flex flex-col gap-4 mt-10">
					<Button
						title="Reload"
						variant="primary"
						onClick={() => {
							projectDataQuery.refetch();
						}}
					/>

					<button
						className="text-sm underline"
						onClick={() =>
							navigate(ROUTE_MAP.project.byId.dataset.createPath({ projectId }))
						}
					>
						Back to project
					</button>
				</div>
			</div>
		);
	}

	const navigateToProjectDataset = () => {
		navigate(ROUTE_MAP.project.byId.dataset.createPath({ projectId }));
	};

	return (
		<StrictMode>
			<div className="grid grid-cols-12 mt-40">
				<ErrorModal
					onClose={insertDataEntryMutation.reset}
					error={insertDataEntryMutation.error}
				/>

				{!selectedGroupId && (
					<SelectGroup
						onGroupSelected={setSelectedGroupId}
						projectId={projectId}
						currentUserId={currentUserId}
					/>
				)}

				<div className="col-span-full">
					<EntryForm
						Header={
							<div className="bg-gray-300 p-4 flex items-center justify-between fixed top-0 right-0 left-0 z-50">
								<div className="flex items-center">
									<Icon
										svg={Svgs.LedidiLogoGrey}
										customSize={3}
										onClick={navigateToProjectDataset}
										id="main_header_logo"
									/>

									<p className="ml-10 text-2xl font-semibold">Create entry</p>
								</div>

								<div className="flex gap-4">
									<Button
										loading={insertDataEntryMutation.loading}
										className="col-start-4 col-span-2"
										data-testid="entry-form-header_save-button"
										title="Save"
										variant="primary"
									/>

									<Icon
										svg={Svgs.Close}
										marginOffset={{ left: 2.4 }}
										variant={v => v.buttonActive}
										dataTestId="entry-form-header_close-icon"
										onClick={navigateToProjectDataset}
									/>
								</div>
							</div>
						}
						projectData={projectDataQuery.data}
						onSubmit={async entry => {
							try {
								if (!projectDataQuery.data) {
									console.error(
										"onSubmit called but projectDataQuery.data doesn't exist, this is a no-op"
									);
									return;
								}

								const insertDataEntryResult = await insertDataEntryMutation.mutate({
									projectId,
									entry,
									organizationGroupId: selectedGroupId
								});

								fetchEntries();

								track({
									eventName: 'entry_created',
									data: {
										entryHasStatus: false
									}
								});

								navigate(
									ROUTE_MAP.project.byId.dataset.update.createPath({
										projectId,
										entryId: insertDataEntryResult.insertedEntry.datasetentryid
									}),
									{ replace: true }
								);
							} catch (e) {
								console.error(e);
							}
						}}
					/>
				</div>
			</div>
		</StrictMode>
	);
};

export const extractFilesFromEntry = ({ entry: originalEntry }: { entry: Entry }) => {
	const entry = { ...originalEntry };

	const files: FileForUpload[] = [];
	Object.entries(entry).forEach(([variableName, value]) => {
		if (isFrontendFormFile(value)) {
			const file: FormFile = value;

			if (file === null) {
				// file should be sent as null to backend for deletion, leave it in
				return;
			}

			if (file?.type !== 'frontend-file') {
				console.error('File is not a frontend file, this is a no-op');
				return;
			}

			files.push({
				base64Bytes: file.encodedFile,
				fileName: file.fileName,
				variableName: variableName
			});

			// Backend will probably fail validation if we dont supply something here
			entry[variableName] = 'this does not matter, just not empty';
		}
	});

	return {
		files,
		entryWithoutFiles: entry
	};
};

const NO_GROUP_ID = '-1';

/**
 * UX On this isn't great but we ensure that the user is required to select a group with this approach.
 */
const SelectGroup = ({
	onGroupSelected,
	projectId,
	currentUserId
}: {
	onGroupSelected: (groupId?: string) => void;
	projectId: string;
	currentUserId?: string;
}) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>();

	const projectOrganizationCollaboratorsQuery = useGetProjectOrganizationCollaboratorsQuery({
		projectId
	});
	const groupsWhereCurrentUserIsMember =
		projectOrganizationCollaboratorsQuery.data?.project.organizations.filter(org =>
			org.organizationCollaborators.find(c => c.userId === currentUserId)
		);

	useEffect(() => {
		// As currentUserId can be undefined, executing the code below before it is set will cause issues
		// ensure that the query is not in cached state
		if (!currentUserId || !projectOrganizationCollaboratorsQuery.isFetchedAfterMount) {
			return;
		}

		if (groupsWhereCurrentUserIsMember?.length === 0) {
			return onGroupSelected(NO_GROUP_ID);
		}

		if (groupsWhereCurrentUserIsMember?.length === 1) {
			// No need to show the modal if there is only one group
			return onGroupSelected(groupsWhereCurrentUserIsMember[0].organizationId);
		}

		setShowModal(true);
	}, [groupsWhereCurrentUserIsMember, currentUserId]);

	const selectOptions =
		groupsWhereCurrentUserIsMember?.map(group => ({
			label: group.organizationName,
			value: group.organizationId
		})) || [];

	if (selectOptions.length < 1) {
		return null;
	}

	return (
		<Modal
			size={s => s.s}
			onClose={() => {
				// Not allowed to cancel this
				return;
			}}
			visible={showModal}
			title="Group selection"
			primary={{
				onClick: () => {
					onGroupSelected(selectedGroupId);
				},
				label: 'Confirm'
			}}
		>
			<p className="text-sm">
				As a collaborator with multiple group memberships, select your current
				representative group to proceed. Your choice will determine entry ownership and
				impact operation execution.
			</p>

			<CreatableSelect
				className="mt-4"
				scrollIntoView={false}
				label="Select a group"
				items={selectOptions}
				onValueSelected={value => {
					setSelectedGroupId(value || undefined);
				}}
				value={selectOptions.find(item => item.value === selectedGroupId) || null}
			/>
		</Modal>
	);
};
