import { Modal } from 'components/UI/Modal';
import { LedidiRequestError } from './data/makeRequest';

export const ErrorModal = ({ onClose, error }: { onClose: () => void; error?: Error }) => {
	if (!error) {
		return null;
	}

	const errors = parseErrors(error);

	return (
		<Modal
			visible
			onClose={onClose}
			title="Error submitting entry"
			size={s => s.s}
			primary={{
				label: 'Close',
				onClick: onClose
			}}
		>
			<div className="flex flex-col">
				<p className="text-sm">Entry submission failed with the following error(s)</p>

				<ul className="mt-4">
					{errors.map((error, index) => {
						return (
							<li key={index} className="text-error-500 font-semibold text-sm">
								{error}
							</li>
						);
					})}
				</ul>
			</div>
		</Modal>
	);
};

const parseErrors = (error: Error) => {
	if (!(error instanceof LedidiRequestError)) {
		return [
			'An unexpected error occurred, please try again. Contact support if the problem persists.'
		];
	}

	return error.errors?.map(error => {
		if (error.code === 'error.invalidRequest') {
			return 'Some fields are not matching the schema';
		}

		if (error.code === 'error.uniqueFieldValue') {
			return 'Some unique field value is already taken';
		}

		if (error.code === 'error.conflict') {
			return 'This entry has been updated or deleted by another user, please refresh the page and try again.';
		}

		if (error.code === 'error.licence') {
			return 'This project has reached the maximum amount of entries available on our free plan (35). In order to add more entries, please upgrade your licence.';
		}

		if (error.code === 'error.licence.other') {
			return 'This project has reached the maximum amount of entries available on our free plan (35). In order to add more entries, contact the project owner and have them upgrade their licence.';
		}

		return 'An unexpected error occurred, please try again. Contact support if the problem persists.';
	});
};
