import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { RadioGroup } from '../component/RadioGroup';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';
import { CategoryFixedFormItem } from '../data/useProjectData';
import { useFilteringDependencies } from './useFilteringDependencies';
import { backendValueToFormValue } from '../utils/parse-backend-values/backendValueToFormValue';
import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';

interface Props {
	control: FormControl;
	item: CategoryFixedFormItem;
}

export const CategoryFixedVariableInput = ({ item, control }: Props) => {
	const variable = item.variable;
	const showDropdown = variable.optimizeForManyValues || variable.allowedCategories.length > 100;

	const {
		field: { value, onChange },
		fieldState
	} = useController({
		name: variable.variableName,
		control
	});

	const filteredCategories = useFilteringDependencies({
		variable,
		dependencies: item.dependencies,
		control
	});

	const options = useMemo(
		() =>
			variable.allowedCategories
				.filter(category => filteredCategories.has(category.value))
				.map(category => ({
					label: category.label,
					value: category.value
				})),
		[variable.allowedCategories, filteredCategories]
	);

	useEffect(() => {
		if (variable.entryType === 'calculated') {
			return;
		}
		const selectedValue = value;
		const allowedValues = new Set(options.map(option => option.value));
		const currentValueLegal = allowedValues.has(selectedValue);
		const emptyValue = backendValueToFormValue({ value: null, variable });
		if (!currentValueLegal && !isEqual(value, emptyValue)) {
			onChange(emptyValue);
		}
	}, [options, value]);

	if (showDropdown) {
		return (
			<CreatableSelect
				className="col-span-full md:col-span-1"
				label={variable.variableLabel}
				items={options}
				required={variable.obligatory}
				value={
					value
						? {
								label: value,
								value
						  }
						: null
				}
				onValueSelected={value => onChange(value ? value : '')}
				allowCreate={false}
				error={fieldState.error?.message?.toString()}
				disabled={variable.entryType === 'calculated'}
				LabelSuffix={<OptionalDescriptionTooltip description={variable.description} />}
			/>
		);
	}

	return (
		<RadioGroup
			className="col-span-full"
			categories={options}
			description={variable.description}
			variable={variable}
			allowCreate={false}
			control={control}
			disabled={variable.entryType === 'calculated'}
		/>
	);
};
