import { isEmpty, isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { VariableType } from 'types/data/variables/constants';
import {
	Columns,
	CorrelationsAnalysis,
	CorrelationsStatisticsV2,
	CorrelationsVariables,
	DataModel
} from 'api/data/analyses';
import { ANALYSIS_DEBOUNCE_TIME } from 'consts';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ConfigContainer } from '../UI';
import { CollapsibleCard } from 'components/UI/Interactables/CollapsibleCard';
import { Gap } from 'components/UI/Gap';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { Switch } from 'components/UI/Interactables/Switch';

import {
	useTranslation,
	useUpdateAnalysis,
	useRefetchAnalyses,
	useFullscreenAnalysis,
	useAnalysisConfigPanel,
	useAnalysesActiveColum,
	useFilters,
	useVariables,
	useVariablesDataSelectItems
} from 'hooks/store';
import { useDebounce, usePrevious } from 'hooks/utils';
import { AnalysisOptionsHeader } from '../AnalysisOptions/AnalysisOptionsHeader';
import { AnalysisFormatting } from '../AnalysisOptions/AnalysisFormatting/AnalysisFormatting';
import { Nullable, SelectItem } from 'types';
import {
	buildSeriesLevelVariableData,
	buildVariablesDataLocation,
	mergeSelectItems
} from 'helpers/variables';
import { useGroupingVariablesSelectItems } from 'hooks/store/data/analysis/useGroupingVariablesSelectItems';

interface Props {
	analysis: CorrelationsAnalysis;
	variablesDataSelectItems: VariablesDataSelectItems;
	loading: boolean;
}

export function CorrelationsConfig({ analysis, variablesDataSelectItems, loading }: Props) {
	const { translate } = useTranslation();

	const updateAnalysis = useUpdateAnalysis();
	const [shouldRefetchAnalyses] = useRefetchAnalyses();
	const [fullscreen] = useFullscreenAnalysis();
	const [{ areFiltersOpen }] = useFilters();
	const {
		options: { configPanel },
		input: { variables, statistics, dataModel, series }
	} = analysis;

	const [values, setValues] = useState(variables);
	const [stats, setStats] = useState(statistics);

	const [{ isParamsOpen }, { openParameters }] = useAnalysisConfigPanel(analysis.id);

	const [activeColumn] = useAnalysesActiveColum();

	const { selectItemsMap, selectItems: mainSelectItems } = variablesDataSelectItems;

	const [{ data: variablesData }] = useVariables();

	const { variableSets } = variablesData;

	const { variablesLocation } = useMemo(
		() => buildVariablesDataLocation(variablesData),
		[variablesData]
	);

	// dynamically created variables data which includes exclusively [selected] series data!
	const setVariablesData = buildSeriesLevelVariableData(variablesData, series);
	const seriesVariableSelectItems = useVariablesDataSelectItems(setVariablesData, {
		series,
		omitVariables: []
	});

	const { selectItemsMap: seriesSelectItemsMap, selectItems: seriesSelectItems } =
		seriesVariableSelectItems;

	const numericSelectItems = useMemo(() => {
		if (series) {
			return mergeSelectItems(seriesSelectItems, variablesData, [
				VariableType.Float,
				VariableType.Integer,
				VariableType.TimeDuration
			]);
		}

		return mergeSelectItems(mainSelectItems, variablesData, [
			VariableType.Float,
			VariableType.Integer,
			VariableType.TimeDuration
		]);
	}, [mainSelectItems, seriesSelectItems, series]);

	const groupingSelectItems = useGroupingVariablesSelectItems({
		variablesData,
		series
	});

	const dataModelSelectItems: SelectItem[] = [
		{
			label: translate(dict => dict.analysis.analyses.correlationsV2.config.mainLevel),
			value: DataModel.main
		},
		{
			label: translate(dict => dict.analysis.analyses.correlationsV2.config.singleSeries),
			value: DataModel.series
		}
	];

	const variableSetSelectItems: SelectItem[] = variableSets.map(set => ({
		label: set.setLabel,
		value: set.setName
	}));

	useEffect(() => {
		if (!isEqual(variables, values)) {
			setValues(variables);
		}
	}, [variables]);

	useEffect(() => {
		if (!isEqual(statistics, stats)) {
			setStats(statistics);
		}
	}, [statistics]);

	useDebounce(
		() => {
			if (haveVariablesChanged(variables, values)) {
				const updatedAnalysis: CorrelationsAnalysis = {
					...analysis,
					input: {
						...analysis.input,
						variables: {
							...analysis.input.variables,
							...values
						},
						statistics: {
							pearsonV2: false,
							spearmanV2: false,
							linearRegressionV2: false
						}
					}
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[values],
		ANALYSIS_DEBOUNCE_TIME
	);

	useDebounce(
		() => {
			if (haveStatisticsChanged(statistics, stats)) {
				const updatedAnalysis: CorrelationsAnalysis = {
					...analysis,
					input: { ...analysis.input, statistics: stats }
				};

				updateAnalysis({ analysis: updatedAnalysis });
			}
		},
		[stats],
		ANALYSIS_DEBOUNCE_TIME
	);

	const prevShouldRefetchAnalyses = usePrevious(shouldRefetchAnalyses);
	useEffect(() => {
		if (
			prevShouldRefetchAnalyses !== undefined &&
			prevShouldRefetchAnalyses !== shouldRefetchAnalyses
		) {
			setStats({
				pearsonV2: false,
				spearmanV2: false,
				linearRegressionV2: false
			});
		}
	}, [shouldRefetchAnalyses]);

	function onSelectDataModel(dataModel: Nullable<string>) {
		const newAnalysis: CorrelationsAnalysis = {
			...analysis,
			input: {
				...analysis.input,
				...(dataModel === DataModel.main ? { series: undefined } : {}),
				dataModel: dataModel as DataModel | null,
				variables: {
					groupVariables: [],
					xVariable: null,
					yVariable: null
				},
				statistics: {
					linearRegressionV2: false,
					pearsonV2: false,
					spearmanV2: false
				}
			}
		};

		updateAnalysis({
			analysis: newAnalysis
		});
	}

	function onSelectSeries(series: Nullable<string>) {
		if (!series) return;

		const newAnalysis: CorrelationsAnalysis = {
			...analysis,
			input: { ...analysis.input, series }
		};

		updateAnalysis({
			analysis: {
				...newAnalysis,
				input: {
					...newAnalysis.input,
					variables: { groupVariables: [], xVariable: null, yVariable: null }
				}
			} as CorrelationsAnalysis
		});

		setValues({
			groupVariables: [],
			xVariable: null,
			yVariable: null
		});
	}

	function onSelectVariable(key: keyof CorrelationsVariables, variableName: Nullable<string>) {
		if (!variableName) {
			setValues(values => ({
				...values,
				[key]: null
			}));
			return;
		}
		const setName = variablesLocation[variableName]?.setName;
		setValues(values => ({
			...values,
			[key]: {
				name: variableName,
				...(setName ? { series: setName } : {})
			}
		}));
	}

	function onSelectGroupVariables(groupVariable: Nullable<string>) {
		// Multi-group choice support (phase 2)
		// const variables = groupVariables.reduce((acc, key) => {
		// 	const setName = variablesLocation[key]?.setName;

		// 	return [
		// 		...acc,
		// 		...[
		// 			{
		// 				name: key,
		// 				...(setName ? { series: setName } : {})
		// 			}
		// 		]
		// 	];
		// }, [] as AnalysisVariable[]);

		if (!groupVariable) {
			return setValues({ ...values, groupVariables: [] });
		}

		const setName = variablesLocation[groupVariable]?.setName;

		setValues({
			...values,
			groupVariables: [
				{
					name: groupVariable,
					...(setName ? { series: setName } : {})
				}
			]
		});
	}

	const allSelectItemsMap = { ...selectItemsMap, ...seriesSelectItemsMap };

	return (
		<ConfigContainer
			data-testid={`${analysis.type}-config-container`}
			disabled={loading}
			isFullScreen={fullscreen}
			areFiltersOpen={areFiltersOpen}
		>
			{activeColumn === Columns.OneColumn && configPanel.open && (
				<AnalysisOptionsHeader analysis={analysis as CorrelationsAnalysis} />
			)}

			{/* PARAMETERS */}
			<CollapsibleCard
				marginOffset={{ bottom: 1.6 }}
				title={translate(
					({ analysis }) => analysis.analyses.groupedOptions.title.Parameters
				)}
				open={isParamsOpen}
				onToggle={() =>
					openParameters({ analysisId: analysis.id, parameters: !isParamsOpen })
				}
			>
				<Gap marginGap={{ bottom: 1.6 }} style={{ width: '100%' }} notLastChild>
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.dataModel
						)}
						items={dataModelSelectItems}
						value={dataModelSelectItems.find(item => item.value === dataModel)}
						onValueSelected={onSelectDataModel}
						canClear={false}
					/>
					{dataModel === DataModel.series && (
						<CreatableSelect
							label={translate(
								({ analysis }) => analysis.analyses.correlationsV2.config.series
							)}
							items={variableSetSelectItems}
							value={variableSetSelectItems.find(item => item.value === series)}
							onValueSelected={onSelectSeries}
							canClear={false}
						/>
					)}
					{/* VARIABLE INPUTS */}
					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.x
						)}
						items={numericSelectItems}
						value={allSelectItemsMap[values.xVariable?.name ?? '']}
						disabled={!dataModel || (dataModel === DataModel.series && !series)}
						isItemDisabled={item =>
							item.value === values.yVariable?.name ||
							item.value === values.groupVariables[0]?.name
						}
						onValueSelected={value => onSelectVariable('xVariable', value)}
						canClear={true}
					/>

					<CreatableSelect
						label={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.y
						)}
						items={numericSelectItems}
						value={allSelectItemsMap[values.yVariable?.name ?? '']}
						onValueSelected={value => onSelectVariable('yVariable', value)}
						isItemDisabled={item =>
							item.value === values.xVariable?.name ||
							item.value === values.groupVariables[0]?.name
						}
						canClear={true}
						disabled={!dataModel || (dataModel === DataModel.series && !series)}
					/>
					<CreatableSelect
						canClear
						label={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.grouped
						)}
						placeholder={translate(
							({ analysis }) => analysis.analyses.correlationsV2.config.ungrouped
						)}
						isItemDisabled={item =>
							!item.options &&
							(item?.value === values.xVariable?.name ||
								item?.value === values.yVariable?.name)
						}
						items={groupingSelectItems}
						disabled={
							!dataModel ||
							(dataModel === DataModel.series && !series) ||
							isEmpty([...groupingSelectItems]) ||
							(dataModel === DataModel.main &&
								!variables.xVariable &&
								!variables.yVariable)
						}
						value={allSelectItemsMap[values.groupVariables?.[0]?.name]}
						onValueSelected={onSelectGroupVariables}
					/>

					{/* STATISTICS */}
					<Switch
						disabled={!(variables.xVariable && variables.yVariable)}
						label={translate(dict => dict.analysis.statistics.pearson.name)}
						on={stats.pearsonV2}
						onChange={() =>
							setStats(state => ({ ...state, pearsonV2: !state.pearsonV2 }))
						}
					/>
					<Switch
						disabled={!(variables.xVariable && variables.yVariable)}
						label={translate(dict => dict.analysis.statistics.spearman.name)}
						on={stats.spearmanV2}
						onChange={() =>
							setStats(state => ({ ...state, spearmanV2: !state.spearmanV2 }))
						}
					/>
					<Switch
						disabled={!(variables.xVariable && variables.yVariable)}
						label={translate(dict => dict.analysis.statistics.linearRegression.name)}
						on={stats.linearRegressionV2}
						onChange={() =>
							setStats(state => ({
								...state,
								linearRegressionV2: !state.linearRegressionV2
							}))
						}
					/>
				</Gap>
			</CollapsibleCard>

			{/* FORMATTING */}
			<AnalysisFormatting
				analysis={analysis}
				isEnabled={variables.groupVariables.length > 0}
			/>
		</ConfigContainer>
	);
}

const haveVariablesChanged = (own: CorrelationsVariables, values: CorrelationsVariables) =>
	own.xVariable?.name !== values.xVariable?.name ||
	own.yVariable?.name !== values.yVariable?.name ||
	!isEqual(own.groupVariables, values.groupVariables);

const haveStatisticsChanged = (own: CorrelationsStatisticsV2, values: CorrelationsStatisticsV2) =>
	own.pearsonV2 !== values.pearsonV2 ||
	own.spearmanV2 !== values.spearmanV2 ||
	own.linearRegressionV2 !== values.linearRegressionV2;
