import { useController } from 'react-hook-form';
import { FormControl } from '../EntryForm';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { OptionalDescriptionTooltip } from '../component/OptionalDescriptionTooltip';
import { CheckboxGroup } from '../component/CheckboxGroup';
import { CategoryMultipleNonFixedFormItem } from '../data/useProjectData';

interface Props {
	control: FormControl;
	item: CategoryMultipleNonFixedFormItem;
}

export const CategoryMultipleVariableInput = ({ item, control }: Props) => {
	const variable = item.variable;

	const { field, fieldState } = useController({
		name: variable.variableName,
		control
	});

	const options = item.variable.categories.map(category => ({
		label: category,
		value: category
	}));

	if (variable.optimizeForManyValues) {
		return (
			<CreatableSelect
				className="col-span-full md:col-span-1"
				label={variable.variableLabel}
				placeholder={variable.obligatory ? 'Select at least one' : 'Select'}
				values={field.value ? field.value.map((v: any) => ({ label: v, value: v })) : []}
				items={options}
				error={fieldState.error?.message?.toString()}
				onBlur={field.onBlur}
				LabelSuffix={<OptionalDescriptionTooltip description={variable.description} />}
				onValuesSelected={values => field.onChange(values)}
				required={variable.obligatory}
				hasMultipleValues
				allowCreate={true}
			/>
		);
	}

	return (
		<CheckboxGroup
			className="col-span-full"
			categories={options}
			variable={variable}
			allowCreate={true}
			control={control}
		/>
	);
};
