import {
	UploadProjectDatasetInput,
	UpdateExistingDatasetInput,
	UploadAsyncXlsInput
} from 'api/data/projects';
import { useActivities } from 'hooks/store';
import { Operation } from 'hooks/store/types';
import { useDispatch } from 'hooks/utils';
import {
	ActionTypes,
	uploadReplaceAllApiAction,
	uploadMoreDataToEntriesApiAction,
	uploadMoreEntriesToDatasetApiAction
} from 'store/data/projects';
import { ImportType } from 'types/index';
import type {
	ApiImportErrorsByVariableName,
	ApiVariableErrorCount
} from 'types/data/projects/types';

export function useImportDataset(): Operation<
	(
		input: UploadProjectDatasetInput | UpdateExistingDatasetInput | UploadAsyncXlsInput,
		importType: ImportType,
		callbacks?: {
			onAPIError: (
				apiErrorMap: ApiImportErrorsByVariableName,
				errorCountMap: ApiVariableErrorCount
			) => void;
		}
	) => void
> {
	const dispatch = useDispatch();

	const [{ loading, error }] = useActivities([
		ActionTypes.UPLOAD_PROJECT_DATASET,
		ActionTypes.UPDATE_EXISTING_DATASET,
		ActionTypes.UPLOAD_TO_EXISTING_DATASET
	]);

	function handler(
		input: UploadProjectDatasetInput | UpdateExistingDatasetInput | UploadAsyncXlsInput,
		importType: ImportType,
		callbacks?: {
			onAPIError: (
				apiErrorMap: ApiImportErrorsByVariableName,
				errorCountMap: ApiVariableErrorCount
			) => void;
		}
	) {
		if (importType === ImportType.MoreDataToExistingEntries) {
			dispatch(
				uploadMoreDataToEntriesApiAction(input as UpdateExistingDatasetInput, callbacks)
			);
		}
		if (importType === ImportType.MoreEntriesToDataset) {
			dispatch(
				uploadMoreEntriesToDatasetApiAction(input as UploadProjectDatasetInput, callbacks)
			);
		}
		if (importType === ImportType.ReplaceAll) {
			dispatch(uploadReplaceAllApiAction(input as UploadProjectDatasetInput, callbacks));
		}
	}

	return [{ loading, error }, handler];
}
