import { FormControl } from '../EntryForm';
import {
	CategoryFixedVariable,
	CategoryMultipleFixedVariable,
	DependencyRule,
	isFilteringDependencyRule
} from '../types';
import { isRuleSatisfied } from '../utils/dependencyRules';
import { useWatch } from 'react-hook-form';
import { useMemo } from 'react';

export const useFilteringDependencies = ({
	control,
	variable,
	dependencies
}: {
	control: FormControl;
	variable: CategoryFixedVariable | CategoryMultipleFixedVariable;
	dependencies: DependencyRule[];
}) => {
	const filteringDependencies = dependencies.filter(isFilteringDependencyRule);
	const supplierValues = useWatch({
		control,
		name: filteringDependencies.map(rule => rule.supplierVariable.variableName)
	});

	const allowedCategories = useMemo(() => {
		const satisfiedDependencies = filteringDependencies.filter((rule, index) =>
			isRuleSatisfied({
				rule,
				supplierValue: supplierValues[index],
				invalidRuleReturnValue: true
			})
		);
		if (satisfiedDependencies.length > 0) {
			return new Set(satisfiedDependencies.flatMap(rule => rule.filteredValues));
		}
		return new Set(variable.allowedCategories.map(category => category.value));
	}, [dependencies, supplierValues, variable.allowedCategories]);

	return allowedCategories;
};
