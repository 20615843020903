import { useState } from 'react';
import { makeRequest } from './makeRequest';
import { Entry } from '../types';
import { extractFilesFromEntry } from '../CreateEntryPageV1_5';
import { submitFiles } from './submitFiles';

export type UpdateDataEntryResult = {
	httpStatusCode: number;
	updatedEntry: Entry;
	newDatasetentryid: string;
};

export const useUpdateDataEntryMutation = () => {
	const [data, setData] = useState<{ updatedEntry: Entry }>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error>();

	async function mutate({
		projectId,
		entryId,
		entry
	}: {
		projectId: string;
		entryId: string;
		entry: Entry;
	}) {
		setLoading(true);

		try {
			const { entryWithoutFiles, files } = extractFilesFromEntry({
				entry
			});

			const result: UpdateDataEntryResult = await makeRequest({
				method: 'updateDataEntry',
				service: 'data-entry',
				data: {
					projectId,
					datasetentryid: entryId,
					observationData: entryWithoutFiles
				}
			});

			if (files.length > 0) {
				await submitFiles({
					entryId: result.newDatasetentryid,
					files,
					projectId
				});
			}

			setData(result); // this is missing the updated values for the files
			return result;
		} catch (e) {
			setError(e as Error);

			throw e;
		} finally {
			setLoading(false);
		}
	}

	return {
		data,
		loading,
		error,
		mutate,
		reset: () => {
			setData(undefined);
			setError(undefined);
			setLoading(false);
		}
	};
};
