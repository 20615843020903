import { createActivity } from 'store/ui/activities';
import { Thunk, ActionPayload } from 'store/types';

import {
	ActionTypes,
	ClearSnapshotAction,
	CreateSnapshotAction,
	DeleteSnapshotAction,
	GetSnapshotAction,
	GetSnapshotsAction,
	SetActiveSnapshotAction,
	UpdateSnapshotAction
} from './types';
import { deleteAnalysesAction } from '../analyses';
import { deleteJADBioAnalyses, extractJADBioAnalysesIds } from 'store/addons/jadbio';
import {
	ComparePairedAnalysis,
	ComparePairedDataModels,
	CorrelationsAnalysis,
	CrosstabAnalysis,
	CrosstabAnalysisV2,
	LogisticRegressionAnalysis,
	PlotNumericAnalysis,
	CompareNumericAnalysisV2,
	CompareNumericAnalysisV1,
	KaplanMeierAnalysis,
	DensityPlotAnalysis,
	ExploreAnalysis,
	FrequenciesAnalysis,
	KaplanMeierDataModels,
	ExploreAnalysisV2,
	AnalysisVariable,
	CorrelationsV1Analysis,
	DensityPlotAnalysisV2,
	TimeCourseAnalysisV2,
	TimeCourseAnalysisV1,
	PlotNumericAnalysisV2,
	LogisticRegressionAnalysisV2,
	FrequenciesAnalysisV2,
	KaplanMeierAnalysisV2,
	GetKaplanMeierDurationRequestInputV2,
	GetLogRankDurationStatisticsV2,
	GetLogRankTimeRangeStatisticsV2,
	ComparePairedAnalysisV2,
	GetKaplanMeierTimeRangeRequestInputV2,
	DataModel,
	AnalysisErrorBarType,
	AnalysisStatisticAggregationType,
	AnalysisV2,
	AnalysisV1,
	CompareNumericResultsV2,
	IndependentResultsV2,
	KruskalResultsV2,
	MannWhitneyResultsV2,
	OneWayAnovaResultsV2,
	OneWayManovaResultsV2,
	ShapiroResultsV2,
	TukeyResultsV2,
	TwoWayAnovaResultsV2,
	TwoWayManovaResultsV2,
	PairedTTestResultsV2,
	PairedWilcoxonResultsV2,
	NumberPlotXYAnalysis
} from 'api/data/analyses/types';
import { Dictionary } from 'environment';
import { AnalysisType } from 'api/data/analyses/constants';
import { parseApiEntryFilters } from 'helpers/analysis';
import {
	buildAggregationRuleNameToAggregatorVariableMap,
	buildVariableCategoriesMap,
	buildVariablesDataFromStoreData,
	getAnalysisVariablesByNames
} from 'helpers/variables';
import { VariablesMap } from '../variables';
import { nanoid } from 'nanoid';

const ERROR_BAR_TO_ANALYSIS_STATISTIC_AGGREGATION_TYPE: Record<
	AnalysisErrorBarType,
	AnalysisStatisticAggregationType
> = {
	[AnalysisErrorBarType.Mean]: AnalysisStatisticAggregationType.Mean,
	[AnalysisErrorBarType.MeanCI]: AnalysisStatisticAggregationType.MeanCI,
	[AnalysisErrorBarType.MeanRange]: AnalysisStatisticAggregationType.MeanRange,
	[AnalysisErrorBarType.MeanSD]: AnalysisStatisticAggregationType.MeanSD,
	[AnalysisErrorBarType.Median]: AnalysisStatisticAggregationType.Median,
	[AnalysisErrorBarType.MedianCI]: AnalysisStatisticAggregationType.MedianCI,
	[AnalysisErrorBarType.MedianIQR]: AnalysisStatisticAggregationType.MedianIQR,
	[AnalysisErrorBarType.MedianRange]: AnalysisStatisticAggregationType.MedianRange
};

export const getSnapshotAction = (
	payload: ActionPayload<GetSnapshotAction>
): GetSnapshotAction => ({
	type: ActionTypes.GET_SNAPSHOT,
	payload
});

export const getSnapshot =
	(snapshotId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.GET_SNAPSHOT,
			dispatch
		});

		try {
			activity.begin();

			const { projectId } = getState().data.projects;

			if (projectId) {
				const data = await context.api.data.snapshots().getSnapshot(snapshotId, projectId);
				const {
					variables: {
						byProjectId: {
							[projectId]: { current: variablesStoreData }
						}
					}
				} = getState().data;

				const variablesData = buildVariablesDataFromStoreData(variablesStoreData);

				// TODO: refactor when BE resolves this issue
				try {
					const variablesStoreData =
						getState().data.variables.byProjectId[projectId].current;
					const { variablesMap, variableSetsMap } =
						buildVariablesDataFromStoreData(variablesStoreData);

					const aggRuleNameToAggRuleMap =
						buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

					const aggRuleToVariableMap = Object.entries(aggRuleNameToAggRuleMap).reduce(
						(acc, [ruleName, rule]) => {
							return {
								...acc,
								[ruleName]: variablesMap[rule.aggregator.variableName]
							};
						},
						{}
					);

					const filters = parseApiEntryFilters(data.filters, {
						...variablesMap,
						...aggRuleToVariableMap
					});

					const extendedVariablesMap: VariablesMap = {
						...variablesMap,
						...aggRuleToVariableMap
					};

					const analysesPromises = await Promise.allSettled(
						data.analysisList.map(async (a: AnalysisV1 | AnalysisV2) => {
							if (a.type === AnalysisType.CompareNumericV2) {
								const oldCompareNumericAnalysis = a as CompareNumericAnalysisV2;
								const {
									categoryVariable: initialCategoryVariable,
									categoryVariableTwo: initialCategoryVariableTwo,
									exploreVariable: initialExploreVariable,
									exploreVariableTwo: initialexploreVariableTwo
								} = oldCompareNumericAnalysis.input.variables;

								const [
									categoryVariable,
									categoryVariableTwo,
									exploreVariable,
									exploreVariableTwo
								] = getAnalysisVariablesByNames(
									[
										initialCategoryVariable?.name,
										initialCategoryVariableTwo?.name,
										initialExploreVariable?.name,
										initialexploreVariableTwo?.name
									],
									variablesData
								);

								oldCompareNumericAnalysis.input.variables = {
									categoryVariable: categoryVariable
										? {
												name: categoryVariable,
												series: initialCategoryVariable?.series
										  }
										: null,
									categoryVariableTwo: categoryVariableTwo
										? {
												name: categoryVariableTwo,
												series: initialCategoryVariableTwo?.series
										  }
										: null,
									exploreVariable: exploreVariable
										? {
												name: exploreVariable,
												series: initialExploreVariable?.series
										  }
										: null,
									exploreVariableTwo: exploreVariableTwo
										? {
												name: exploreVariableTwo,
												series: initialexploreVariableTwo?.name
										  }
										: null
								};

								const {
									categoryVariable: newCategoryVariable,
									categoryVariableTwo: newCategoryVariableTwo,
									exploreVariable: newExploreVariable,
									exploreVariableTwo: newExploreVariableTwo
								} = oldCompareNumericAnalysis.input.variables;

								let dataset: CompareNumericResultsV2 = {
									data: null
								};

								if (newCategoryVariable && newExploreVariable) {
									const compareNumericAnalysisDataset = await context.api.data
										.analyses()
										.getCompareNumericV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables:
												newCategoryVariableTwo && newCategoryVariable
													? [newCategoryVariable, newCategoryVariableTwo]
													: newCategoryVariable
													? [newCategoryVariable]
													: [],
											numericVariables:
												newExploreVariableTwo && newExploreVariable
													? [newExploreVariable, newExploreVariableTwo]
													: newExploreVariable
													? [newExploreVariable]
													: [],
											filters
										});

									dataset = compareNumericAnalysisDataset;
									oldCompareNumericAnalysis.output.dataset = dataset;

									// TODO VARIABLE CHECKING FOR STATS;
									const {
										independentV2,
										independent,
										kruskalV2,
										kruskal,
										mannWhitneyV2,
										mannWhitney,
										oneWayAnovaV2,
										oneWayAnova,
										oneWayManovaV2,
										oneWayManova,
										shapiroV2,
										shapiro,
										tukeyV2,
										tukey,
										twoWayAnovaV2,
										twoWayAnova,
										twoWayManovaV2,
										twoWayManova
									} = oldCompareNumericAnalysis.input.statistics;

									let independentResults: IndependentResultsV2 = {
										data: null
									};

									if (
										(independentV2 || independent) &&
										newCategoryVariable &&
										newExploreVariable
									) {
										independentResults = await context.api.data
											.analyses()
											.getIndependentStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable: newExploreVariable,
												groupVariable: newCategoryVariable,
												filters
											});
									}
									oldCompareNumericAnalysis.output.statistics.independent =
										independentResults;

									let kruskalResults: KruskalResultsV2 = { data: null };
									if (
										(kruskalV2 || kruskal) &&
										newExploreVariable &&
										newCategoryVariable
									) {
										kruskalResults = await context.api.data
											.analyses()
											.getKruskalStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable: newExploreVariable,
												groupVariable: newCategoryVariable,
												filters
											});
									}
									oldCompareNumericAnalysis.output.statistics.kruskal =
										kruskalResults;

									let mannWhitneyResults: MannWhitneyResultsV2 = { data: null };

									if (
										(mannWhitneyV2 || mannWhitney) &&
										newExploreVariable &&
										newCategoryVariable
									) {
										mannWhitneyResults = await context.api.data
											.analyses()
											.getMannWhitneyStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable: newExploreVariable,
												groupVariable: newCategoryVariable,
												filters
											});
									}
									oldCompareNumericAnalysis.output.statistics.mannWhitney =
										mannWhitneyResults;

									let oneWayAnovaResults: OneWayAnovaResultsV2 = {
										data: null
									};

									if (
										(oneWayAnovaV2 || oneWayAnova) &&
										newExploreVariable &&
										newCategoryVariable
									) {
										oneWayAnovaResults = await context.api.data
											.analyses()
											.getOneWayAnovaStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable: newExploreVariable,
												groupVariables: [newCategoryVariable],
												filters
											});

										oldCompareNumericAnalysis.output.statistics.oneWayAnova =
											oneWayAnovaResults;
									}

									let oneWayManovaResults: OneWayManovaResultsV2 = { data: null };
									if (
										(oneWayManovaV2 || oneWayManova) &&
										newExploreVariableTwo &&
										newExploreVariable &&
										newCategoryVariable
									) {
										oneWayManovaResults = await context.api.data
											.analyses()
											.getOneWayManovaStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												groupVariables: [newCategoryVariable],
												numericVariables: [
													newExploreVariable,
													newExploreVariableTwo
												] as AnalysisVariable[],
												filters
											});
									}
									oldCompareNumericAnalysis.output.statistics.oneWayManova =
										oneWayManovaResults;

									let shapiroResults: ShapiroResultsV2 = { data: null };
									if (
										(shapiroV2 || shapiro) &&
										newCategoryVariableTwo &&
										newExploreVariableTwo &&
										newCategoryVariable &&
										newExploreVariable
									) {
										shapiroResults = await context.api.data
											.analyses()
											.getShapiroStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariables: [
													newExploreVariable,
													newExploreVariableTwo
												],
												groupVariables: [
													newCategoryVariable,
													newCategoryVariableTwo
												],

												filters
											});
									}
									oldCompareNumericAnalysis.output.statistics.shapiro =
										shapiroResults;

									let tukeyResults: TukeyResultsV2 = { data: null };

									if (
										(tukeyV2 || tukey) &&
										newExploreVariable &&
										newCategoryVariable
									) {
										tukeyResults = await context.api.data
											.analyses()
											.getTukeyStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable: newExploreVariable,
												groupVariable: newCategoryVariable,
												filters
											});
									}
									oldCompareNumericAnalysis.output.statistics.tukey =
										tukeyResults;

									let twoWayAnovaResults: TwoWayAnovaResultsV2 = { data: null };

									if (
										(twoWayAnovaV2 || twoWayAnova) &&
										newCategoryVariable &&
										newCategoryVariableTwo &&
										newExploreVariable
									) {
										twoWayAnovaResults = await context.api.data
											.analyses()
											.getTwoWayAnovaStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												groupVariables: [
													newCategoryVariable,
													newCategoryVariableTwo
												],
												numericVariable: newExploreVariable,
												filters
											});
									}
									oldCompareNumericAnalysis.output.statistics.twoWayAnova =
										twoWayAnovaResults;

									let twoWayManovaResults: TwoWayManovaResultsV2 = { data: null };
									if (
										(twoWayManovaV2 || twoWayManova) &&
										newCategoryVariableTwo &&
										newExploreVariableTwo &&
										newExploreVariable &&
										newCategoryVariable
									) {
										twoWayManovaResults = await context.api.data
											.analyses()
											.getTwoWayManovaStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												groupVariables: [
													newCategoryVariable,
													newCategoryVariableTwo
												],
												numericVariables: [
													newExploreVariable,
													newExploreVariableTwo
												],
												filters
											});
									}

									oldCompareNumericAnalysis.output.statistics.twoWayManova =
										twoWayManovaResults;

									return oldCompareNumericAnalysis;
								}
							}

							if (a.type === AnalysisType.CompareNumericV1) {
								const oldCompareNumericAnalysis = {
									...a,
									type: AnalysisType.CompareNumericV1
								} as CompareNumericAnalysisV1;

								const {
									categoryVariable: oldCategoryVariable,
									categoryVariableTwo: oldCategoryVariableTwo,
									exploreVariable: oldExploreVariable,
									exploreVariableTwo: oldExploreVariableTwo
								} = oldCompareNumericAnalysis.input.variables;

								const [
									initialCategoryVariable,
									initialCategoryVariableTwo,
									initialExploreVariable,
									initialExploreVariableTwo
								] = getAnalysisVariablesByNames(
									[
										oldCategoryVariable,
										oldCategoryVariableTwo,
										oldExploreVariable,
										oldExploreVariableTwo
									],
									variablesData
								);

								const newAnalysis: CompareNumericAnalysisV2 = {
									...oldCompareNumericAnalysis,
									type: AnalysisType.CompareNumericV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											categoryVariable: initialCategoryVariable
												? {
														name: initialCategoryVariable
												  }
												: null,
											categoryVariableTwo: initialCategoryVariableTwo
												? {
														name: initialCategoryVariableTwo
												  }
												: null,
											exploreVariable: initialExploreVariable
												? {
														name: initialExploreVariable
												  }
												: null,
											exploreVariableTwo: initialExploreVariableTwo
												? {
														name: initialExploreVariableTwo
												  }
												: null
										},
										// implementation error in v1 now leads to 2 flags, not sure which one is relevant, but if `v1` suffixed one is ever used the checks below ensure that the outcome is correct
										statistics: {
											independentV2:
												oldCompareNumericAnalysis.input.statistics
													.independent ||
												oldCompareNumericAnalysis.input.statistics
													.independentV1,
											kruskalV2:
												oldCompareNumericAnalysis.input.statistics
													.kruskal ||
												oldCompareNumericAnalysis.input.statistics
													.kruskalV1,
											mannWhitneyV2:
												oldCompareNumericAnalysis.input.statistics
													.mannWhitney ||
												oldCompareNumericAnalysis.input.statistics
													.mannWhitneyV1,
											oneWayAnovaV2:
												oldCompareNumericAnalysis.input.statistics
													.oneWayAnova ||
												oldCompareNumericAnalysis.input.statistics
													.oneWayAnovaV1,
											twoWayAnovaV2:
												oldCompareNumericAnalysis.input.statistics
													.twoWayAnova ||
												oldCompareNumericAnalysis.input.statistics
													.twoWayAnovaV1,
											oneWayManovaV2:
												oldCompareNumericAnalysis.input.statistics
													.oneWayManova ||
												oldCompareNumericAnalysis.input.statistics
													.oneWayManovaV1,
											tukeyV2:
												oldCompareNumericAnalysis.input.statistics.tukey ||
												oldCompareNumericAnalysis.input.statistics.tukeyV1,
											twoWayManovaV2:
												oldCompareNumericAnalysis.input.statistics
													.twoWayManova ||
												oldCompareNumericAnalysis.input.statistics
													.twoWayManovaV1,
											shapiroV2:
												oldCompareNumericAnalysis.input.statistics
													.shapiro ||
												oldCompareNumericAnalysis.input.statistics.shapiroV1
										}
									},
									output: {
										dataset: {
											data: null
										},
										statistics: {
											independent: { data: null },
											kruskal: { data: null },
											mannWhitney: { data: null },
											oneWayAnova: { data: null },
											oneWayManova: { data: null },
											shapiro: { data: null },
											tukey: { data: null },
											twoWayAnova: { data: null },
											twoWayManova: { data: null }
										}
									}
								};

								const {
									input: {
										variables: {
											categoryVariable,
											categoryVariableTwo,
											exploreVariable,
											exploreVariableTwo
										}
									}
								} = newAnalysis as CompareNumericAnalysisV2;

								let dataset: CompareNumericResultsV2 = { data: null };

								if (categoryVariable && exploreVariable) {
									dataset = await context.api.data
										.analyses()
										.getCompareNumericV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [
												...(categoryVariable && categoryVariableTwo
													? [categoryVariable, categoryVariableTwo]
													: categoryVariable
													? [categoryVariable]
													: categoryVariableTwo
													? [categoryVariableTwo]
													: [])
											],
											numericVariables: [
												...(exploreVariable && exploreVariableTwo
													? [exploreVariable, exploreVariableTwo]
													: exploreVariable
													? [exploreVariable]
													: exploreVariableTwo
													? [exploreVariableTwo]
													: [])
											],
											filters
										});
								}

								newAnalysis.output.dataset = dataset;

								const {
									independentV2,
									kruskalV2,
									mannWhitneyV2,
									oneWayAnovaV2,
									oneWayManovaV2,
									shapiroV2,
									tukeyV2,
									twoWayAnovaV2,
									twoWayManovaV2
								} = newAnalysis.input.statistics;

								if (independentV2 && categoryVariable && exploreVariable) {
									const independentStatistic = await context.api.data
										.analyses()
										.getIndependentStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariable: categoryVariable,
											numericVariable: exploreVariable,
											filters
										});

									newAnalysis.output.statistics.independent =
										independentStatistic;
								}

								if (kruskalV2 && exploreVariable && categoryVariable) {
									const kruskalStatistic = await context.api.data
										.analyses()
										.getKruskalStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariable: categoryVariable,
											numericVariable: exploreVariable,
											filters
										});

									newAnalysis.output.statistics.kruskal = kruskalStatistic;
								}

								if (mannWhitneyV2 && exploreVariable && categoryVariable) {
									const mannWhitneyStatistic = await context.api.data
										.analyses()
										.getMannWhitneyStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariable: categoryVariable,
											numericVariable: exploreVariable,
											filters
										});

									newAnalysis.output.statistics.mannWhitney =
										mannWhitneyStatistic;
								}

								if (oneWayAnovaV2 && exploreVariable && categoryVariable) {
									const oneWayAnovaStatistic = await context.api.data
										.analyses()
										.getOneWayAnovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable],
											numericVariable: exploreVariable,
											filters
										});

									newAnalysis.output.statistics.oneWayAnova =
										oneWayAnovaStatistic;
								}
								if (
									oneWayManovaV2 &&
									exploreVariable &&
									exploreVariableTwo &&
									categoryVariable
								) {
									const oneWayManovaStatistic = await context.api.data
										.analyses()
										.getOneWayManovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable],
											numericVariables: [exploreVariable, exploreVariableTwo],
											filters
										});

									newAnalysis.output.statistics.oneWayManova =
										oneWayManovaStatistic;
								}

								if (
									shapiroV2 &&
									categoryVariable &&
									categoryVariableTwo &&
									exploreVariable &&
									exploreVariableTwo
								) {
									const shapiroStatistic = await context.api.data
										.analyses()
										.getShapiroStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable, categoryVariableTwo],
											numericVariables: [exploreVariable, exploreVariableTwo],
											filters
										});

									newAnalysis.output.statistics.shapiro = shapiroStatistic;
								}

								if (tukeyV2 && exploreVariable && categoryVariable) {
									const tukeyStatistic = await context.api.data
										.analyses()
										.getTukeyStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariable: categoryVariable,
											numericVariable: exploreVariable,
											filters
										});

									newAnalysis.output.statistics.tukey = tukeyStatistic;
								}

								if (
									twoWayAnovaV2 &&
									categoryVariableTwo &&
									categoryVariable &&
									exploreVariable
								) {
									const twoWayAnovaStatistic = await context.api.data
										.analyses()
										.getTwoWayAnovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable, categoryVariableTwo],
											numericVariable: exploreVariable,
											filters
										});

									newAnalysis.output.statistics.twoWayAnova =
										twoWayAnovaStatistic;
								}

								if (
									twoWayManovaV2 &&
									exploreVariable &&
									exploreVariableTwo &&
									categoryVariable &&
									categoryVariableTwo
								) {
									const twoWayManovaStatistic = await context.api.data
										.analyses()
										.getTwoWayManovaStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables: [categoryVariable, categoryVariableTwo],
											numericVariables: [exploreVariable, exploreVariableTwo],
											filters
										});

									newAnalysis.output.statistics.twoWayManova =
										twoWayManovaStatistic;
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.ComparePaired) {
								const oldComparePairedAnalysis = a as ComparePairedAnalysis;

								const isSingleEntryPerSubject =
									oldComparePairedAnalysis.input.dataModel ===
									ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT;
								const isMultipleEntryPerSubject =
									oldComparePairedAnalysis.input.dataModel ===
									ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT;

								// OLD key to overriden by new key at some point
								const isUsingSeries =
									oldComparePairedAnalysis.input.dataModel ===
										ComparePairedDataModels.USING_SERIES ||
									oldComparePairedAnalysis.input.dataModel ===
										ComparePairedDataModels.OLD_USING_SERIES;

								const newAnalysis: ComparePairedAnalysisV2 = {
									...oldComparePairedAnalysis,
									type: AnalysisType.ComparePairedV2,
									input: {
										dataModel: isUsingSeries
											? ComparePairedDataModels.USING_SERIES
											: oldComparePairedAnalysis.input.dataModel,
										variables: {
											afterVariable: null,
											beforeVariable: null,
											groupVariable: null,
											numericVariable: null,
											pairIdentifier: null
										},
										statistics: {
											pairedTTestV2:
												oldComparePairedAnalysis.input.statistics
													.pairedTTest,
											pairedWilcoxonV2:
												oldComparePairedAnalysis.input.statistics
													.pairedWilcoxon
										}
									},
									output: {
										dataset: {
											data: null
										},
										statistics: {
											pairedTTest: {
												data: null
											},
											pairedWilcoxon: {
												data: null
											}
										}
									}
								};

								const {
									numericVariableTwo: initialNumericVariableTwo,
									numericVariableOne: initialNumericVariableOne
								} = oldComparePairedAnalysis.input.variables;

								const [numericVariableOne, numericVariableTwo] =
									getAnalysisVariablesByNames(
										[initialNumericVariableOne, initialNumericVariableTwo],
										variablesData
									);

								if (isSingleEntryPerSubject) {
									newAnalysis.input.variables.beforeVariable = numericVariableTwo
										? {
												name: numericVariableTwo
										  }
										: null;
									newAnalysis.input.variables.afterVariable = numericVariableOne
										? {
												name: numericVariableOne
										  }
										: null;

									const { beforeVariable, afterVariable } =
										newAnalysis.input.variables;

									if (beforeVariable && afterVariable) {
										const dataset = await context.api.data
											.analyses()
											.getComparePairedV2(
												{
													beforeVariable,
													afterVariable,
													filters,
													projectId: Number(projectId)
												},
												true
											);

										newAnalysis.output.dataset = dataset;
									}
								} else if (isMultipleEntryPerSubject) {
									const {
										patientIdentifierVarname: initialPatientIdentifierVarname,
										catVarnameDiffSamples: initialCatVarnameDiffSamples,
										numericVariableOne: initialNumericVariableOne
									} = oldComparePairedAnalysis.input.variables;

									const [
										patientIdentifierVarname,
										catVarnameDiffSamples,
										numericVariableOne
									] = getAnalysisVariablesByNames(
										[
											initialPatientIdentifierVarname,
											initialCatVarnameDiffSamples,
											initialNumericVariableOne
										],
										variablesData
									);

									newAnalysis.input.variables.pairIdentifier =
										patientIdentifierVarname
											? {
													name: patientIdentifierVarname
											  }
											: null;
									newAnalysis.input.variables.groupVariable =
										catVarnameDiffSamples
											? {
													name: catVarnameDiffSamples
											  }
											: null;
									newAnalysis.input.variables.numericVariable = numericVariableOne
										? {
												name: numericVariableOne
										  }
										: null;

									const { numericVariable, groupVariable, pairIdentifier } =
										newAnalysis.input.variables;

									if (numericVariable && groupVariable && pairIdentifier) {
										const dataset = await context.api.data
											.analyses()
											.getComparePairedV2(
												{
													projectId: Number(projectId),
													filters,
													numericVariable,
													groupVariable,
													pairingVariable: pairIdentifier
												},
												false
											);

										newAnalysis.output.dataset = dataset;
									}
								} else {
									newAnalysis.input.series =
										oldComparePairedAnalysis.input.variables.setName;

									const {
										testVariable: initialTestVariable,
										groupVariable: initialGroupVariable
									} = oldComparePairedAnalysis.input.variables;
									const [testVariable, groupVariable] =
										getAnalysisVariablesByNames(
											[initialTestVariable, initialGroupVariable],
											variablesData
										);

									newAnalysis.input.variables.numericVariable = testVariable
										? {
												name: testVariable,
												series: newAnalysis.input.series
										  }
										: null;
									newAnalysis.input.variables.groupVariable = groupVariable
										? {
												name: groupVariable,
												series: newAnalysis.input.series
										  }
										: null;

									const { numericVariable, groupVariable: newGroupVariable } =
										newAnalysis.input.variables;

									if (numericVariable && newGroupVariable) {
										const dataset = await context.api.data
											.analyses()
											.getComparePairedV2(
												{
													projectId: Number(projectId),
													filters,
													groupVariable: newGroupVariable,
													numericVariable
												},
												false
											);
										newAnalysis.output.dataset = dataset;
									}
								}

								const { pairedTTestV2, pairedWilcoxonV2 } =
									newAnalysis.input.statistics;

								if (pairedTTestV2) {
									if (isSingleEntryPerSubject) {
										const { beforeVariable, afterVariable } =
											newAnalysis.input.variables;

										if (beforeVariable && afterVariable) {
											const pairedTTextStatistic = await context.api.data
												.analyses()
												.getPairedTTestStatisticsV2({
													input: {
														projectId: Number(projectId),
														filters,
														afterVariable,
														beforeVariable
													},
													dataModel:
														oldComparePairedAnalysis.input.dataModel
												});

											newAnalysis.output.statistics.pairedTTest =
												pairedTTextStatistic;
										}
									} else if (isMultipleEntryPerSubject) {
										const { numericVariable, groupVariable, pairIdentifier } =
											newAnalysis.input.variables;

										if (numericVariable && groupVariable && pairIdentifier) {
											const pairedTTestStatistic = await context.api.data
												.analyses()
												.getPairedTTestStatisticsV2({
													input: {
														projectId: Number(projectId),
														filters,
														groupVariable,
														numericVariable,
														pairingVariable: pairIdentifier
													},
													dataModel:
														oldComparePairedAnalysis.input.dataModel
												});

											newAnalysis.output.statistics.pairedTTest =
												pairedTTestStatistic;
										}
									} else {
										const { numericVariable, groupVariable } =
											newAnalysis.input.variables;

										if (numericVariable && groupVariable) {
											const pairedTTestStatistic = await context.api.data
												.analyses()
												.getPairedTTestStatisticsV2({
													input: {
														projectId: Number(projectId),
														filters,
														groupVariable,
														numericVariable
													},
													dataModel:
														oldComparePairedAnalysis.input.dataModel
												});

											newAnalysis.output.statistics.pairedTTest =
												pairedTTestStatistic;
										}
									}
								}

								if (pairedWilcoxonV2) {
									if (isSingleEntryPerSubject) {
										const { beforeVariable, afterVariable } =
											newAnalysis.input.variables;

										if (beforeVariable && afterVariable) {
											const pairedWilcoxonStatistic = await context.api.data
												.analyses()
												.getPairedWilcoxonStatisticsV2({
													dataModel:
														oldComparePairedAnalysis.input.dataModel,
													input: {
														projectId: Number(projectId),
														filters,
														beforeVariable,
														afterVariable
													}
												});

											newAnalysis.output.statistics.pairedWilcoxon =
												pairedWilcoxonStatistic;
										}
									} else if (isMultipleEntryPerSubject) {
										const { numericVariable, groupVariable, pairIdentifier } =
											newAnalysis.input.variables;

										if (numericVariable && groupVariable && pairIdentifier) {
											const pairedWilcoxonStatistic = await context.api.data
												.analyses()
												.getPairedWilcoxonStatisticsV2({
													dataModel:
														oldComparePairedAnalysis.input.dataModel,
													input: {
														projectId: Number(projectId),
														filters,
														numericVariable,
														groupVariable,
														pairingVariable: pairIdentifier
													}
												});

											newAnalysis.output.statistics.pairedWilcoxon =
												pairedWilcoxonStatistic;
										}
									} else {
										const { numericVariable, groupVariable } =
											newAnalysis.input.variables;
										if (numericVariable && groupVariable) {
											const pairedWilcoxonStatistic = await context.api.data
												.analyses()
												.getPairedWilcoxonStatisticsV2({
													dataModel:
														oldComparePairedAnalysis.input.dataModel,
													input: {
														projectId: Number(projectId),
														filters,
														numericVariable,
														groupVariable
													}
												});

											newAnalysis.output.statistics.pairedWilcoxon =
												pairedWilcoxonStatistic;
										}
									}
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.ComparePairedV2) {
								const oldComparePairedAnalysis = a as ComparePairedAnalysisV2;
								const {
									input: {
										dataModel,
										variables: {
											afterVariable: initialAfterVariable,
											beforeVariable: initialBeforeVariable,
											groupVariable: initialGroupVariable,
											numericVariable: initialNumericVariable,
											pairIdentifier: initialPairIdentifier
										}
									}
								} = oldComparePairedAnalysis;

								const [
									afterVariable,
									beforeVariable,
									groupVariable,
									numericVariable,
									pairIdentifier
								] = getAnalysisVariablesByNames(
									[
										initialAfterVariable?.name,
										initialBeforeVariable?.name,
										initialGroupVariable?.name,
										initialNumericVariable?.name,
										initialPairIdentifier?.name
									],
									variablesData
								);

								const newAnalysis: ComparePairedAnalysisV2 = {
									...oldComparePairedAnalysis,
									input: {
										...oldComparePairedAnalysis.input,
										variables: {
											...(dataModel ===
											ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT
												? {
														beforeVariable: beforeVariable
															? { name: beforeVariable }
															: null,
														afterVariable: afterVariable
															? { name: afterVariable }
															: null,
														groupVariable: null,
														numericVariable: null,
														pairIdentifier: null
												  }
												: dataModel ===
												  ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT
												? {
														numericVariable: numericVariable
															? { name: numericVariable }
															: null,
														groupVariable: groupVariable
															? { name: groupVariable }
															: null,
														pairIdentifier: pairIdentifier
															? { name: pairIdentifier }
															: null,
														afterVariable: null,
														beforeVariable: null
												  }
												: {
														numericVariable: numericVariable
															? {
																	name: numericVariable,
																	series: initialNumericVariable?.series
															  }
															: null,
														groupVariable: groupVariable
															? {
																	name: groupVariable,
																	series: initialGroupVariable?.series
															  }
															: null,
														afterVariable: null,
														beforeVariable: null,
														pairIdentifier: null
												  })
										},
										...(oldComparePairedAnalysis.input.series && {
											series: oldComparePairedAnalysis.input.series
										})
									},
									output: {
										dataset: {
											data: null
										},
										statistics: {
											pairedTTest: {
												data: null
											},
											pairedWilcoxon: {
												data: null
											}
										}
									}
								};

								const {
									afterVariable: newAfterVariable,
									beforeVariable: newBeforeVariable,
									groupVariable: newGroupVariable,
									numericVariable: newNumericVariable,
									pairIdentifier: newPairIdentifier
								} = newAnalysis.input.variables;

								if (
									dataModel ===
										ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
									newBeforeVariable &&
									newAfterVariable
								) {
									newAnalysis.output.dataset = await context.api.data
										.analyses()
										.getComparePairedV2(
											{
												projectId: Number(projectId),
												filters,
												beforeVariable: newBeforeVariable,
												afterVariable: newAfterVariable
											},
											true
										);
								} else if (
									dataModel ===
										ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT &&
									newNumericVariable &&
									newGroupVariable &&
									newPairIdentifier
								) {
									newAnalysis.output.dataset = await context.api.data
										.analyses()
										.getComparePairedV2({
											projectId: Number(projectId),
											filters,
											numericVariable: newNumericVariable,
											groupVariable: newGroupVariable,
											pairingVariable: newPairIdentifier
										});
								} else if (
									dataModel === ComparePairedDataModels.USING_SERIES &&
									newNumericVariable &&
									newGroupVariable
								) {
									newAnalysis.output.dataset = await context.api.data
										.analyses()
										.getComparePairedV2({
											projectId: Number(projectId),
											filters,
											numericVariable: newNumericVariable,
											groupVariable: newGroupVariable
										});
								}

								const { pairedTTestV2, pairedWilcoxonV2 } =
									oldComparePairedAnalysis.input.statistics;

								let pairedTTestOutput: PairedTTestResultsV2 = {
									data: null
								};

								if (pairedTTestV2) {
									if (
										dataModel === ComparePairedDataModels.USING_SERIES &&
										newNumericVariable &&
										newGroupVariable
									) {
										pairedTTestOutput = await context.api.data
											.analyses()
											.getPairedTTestStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													numericVariable: newNumericVariable,
													groupVariable: newGroupVariable
												}
											});
									} else if (
										dataModel ===
											ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
										newBeforeVariable &&
										newAfterVariable
									) {
										pairedTTestOutput = await context.api.data
											.analyses()
											.getPairedTTestStatisticsV2({
												input: {
													projectId: Number(projectId),
													filters,
													beforeVariable: newBeforeVariable,
													afterVariable: newAfterVariable
												},
												dataModel
											});
									} else if (
										dataModel ===
											ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
										newNumericVariable &&
										newGroupVariable &&
										newPairIdentifier
									) {
										pairedTTestOutput = await context.api.data
											.analyses()
											.getPairedTTestStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													numericVariable: newNumericVariable,
													groupVariable: newGroupVariable,
													pairingVariable: newPairIdentifier
												}
											});
									}
									oldComparePairedAnalysis.output.statistics.pairedTTest =
										pairedTTestOutput;

									return oldComparePairedAnalysis;
								}

								let pairedWilcoxonOutput: PairedWilcoxonResultsV2 = {
									data: null
								};

								if (pairedWilcoxonV2) {
									if (
										dataModel === ComparePairedDataModels.USING_SERIES &&
										newNumericVariable &&
										newGroupVariable
									) {
										pairedWilcoxonOutput = await context.api.data
											.analyses()
											.getPairedWilcoxonStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													numericVariable: newNumericVariable,
													groupVariable: newGroupVariable
												}
											});
									} else if (
										dataModel ===
											ComparePairedDataModels.SINGLE_ENTRY_PER_SUBJECT &&
										newBeforeVariable &&
										newAfterVariable
									) {
										pairedWilcoxonOutput = await context.api.data
											.analyses()
											.getPairedWilcoxonStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													beforeVariable: newBeforeVariable,
													afterVariable: newAfterVariable
												}
											});
									} else if (
										dataModel ===
											ComparePairedDataModels.MULTIPLE_ENTRIES_PER_SUBJECT &&
										newNumericVariable &&
										newGroupVariable &&
										newPairIdentifier
									) {
										pairedWilcoxonOutput = await context.api.data
											.analyses()
											.getPairedWilcoxonStatisticsV2({
												dataModel,
												input: {
													projectId: Number(projectId),
													filters,
													numericVariable: newNumericVariable,
													groupVariable: newGroupVariable,
													pairingVariable: newPairIdentifier
												}
											});
									}
									oldComparePairedAnalysis.output.statistics.pairedWilcoxon =
										pairedWilcoxonOutput;
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.CorrelationsV2) {
								const oldCorrelationsAnalysis = a as CorrelationsAnalysis;
								/**
								 * 	xVariable: AnalysisVariable | null;
									yVariable: AnalysisVariable | null;
									groupVariables: AnalysisVariable[];
								 */

								const {
									input: {
										variables: { groupVariables, xVariable, yVariable }
									}
								} = oldCorrelationsAnalysis;

								const [initialXVariable, initialYVariable, initialGroupVariable] =
									getAnalysisVariablesByNames(
										[
											xVariable?.name,
											yVariable?.name,
											groupVariables?.[0]?.name ?? ''
										],
										variablesData
									);

								const newAnalysis: CorrelationsAnalysis = {
									...oldCorrelationsAnalysis,
									input: {
										...oldCorrelationsAnalysis.input,
										dataModel:
											oldCorrelationsAnalysis.input.dataModel ??
											DataModel.main,
										variables: {
											groupVariables: initialGroupVariable
												? [
														{
															name: initialGroupVariable,
															series: oldCorrelationsAnalysis.input
																.series
														}
												  ]
												: [],
											xVariable: initialXVariable
												? {
														name: initialXVariable,
														series: oldCorrelationsAnalysis.input.series
												  }
												: null,
											yVariable: initialYVariable
												? {
														name: initialYVariable,
														series: oldCorrelationsAnalysis.input.series
												  }
												: null
										},
										...(oldCorrelationsAnalysis.input.series && {
											series: oldCorrelationsAnalysis.input.series
										})
									},
									output: {
										dataset: {
											data: null
										},
										statistics: {
											linearRegressionV2: {
												data: null
											},
											pearsonV2: {
												data: null
											},
											spearmanV2: {
												data: null
											}
										}
									}
								};

								const {
									xVariable: newXVariable,
									yVariable: newYVariable,
									groupVariables: newGroupVariables
								} = newAnalysis.input.variables;

								if (newXVariable && newYVariable) {
									newAnalysis.output.dataset = await context.api.data
										.analyses()
										.getCorrelations({
											projectId: Number(projectId),
											filters,
											...newAnalysis.input.variables
										});
								}

								const { linearRegressionV2, pearsonV2, spearmanV2 } =
									oldCorrelationsAnalysis.input.statistics;

								if (linearRegressionV2 && newXVariable && yVariable) {
									newAnalysis.output.statistics.linearRegressionV2 =
										await context.api.data
											.analyses()
											.getLinearRegressionStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												xVariable: newXVariable,
												yVariable: yVariable,
												groupVariables: newGroupVariables,
												filters
											});
								}

								if (pearsonV2 && newXVariable && newYVariable) {
									newAnalysis.output.statistics.pearsonV2 = await context.api.data
										.analyses()
										.getPearsonStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											xVariable: newXVariable,
											yVariable: newYVariable,
											groupVariables: newGroupVariables,
											filters
										});
								}

								if (spearmanV2 && xVariable && yVariable) {
									newAnalysis.output.statistics.spearmanV2 =
										await context.api.data.analyses().getSpearmanStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											xVariable,
											yVariable,
											groupVariables,
											filters
										});
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.CorrelationsV1) {
								const old = a as CorrelationsV1Analysis;
								// backwards compatibility
								const oldCorrelationsAnalysis = {
									...old,
									output: {
										...old.output,
										statistics: {
											...old.output.statistics,
											linearRegression:
												old.output.statistics.linearRegression,
											pearson: old.output.statistics.pearson,
											spearman: old.output.statistics.spearman
										} as CorrelationsV1Analysis['output']['statistics']
									}
								} as CorrelationsV1Analysis;

								const [xNumericVariable, yNumericVariable, groupingVariable] =
									getAnalysisVariablesByNames(
										[
											oldCorrelationsAnalysis.input.variables
												.xNumericVariable,
											oldCorrelationsAnalysis.input.variables
												.yNumericVariable,
											oldCorrelationsAnalysis.input.variables.groupingVariable
										],
										variablesData
									);

								const newAnalysis: CorrelationsAnalysis = {
									...oldCorrelationsAnalysis,
									type: AnalysisType.CorrelationsV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											xVariable: xNumericVariable
												? {
														name: xNumericVariable
												  }
												: null,
											yVariable: yNumericVariable
												? {
														name: yNumericVariable
												  }
												: null,
											groupVariables: [
												...(groupingVariable
													? [
															{
																name: groupingVariable
															}
													  ]
													: [])
											]
										},
										statistics: {
											linearRegressionV2:
												oldCorrelationsAnalysis.input.statistics
													.linearRegression ||
												oldCorrelationsAnalysis.input.statistics
													.linearRegressionV1,
											pearsonV2:
												oldCorrelationsAnalysis.input.statistics.pearson ||
												oldCorrelationsAnalysis.input.statistics.pearsonV1,
											spearmanV2:
												oldCorrelationsAnalysis.input.statistics.spearman ||
												oldCorrelationsAnalysis.input.statistics.spearmanV1
										}
									},
									output: {
										dataset: {
											data: null
										},
										statistics: {
											linearRegressionV2: {
												data: null
											},
											pearsonV2: {
												data: null
											},
											spearmanV2: {
												data: null
											}
										}
									}
								};
								const { groupVariables, xVariable, yVariable } =
									newAnalysis.input.variables;

								if (xVariable && yVariable) {
									const correlationsAnalysis = await context.api.data
										.analyses()
										.getCorrelations({
											projectId: Number(projectId),
											filters,
											groupVariables,
											xVariable,
											yVariable
										});

									newAnalysis.output.dataset = correlationsAnalysis;
								}

								const { linearRegressionV2, pearsonV2, spearmanV2 } =
									newAnalysis.input.statistics;

								if (linearRegressionV2 && xVariable && yVariable) {
									const linearRegressionStatistic = await context.api.data
										.analyses()
										.getLinearRegressionStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables,
											xVariable,
											yVariable,
											filters
										});

									newAnalysis.output.statistics.linearRegressionV2 =
										linearRegressionStatistic;
								}

								if (pearsonV2 && xVariable && yVariable) {
									const pearsonStatistic = await context.api.data
										.analyses()
										.getPearsonStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables,
											xVariable,
											yVariable,
											filters
										});

									newAnalysis.output.statistics.pearsonV2 = pearsonStatistic;
								}

								if (spearmanV2 && xVariable && yVariable) {
									const spearmanStatistic = await context.api.data
										.analyses()
										.getSpearmanStatisticsV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											groupVariables,
											xVariable,
											yVariable,
											filters
										});

									newAnalysis.output.statistics.spearmanV2 = spearmanStatistic;
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.Crosstab) {
								const oldCrosstabAnalysis = a as CrosstabAnalysis;

								const { groupingVariable, yVariable } =
									oldCrosstabAnalysis.input.variables;

								const [initialGroupingVariable, initialYVariable] =
									getAnalysisVariablesByNames(
										[groupingVariable, yVariable],
										variablesData
									);

								const newAnalysis: CrosstabAnalysisV2 = {
									...oldCrosstabAnalysis,
									type: AnalysisType.CrosstabV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											rowVariable: initialYVariable
												? { name: initialYVariable }
												: null,
											columnVariable: initialGroupingVariable
												? { name: initialGroupingVariable }
												: null
										},
										statistics: {
											chiSquareV2:
												oldCrosstabAnalysis.input.statistics.chiSquare,
											fisherV2: oldCrosstabAnalysis.input.statistics.fisher,
											mcNemarV2: oldCrosstabAnalysis.input.statistics.mcNemar
										}
									},
									output: {
										dataset: {
											data: null
										},
										statistics: {
											chiSquare: { data: null },
											fisher: { data: null },
											mcNemar: { data: null }
										}
									}
								};

								const { columnVariable, rowVariable } = newAnalysis.input.variables;

								if (columnVariable && rowVariable) {
									const crosstabAnalysis = await context.api.data
										.analyses()
										.getCrosstabV2({
											projectId: Number(projectId),
											filters,
											rowVariable,
											columnVariable
										});

									newAnalysis.output.dataset = crosstabAnalysis;

									const { chiSquareV2, fisherV2, mcNemarV2 } =
										newAnalysis.input.statistics;

									if (chiSquareV2) {
										const chiSquareStatistic = await context.api.data
											.analyses()
											.getChiStatisticsV2({
												projectId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										newAnalysis.output.statistics.chiSquare =
											chiSquareStatistic;
									}

									if (fisherV2) {
										const fisherStatistic = await context.api.data
											.analyses()
											.getFisherStatisticsV2({
												projectId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										newAnalysis.output.statistics.fisher = fisherStatistic;
									}

									if (mcNemarV2) {
										const mcNemarStatistic = await context.api.data
											.analyses()
											.getMcNemarStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												columnVariable,
												rowVariable,
												filters
											});

										newAnalysis.output.statistics.mcNemar = mcNemarStatistic;
									}

									return newAnalysis;
								}
							}

							if (a.type === AnalysisType.CrosstabV2) {
								const oldCrosstabAnalysis = a as CrosstabAnalysisV2;

								const {
									input: {
										variables: {
											columnVariable: initialColumnVariable,
											rowVariable: initialRowVariable
										},
										statistics: { chiSquareV2, fisherV2, mcNemarV2 }
									}
								} = oldCrosstabAnalysis;

								const [newColumnVariable, newRowVariable] =
									getAnalysisVariablesByNames(
										[
											initialColumnVariable?.name ?? '',
											initialRowVariable?.name ?? ''
										],
										variablesData
									);

								const newAnalysis: CrosstabAnalysisV2 = {
									...oldCrosstabAnalysis,
									input: {
										...oldCrosstabAnalysis.input,
										dataModel:
											oldCrosstabAnalysis.input.dataModel ?? DataModel.main,
										variables: {
											columnVariable: newColumnVariable
												? {
														name: newColumnVariable,
														series: oldCrosstabAnalysis.input.series
												  }
												: null,
											rowVariable: newRowVariable
												? {
														name: newRowVariable,
														series: oldCrosstabAnalysis.input.series
												  }
												: null
										},
										...(oldCrosstabAnalysis.input.series && {
											series: oldCrosstabAnalysis.input.series
										})
									}
								};

								const { rowVariable, columnVariable } = newAnalysis.input.variables;

								if (rowVariable && columnVariable) {
									const dataset = await context.api.data
										.analyses()
										.getCrosstabV2({
											projectId: Number(projectId),
											filters,
											rowVariable,
											columnVariable
										});

									newAnalysis.output.dataset = dataset;

									if (chiSquareV2) {
										const chiSquareStatistic = await context.api.data
											.analyses()
											.getChiStatisticsV2({
												projectId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										newAnalysis.output.statistics.chiSquare =
											chiSquareStatistic;
									}

									if (fisherV2) {
										const fisherStatistic = await context.api.data
											.analyses()
											.getFisherStatisticsV2({
												projectId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										newAnalysis.output.statistics.fisher = fisherStatistic;
									}

									if (mcNemarV2) {
										const mcNemarStatistic = await context.api.data
											.analyses()
											.getMcNemarStatisticsV2({
												projectId: Number(projectId),
												datasetId: Number(projectId),
												rowVariable,
												columnVariable,
												filters
											});

										newAnalysis.output.statistics.mcNemar = mcNemarStatistic;
									}
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.DensityPlot) {
								const oldDensityPlotAnalysis = a as DensityPlotAnalysis;

								const [initialGroupingVariable, initialNumericVariable] =
									getAnalysisVariablesByNames(
										[
											oldDensityPlotAnalysis.input.variables.groupingVariable,
											oldDensityPlotAnalysis.input.variables.numericVariable
										],
										variablesData
									);

								const newAnalysis: DensityPlotAnalysisV2 = {
									...oldDensityPlotAnalysis,
									type: AnalysisType.DensityPlotV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											numericVariable: initialNumericVariable
												? {
														name: initialNumericVariable
												  }
												: null,
											groupVariables: [
												...(initialGroupingVariable
													? [
															{
																name: initialGroupingVariable
															}
													  ]
													: [])
											]
										}
									},
									output: {
										dataset: {
											data: null
										}
									}
								};

								const { numericVariable } = newAnalysis.input.variables;

								if (numericVariable) {
									const densityPlotAnalysis = await context.api.data
										.analyses()
										.getDensityPlotV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...newAnalysis.input.variables,
											filters
										});

									newAnalysis.output.dataset = densityPlotAnalysis;
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.DensityPlotV2) {
								const oldDensityPlotAnalysis = a as DensityPlotAnalysisV2;

								const [initialNumericVariable, initialGroupVariable] =
									getAnalysisVariablesByNames(
										[
											oldDensityPlotAnalysis.input.variables.numericVariable
												?.name ?? '',
											oldDensityPlotAnalysis.input.variables
												.groupVariables?.[0]?.name ?? ''
										],
										variablesData
									);

								const newAnalysis: DensityPlotAnalysisV2 = {
									...oldDensityPlotAnalysis,
									input: {
										...oldDensityPlotAnalysis.input,
										dataModel:
											oldDensityPlotAnalysis.input.dataModel ??
											DataModel.main,
										variables: {
											numericVariable: initialNumericVariable
												? {
														name: initialNumericVariable,
														series: oldDensityPlotAnalysis.input.series
												  }
												: null,
											groupVariables: initialGroupVariable
												? [
														{
															name: initialGroupVariable,
															series: oldDensityPlotAnalysis.input
																.series
														}
												  ]
												: []
										},
										...(oldDensityPlotAnalysis.input.series && {
											series: oldDensityPlotAnalysis.input.series
										})
									},
									output: {
										dataset: {
											data: null
										}
									}
								};

								const { numericVariable } = newAnalysis.input.variables;

								if (numericVariable) {
									newAnalysis.output.dataset = await context.api.data
										.analyses()
										.getDensityPlotV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											...newAnalysis.input.variables,
											filters
										});
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.Explore) {
								const oldExploreAnalysis = a as ExploreAnalysis;

								const oldExploreVariables = oldExploreAnalysis.input.variables.map(
									d => d.variableName
								);
								const newVariables = getAnalysisVariablesByNames(
									oldExploreVariables,
									variablesData
								);

								const newAnalysis: ExploreAnalysisV2 = {
									...oldExploreAnalysis,
									type: AnalysisType.ExploreV2,
									input: {
										dataModel: DataModel.main,
										variables: newVariables.filter(Boolean).map(name => ({
											name
										})) as AnalysisVariable[]
									},
									output: {
										dataset: { data: null }
									}
								};

								const exploreVariables = newAnalysis.input.variables;

								if (exploreVariables.length) {
									newAnalysis.output.dataset = await context.api.data
										.analyses()
										.getExploreV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariables: exploreVariables,
											filters
										});
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.ExploreV2) {
								const oldExploreAnalysis = a as ExploreAnalysisV2;

								const oldExploreVariables = oldExploreAnalysis.input.variables.map(
									d => d.name
								);
								const newVariables = getAnalysisVariablesByNames(
									oldExploreVariables,
									variablesData
								);

								const newAnalysis: ExploreAnalysisV2 = {
									...oldExploreAnalysis,
									input: {
										...oldExploreAnalysis.input,
										dataModel:
											oldExploreAnalysis.input.dataModel ?? DataModel.main,
										variables: newVariables.filter(Boolean).map(name => ({
											name,
											series: oldExploreAnalysis.input.series
										})) as AnalysisVariable[],
										...(oldExploreAnalysis.input.series && {
											series: oldExploreAnalysis.input.series
										})
									},
									output: {
										dataset: {
											data: null
										}
									}
								};

								const exploreVariables = newAnalysis.input.variables;

								if (exploreVariables.length) {
									newAnalysis.output.dataset = await context.api.data
										.analyses()
										.getExploreV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											numericVariables: exploreVariables,
											filters
										});
								}

								return oldExploreAnalysis;
							}

							if (a.type === AnalysisType.Frequencies) {
								const oldAnalysis = a as FrequenciesAnalysis;

								const { categoryVariable } = oldAnalysis.input.variables;

								const [initialCategoryVariable] = getAnalysisVariablesByNames(
									[categoryVariable],
									variablesData
								);

								const newAnalysis: FrequenciesAnalysisV2 = {
									...oldAnalysis,
									type: AnalysisType.FrequenciesV2,
									input: {
										...oldAnalysis.input,
										dataModel: DataModel.main,
										variables: {
											categoryVariable: initialCategoryVariable
												? {
														name: initialCategoryVariable
												  }
												: null
										}
									},
									output: {
										dataset: {
											data: null
										}
									}
								};

								const { categoryVariable: newCategoryVariable } =
									newAnalysis.input.variables;

								if (newCategoryVariable) {
									newAnalysis.output.dataset = await context.api.data
										.analyses()
										.getFrequenciesV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											filters,
											categoryVariable: newCategoryVariable
										});
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.FrequenciesV2) {
								const oldFrequencyAnalysis = a as FrequenciesAnalysisV2;

								const [initialCategoryVariable] = getAnalysisVariablesByNames(
									[
										oldFrequencyAnalysis.input.variables.categoryVariable
											?.name ?? ''
									],
									variablesData
								);

								const newAnalysis: FrequenciesAnalysisV2 = {
									...oldFrequencyAnalysis,
									input: {
										...oldFrequencyAnalysis.input,
										dataModel:
											oldFrequencyAnalysis.input.dataModel ?? DataModel.main,
										variables: {
											categoryVariable: initialCategoryVariable
												? {
														name: initialCategoryVariable,
														series: oldFrequencyAnalysis.input.series
												  }
												: null
										},
										...(oldFrequencyAnalysis.input.series && {
											series: oldFrequencyAnalysis.input.series
										})
									}
								};

								const { categoryVariable } = newAnalysis.input.variables;

								if (categoryVariable) {
									newAnalysis.output.dataset = await context.api.data
										.analyses()
										.getFrequenciesV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											categoryVariable,
											filters
										});

									return newAnalysis;
								}
							}

							if (a.type === AnalysisType.Kaplan) {
								const oldKaplanMeierAnalysis = a as KaplanMeierAnalysis;

								const [
									initialDurationVariable,
									initialEndDate,
									initialEventVariable,
									initialGroupVariable,
									initialStartDate
								] = getAnalysisVariablesByNames(
									[
										oldKaplanMeierAnalysis.input.variables.durationVariable,
										oldKaplanMeierAnalysis.input.variables.endDate,
										oldKaplanMeierAnalysis.input.variables.eventVariable,
										oldKaplanMeierAnalysis.input.variables.groupVariable,
										oldKaplanMeierAnalysis.input.variables.startDate
									],
									variablesData
								);

								let initialPositiveEvent: string[] = [];
								if (initialEventVariable) {
									const initialCategoriesMap = buildVariableCategoriesMap(
										extendedVariablesMap[initialEventVariable].categories
									);
									if (
										oldKaplanMeierAnalysis.input.variables.positiveEvent?.[0] in
										initialCategoriesMap
									) {
										initialPositiveEvent =
											oldKaplanMeierAnalysis.input.variables.positiveEvent;
									}
								}

								const newAnalysis: KaplanMeierAnalysisV2 = {
									...oldKaplanMeierAnalysis,

									type: AnalysisType.KaplanV2,
									input: {
										...oldKaplanMeierAnalysis.input,
										dataModel: DataModel.main,
										statistics: {
											confidenceIntervalsV2:
												oldKaplanMeierAnalysis.input.statistics
													.confidenceIntervals,
											logRankV2:
												oldKaplanMeierAnalysis.input.statistics.logRank,
											patientsV2:
												oldKaplanMeierAnalysis.input.statistics.patients
										},
										variables: {
											durationVariable: initialDurationVariable
												? { name: initialDurationVariable }
												: null,
											endDate: initialEndDate
												? { name: initialEndDate }
												: null,
											startDate: initialStartDate
												? { name: initialStartDate }
												: null,
											eventVariable: initialEventVariable
												? { name: initialEventVariable }
												: null,
											groupVariable: initialGroupVariable
												? { name: initialGroupVariable }
												: null,
											positiveEvent: initialPositiveEvent,
											timeUnit:
												oldKaplanMeierAnalysis.input.variables.timeUnit
										}
									},
									output: {
										dataset: { data: null },
										statistics: { logRank: { data: null } }
									}
								};

								const {
									input: {
										selectedDataModel: model,
										variables: {
											durationVariable,
											endDate,
											eventVariable,
											positiveEvent,
											startDate,
											timeUnit,
											autofillDate,
											groupVariable,
											endTimeCensorVariable
										},
										statistics: { logRankV2 }
									}
								} = newAnalysis;

								if (durationVariable && eventVariable && positiveEvent) {
									if (model === KaplanMeierDataModels.duration) {
										const input: GetKaplanMeierDurationRequestInputV2 = {
											projectId: Number(projectId),
											durationVariable,
											events: positiveEvent,
											eventVariable,
											filters,
											...(groupVariable
												? { groupVariables: [groupVariable] }
												: {})
										};

										newAnalysis.output.dataset = await context.api.data
											.analyses()
											.getKaplanMeierV2(input, model);
									}
								}

								if (
									eventVariable &&
									startDate &&
									endDate &&
									endTimeCensorVariable
								) {
									if (model === KaplanMeierDataModels.timeRangeWithEvent) {
										const input: GetKaplanMeierTimeRangeRequestInputV2 = {
											projectId: Number(projectId),
											eventVariable,
											events: positiveEvent,
											startTimeVariable: startDate,
											endTimeVariable: endDate,
											endTimeFillValue: autofillDate,
											timeUnit,
											filters,
											endTimeCensorVariable,
											...(groupVariable
												? { groupVariables: [groupVariable] }
												: {})
										};

										newAnalysis.output.dataset = await context.api.data
											.analyses()
											.getKaplanMeierV2(input, model);
									}
								}
								/// here
								if (groupVariable && model && logRankV2) {
									if (
										model === KaplanMeierDataModels.duration &&
										durationVariable &&
										eventVariable
									) {
										const input: GetLogRankDurationStatisticsV2 = {
											events: positiveEvent,
											durationVariable,
											eventVariable,
											groupVariable
										};

										const logRankStatistic = await context.api.data
											.analyses()
											.getLogRankStatisticsV2(
												{
													...input,
													projectId: Number(projectId),
													datasetId: Number(projectId),
													filters
												},
												model
											);
										newAnalysis.output.statistics.logRank = logRankStatistic;
									} else if (
										model === KaplanMeierDataModels.timeRangeWithEvent &&
										endDate &&
										startDate &&
										eventVariable
									) {
										const input: GetLogRankTimeRangeStatisticsV2 = {
											events: positiveEvent,
											eventVariable,
											endTimeVariable: endDate,
											startTimeVariable: startDate,
											timeUnit,
											endTimeCensorVariable,
											endTimeFillValue: autofillDate,
											groupVariable
										};

										const logRankStatistic = await context.api.data
											.analyses()
											.getLogRankStatisticsV2(
												{
													...input,
													projectId: Number(projectId),
													datasetId: Number(projectId),
													filters
												},
												model
											);
										newAnalysis.output.statistics.logRank = logRankStatistic;
									}
								}
								return newAnalysis;
							}

							if (a.type === AnalysisType.KaplanV2) {
								const oldKaplanMeierAnalysis = a as KaplanMeierAnalysisV2;

								const [
									initialDurationVariable,
									initialEndDate,
									initialEventVariable,
									initialGroupVariable,
									initialStartDate
								] = getAnalysisVariablesByNames(
									[
										oldKaplanMeierAnalysis.input.variables.durationVariable
											?.name ?? '',
										oldKaplanMeierAnalysis.input.variables.endDate?.name ?? '',
										oldKaplanMeierAnalysis.input.variables.eventVariable
											?.name ?? '',
										oldKaplanMeierAnalysis.input.variables.groupVariable
											?.name ?? '',
										oldKaplanMeierAnalysis.input.variables.startDate?.name ?? ''
									],
									variablesData
								);

								let initialPositiveEvent: string[] = [];
								if (initialEventVariable) {
									const initialCategoriesMap = buildVariableCategoriesMap(
										extendedVariablesMap[initialEventVariable].categories
									);
									if (
										oldKaplanMeierAnalysis.input.variables.positiveEvent?.[0] in
										initialCategoriesMap
									) {
										initialPositiveEvent =
											oldKaplanMeierAnalysis.input.variables.positiveEvent;
									}
								}

								const newAnalysis: KaplanMeierAnalysisV2 = {
									...oldKaplanMeierAnalysis,
									input: {
										...oldKaplanMeierAnalysis.input,
										dataModel:
											oldKaplanMeierAnalysis.input.dataModel ??
											DataModel.main,
										variables: {
											durationVariable: initialDurationVariable
												? { name: initialDurationVariable }
												: null,
											endDate: initialEndDate
												? { name: initialEndDate }
												: null,
											startDate: initialStartDate
												? { name: initialStartDate }
												: null,
											eventVariable: initialEventVariable
												? { name: initialEventVariable }
												: null,
											groupVariable: initialGroupVariable
												? { name: initialGroupVariable }
												: null,
											positiveEvent: initialPositiveEvent,
											timeUnit:
												oldKaplanMeierAnalysis.input.variables.timeUnit
										},
										...(oldKaplanMeierAnalysis.input.series && {
											series: oldKaplanMeierAnalysis.input.series
										})
									},
									output: {
										dataset: {
											data: null
										},
										statistics: {
											logRank: {
												data: null
											}
										}
									}
								};

								const {
									input: {
										selectedDataModel: model,
										variables: {
											durationVariable,
											endDate,
											eventVariable,
											positiveEvent,
											startDate,
											timeUnit,
											autofillDate,
											groupVariable,
											endTimeCensorVariable
										}
									}
								} = newAnalysis;

								if (durationVariable && eventVariable && positiveEvent) {
									if (model === KaplanMeierDataModels.duration) {
										const input: GetKaplanMeierDurationRequestInputV2 = {
											projectId: Number(projectId),
											durationVariable,
											events: positiveEvent,
											eventVariable,
											filters,
											...(groupVariable
												? { groupVariables: [groupVariable] }
												: {})
										};

										newAnalysis.output.dataset = await context.api.data
											.analyses()
											.getKaplanMeierV2(input, model);
									}
								}

								if (
									eventVariable &&
									startDate &&
									endDate &&
									endTimeCensorVariable
								) {
									if (model === KaplanMeierDataModels.timeRangeWithEvent) {
										const input: GetKaplanMeierTimeRangeRequestInputV2 = {
											projectId: Number(projectId),
											eventVariable,
											events: positiveEvent,
											startTimeVariable: startDate,
											endTimeVariable: endDate,
											endTimeFillValue: autofillDate,
											timeUnit,
											filters,
											endTimeCensorVariable,
											...(groupVariable
												? { groupVariables: [groupVariable] }
												: {})
										};

										newAnalysis.output.dataset = await context.api.data
											.analyses()
											.getKaplanMeierV2(input, model);
									}
								}

								const { logRankV2 } = newAnalysis.input.statistics;

								if (logRankV2) {
									const {
										input: {
											selectedDataModel: dataModel,
											variables: {
												durationVariable,
												eventVariable,
												positiveEvent: events,
												endDate: endTimeVariable,
												startDate: startTimeVariable,
												groupVariable,
												endTimeCensorVariable,
												timeUnit,
												autofillDate
											}
										}
									} = newAnalysis;

									if (groupVariable && dataModel) {
										if (
											model === KaplanMeierDataModels.duration &&
											durationVariable &&
											eventVariable
										) {
											const input: GetLogRankDurationStatisticsV2 = {
												events,
												durationVariable,
												eventVariable,
												groupVariable
											};

											const logRankStatistic = await context.api.data
												.analyses()
												.getLogRankStatisticsV2(
													{
														...input,
														projectId: Number(projectId),
														datasetId: Number(projectId),
														filters
													},
													dataModel
												);
											oldKaplanMeierAnalysis.output.statistics.logRank =
												logRankStatistic;
										} else if (
											model === KaplanMeierDataModels.timeRangeWithEvent &&
											startTimeVariable &&
											endTimeVariable &&
											eventVariable
										) {
											const input: GetLogRankTimeRangeStatisticsV2 = {
												events,
												eventVariable,
												endTimeVariable,
												startTimeVariable,
												timeUnit,
												endTimeCensorVariable,
												endTimeFillValue: autofillDate,
												groupVariable
											};

											const logRankStatistic = await context.api.data
												.analyses()
												.getLogRankStatisticsV2(
													{
														...input,
														projectId: Number(projectId),
														datasetId: Number(projectId),
														filters
													},
													dataModel
												);
											newAnalysis.output.statistics.logRank =
												logRankStatistic;
										}
									}
								}
								return newAnalysis;
							}

							if (a.type === AnalysisType.LogisticRegression) {
								const oldLogisticRegressionAnalysis =
									a as LogisticRegressionAnalysis;

								const [
									initialIndependentVariable,
									initialDependentVariable,
									initialGroupVariable
								] = getAnalysisVariablesByNames(
									[
										oldLogisticRegressionAnalysis.input.variables
											.independentVariable,
										oldLogisticRegressionAnalysis.input.variables
											.dependentVariable,
										oldLogisticRegressionAnalysis.input.variables.groupVariable
									],
									variablesData
								);

								const newAnalysis: LogisticRegressionAnalysisV2 = {
									...oldLogisticRegressionAnalysis,
									type: AnalysisType.LogisticRegressionV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											outcomes:
												oldLogisticRegressionAnalysis.input.variables
													.positiveEvent,
											xVariable: initialIndependentVariable
												? {
														name: initialIndependentVariable
												  }
												: null,
											yVariable: initialDependentVariable
												? {
														name: initialDependentVariable
												  }
												: null,
											groupVariables: [
												...(initialGroupVariable
													? [
															{
																name: initialGroupVariable
															}
													  ]
													: [])
											]
										}
									},
									output: {
										dataset: { data: null }
									}
								};

								const { yVariable, xVariable, outcomes, groupVariables } =
									newAnalysis.input.variables;

								if (yVariable && xVariable && outcomes.length) {
									const logisticRegressionAnalysis = await context.api.data
										.analyses()
										.getLogisticReggresionV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											xVariable,
											yVariable,
											outcomes,
											...(groupVariables ? { groupVariables } : {}),
											filters
										});

									newAnalysis.output.dataset = logisticRegressionAnalysis;

									return newAnalysis;
								}
							}

							if (a.type === AnalysisType.LogisticRegressionV2) {
								const oldLogisticRegressionAnalysis =
									a as LogisticRegressionAnalysisV2;
								const [
									initialIndependentVariable,
									initialDependentVariable,
									initialGroupVariable
								] = getAnalysisVariablesByNames(
									[
										oldLogisticRegressionAnalysis.input.variables.xVariable
											?.name ?? '',
										oldLogisticRegressionAnalysis.input.variables.yVariable
											?.name ?? '',
										oldLogisticRegressionAnalysis.input.variables
											.groupVariables?.[0]?.name ?? ''
									],
									variablesData
								);

								const newAnalysis: LogisticRegressionAnalysisV2 = {
									...oldLogisticRegressionAnalysis,
									type: AnalysisType.LogisticRegressionV2,
									input: {
										dataModel:
											oldLogisticRegressionAnalysis.input.dataModel ??
											DataModel.main,
										variables: {
											outcomes:
												oldLogisticRegressionAnalysis.input.variables
													.outcomes,
											xVariable: initialIndependentVariable
												? {
														name: initialIndependentVariable
												  }
												: null,
											yVariable: initialDependentVariable
												? {
														name: initialDependentVariable
												  }
												: null,
											groupVariables: [
												...(initialGroupVariable
													? [
															{
																name: initialGroupVariable
															}
													  ]
													: [])
											]
										},
										...(oldLogisticRegressionAnalysis.input.series && {
											series: oldLogisticRegressionAnalysis.input.series
										})
									},
									output: {
										dataset: { data: null }
									}
								};

								const { yVariable, xVariable, outcomes, groupVariables } =
									newAnalysis.input.variables;

								if (yVariable && xVariable && outcomes.length) {
									const logisticRegressionAnalysis = await context.api.data
										.analyses()
										.getLogisticReggresionV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											xVariable,
											yVariable,
											outcomes,
											...(groupVariables ? { groupVariables } : {}),
											filters
										});

									oldLogisticRegressionAnalysis.output.dataset =
										logisticRegressionAnalysis;

									return oldLogisticRegressionAnalysis;
								}
							}

							if (a.type === AnalysisType.NumberPlotXY) {
								const oldNumberPlotXYAnalysis = a as NumberPlotXYAnalysis;

								return {
									...oldNumberPlotXYAnalysis,
									deprecated: true
								} as NumberPlotXYAnalysis;
							}

							if (a.type === AnalysisType.PlotNumeric) {
								const oldPlotNumericAnalysis = a as PlotNumericAnalysis;

								const [
									initialCategoryVariable,
									initialGroupingVariable,
									initialNumericVariable
								] = getAnalysisVariablesByNames(
									[
										oldPlotNumericAnalysis.input.variables.categoryVariable,
										oldPlotNumericAnalysis.input.variables.groupingVariable,
										oldPlotNumericAnalysis.input.variables.numericVariable
									],
									variablesData
								);

								const newAnalysis: PlotNumericAnalysisV2 = {
									...oldPlotNumericAnalysis,
									type: AnalysisType.PlotNumericV2,
									input: {
										dataModel: DataModel.main,
										variables: {
											categoryVariable: initialCategoryVariable
												? {
														name: initialCategoryVariable
												  }
												: null,
											errorBar:
												ERROR_BAR_TO_ANALYSIS_STATISTIC_AGGREGATION_TYPE[
													oldPlotNumericAnalysis.input.variables.errorBar
												],
											numericVariable: initialNumericVariable
												? {
														name: initialNumericVariable
												  }
												: null,
											groupingVariable: initialGroupingVariable
												? {
														name: initialGroupingVariable
												  }
												: null
										}
									},
									output: {
										dataset: {
											boxplot: {
												data: null
											},
											columns: {
												data: null
											},
											scatter: {
												data: null
											}
										},
										grouping:
											!!oldPlotNumericAnalysis.input.variables
												.groupingVariable
									}
								};

								const { categoryVariable, numericVariable, groupingVariable } =
									newAnalysis.input.variables;

								const prevActiveTab = oldPlotNumericAnalysis.options.activeTab ?? 0;

								if (prevActiveTab === 0 && categoryVariable && numericVariable) {
									const plotNumericColumnAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												groupVariables: [
													...(groupingVariable
														? [categoryVariable, groupingVariable]
														: [categoryVariable])
												],
												numericVariable,
												filters
											},
											0
										);

									newAnalysis.output.dataset.columns =
										plotNumericColumnAnalysis.columns;
								}

								if (prevActiveTab === 1 && categoryVariable && numericVariable) {
									const plotNumericBoxplotAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												groupVariables: [
													...(groupingVariable
														? [categoryVariable, groupingVariable]
														: [categoryVariable])
												],
												numericVariable,
												filters
											},
											1
										);

									newAnalysis.output.dataset.boxplot =
										plotNumericBoxplotAnalysis.boxplot;
								}

								if (prevActiveTab === 2 && categoryVariable && numericVariable) {
									const plotNumericScatterAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												groupVariables: [
													...(groupingVariable
														? [categoryVariable, groupingVariable]
														: [categoryVariable])
												],
												numericVariable,
												filters
											},
											2
										);

									newAnalysis.output.dataset.scatter =
										plotNumericScatterAnalysis.scatter;
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.PlotNumericV2) {
								const oldPlotNumericAnalysis = a as PlotNumericAnalysisV2;

								const [
									initialCategoryVariable,
									initialGroupingVariable,
									initialNumericVariable
								] = getAnalysisVariablesByNames(
									[
										oldPlotNumericAnalysis.input.variables.categoryVariable
											?.name ?? '',
										oldPlotNumericAnalysis.input.variables.groupingVariable
											?.name ?? '',
										oldPlotNumericAnalysis.input.variables.numericVariable
											?.name ?? ''
									],
									variablesData
								);

								const newAnalysis: PlotNumericAnalysisV2 = {
									...oldPlotNumericAnalysis,
									type: AnalysisType.PlotNumericV2,
									input: {
										dataModel:
											oldPlotNumericAnalysis.input.dataModel ??
											DataModel.main,
										variables: {
											categoryVariable: initialCategoryVariable
												? {
														name: initialCategoryVariable,
														series: oldPlotNumericAnalysis.input.series
												  }
												: null,
											errorBar:
												oldPlotNumericAnalysis.input.variables.errorBar,

											numericVariable: initialNumericVariable
												? {
														name: initialNumericVariable,
														series: oldPlotNumericAnalysis.input.series
												  }
												: null,
											groupingVariable: initialGroupingVariable
												? {
														name: initialGroupingVariable,
														series: oldPlotNumericAnalysis.input.series
												  }
												: null
										},
										...(oldPlotNumericAnalysis.input.series && {
											series: oldPlotNumericAnalysis.input.series
										})
									},
									output: {
										dataset: {
											boxplot: {
												data: null
											},
											columns: {
												data: null
											},
											scatter: {
												data: null
											}
										},
										grouping:
											!!oldPlotNumericAnalysis.input.variables
												.groupingVariable
									}
								};

								const {
									input: {
										variables: {
											numericVariable,
											groupingVariable,
											categoryVariable
										}
									}
								} = newAnalysis;

								const prevActiveTab = oldPlotNumericAnalysis.options.activeTab ?? 0;

								if (prevActiveTab === 0 && numericVariable && categoryVariable) {
									const plotNumericColumnAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable,
												groupVariables: [
													categoryVariable,
													...(groupingVariable ? [groupingVariable] : [])
												],
												filters
											},
											0
										);

									newAnalysis.output.dataset.columns =
										plotNumericColumnAnalysis.columns;
								}

								if (prevActiveTab === 1 && numericVariable && categoryVariable) {
									const plotNumericBoxplotAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable,
												groupVariables: [
													categoryVariable,
													...(groupingVariable ? [groupingVariable] : [])
												],
												filters
											},
											1
										);

									newAnalysis.output.dataset.boxplot =
										plotNumericBoxplotAnalysis.boxplot;
								}

								if (prevActiveTab === 2 && numericVariable && categoryVariable) {
									const plotNumericScatterAnalysis = await context.api.data
										.analyses()
										.getPlotNumericV2(
											{
												projectId: Number(projectId),
												datasetId: Number(projectId),
												numericVariable,
												groupVariables: [categoryVariable],
												filters
											},
											2
										);

									newAnalysis.output.dataset.scatter =
										plotNumericScatterAnalysis.scatter;
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.TimeCourseV2) {
								const oldTimeCourseAnalysis = a as TimeCourseAnalysisV2;

								const [
									initialNumericVariable,
									initialTimeVariable,
									initialGroupVariable
								] = getAnalysisVariablesByNames(
									[
										oldTimeCourseAnalysis.input.variables.numericVariable
											?.name ?? '',
										oldTimeCourseAnalysis.input.variables.timeVariable?.name ??
											'',
										oldTimeCourseAnalysis.input.variables.groupVariables?.[0]
											?.name ?? ''
									],
									variablesData
								);

								const newAnalysis: TimeCourseAnalysisV2 = {
									...oldTimeCourseAnalysis,
									input: {
										...oldTimeCourseAnalysis.input,
										dataModel:
											oldTimeCourseAnalysis.input.dataModel ?? DataModel.main,
										variables: {
											numericVariable: initialNumericVariable
												? {
														name: initialNumericVariable,
														series: oldTimeCourseAnalysis.input.series
												  }
												: null,
											timeVariable: initialTimeVariable
												? {
														name: initialTimeVariable,
														series: oldTimeCourseAnalysis.input.series
												  }
												: null,
											groupVariables: initialGroupVariable
												? [
														{
															name: initialGroupVariable,
															series: oldTimeCourseAnalysis.input
																.series
														}
												  ]
												: [],
											statistic:
												oldTimeCourseAnalysis.input.variables.statistic,
											timeUnit: oldTimeCourseAnalysis.input.variables.timeUnit
										},
										...(oldTimeCourseAnalysis.input.series && {
											series: oldTimeCourseAnalysis.input.series
										})
									}
								};

								const {
									numericVariable,
									statistic,
									timeVariable,
									groupVariables,
									timeUnit
								} = newAnalysis.input.variables;

								if (numericVariable && timeVariable) {
									const timeCourseAnalysis = await context.api.data
										.analyses()
										.getTimeCourseV2({
											projectId: Number(projectId),
											datasetId: Number(projectId),
											format: 'unix',
											numericVariable,
											timeVariable,
											groupVariables,
											timeUnit,
											statistic,
											filters
										});

									newAnalysis.output.dataset = timeCourseAnalysis;
								}

								return newAnalysis;
							}

							if (a.type === AnalysisType.TimeCourseV1) {
								const oldTimeCourseAnalysis = a as TimeCourseAnalysisV1;

								const [
									initialNumericVariable,
									initialTimeVariable,
									initialGroupVariable
								] = getAnalysisVariablesByNames(
									[
										oldTimeCourseAnalysis.input.variables.numericVariable,
										oldTimeCourseAnalysis.input.variables.dateVariable,
										oldTimeCourseAnalysis.input.variables.groupingVariable
									],
									variablesData
								);

								const newAnalysis: TimeCourseAnalysisV2 = {
									...oldTimeCourseAnalysis,
									type: AnalysisType.TimeCourseV2,
									input: {
										...oldTimeCourseAnalysis.input,
										dataModel: DataModel.main,
										variables: {
											numericVariable: initialNumericVariable
												? {
														name: initialNumericVariable
												  }
												: null,
											timeVariable: initialTimeVariable
												? {
														name: initialTimeVariable
												  }
												: null,
											groupVariables: initialGroupVariable
												? [
														{
															name: initialGroupVariable
														}
												  ]
												: [],
											statistic:
												ERROR_BAR_TO_ANALYSIS_STATISTIC_AGGREGATION_TYPE[
													oldTimeCourseAnalysis.input.variables.errorBar
												],
											timeUnit:
												oldTimeCourseAnalysis.input.variables.timeWindowSize
										}
									},
									output: {
										dataset: {
											data: null
										},
										grouping: !!initialGroupVariable
									}
								};

								const timeCourseAnalysis = await context.api.data
									.analyses()
									.getTimeCourseV2({
										projectId: Number(projectId),
										datasetId: Number(projectId),
										format: 'unix',
										...newAnalysis.input.variables,
										filters
									});

								newAnalysis.output.dataset = timeCourseAnalysis;

								return newAnalysis;
							}

							return a;
						})
					);

					// filter the analyses that were not fetched
					const newAnalysisList = await getFulfilledValues(analysesPromises);

					data.analysisList = newAnalysisList;
					dispatch(getSnapshotAction({ projectId, snapshotId, data }));
				} catch (e: any) {
					throw new Error(Dictionary.errors.api.snapshots.couldNotLoadSnapshot);
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

async function getFulfilledValues(results: PromiseSettledResult<any>[]): Promise<AnalysisV2[]> {
	// Filter out only the fulfilled promises and extract their values
	const fulfilledValues = results
		.filter(
			(result): result is PromiseFulfilledResult<AnalysisV2> => result.status === 'fulfilled'
		)
		.map((result: { value: AnalysisV2 }) => result.value);

	return fulfilledValues;
}

const getSnapshotsAction = (payload: ActionPayload<GetSnapshotsAction>): GetSnapshotsAction => ({
	type: ActionTypes.GET_SNAPSHOTS,
	payload
});

export const getSnapshots = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_SNAPSHOTS,
		dispatch
	});

	const { projectId } = getState().data.projects;

	try {
		activity.begin({ payload: { projectId } });

		if (projectId) {
			const snapshots = await context.api.data.snapshots().getSnapshots(projectId);

			dispatch(getSnapshotsAction({ projectId, snapshots }));
		}
	} catch (e: any) {
		activity.error({ error: e.message, payload: { projectId } });
	} finally {
		activity.end();
	}
};

const createSnapshotAction = (
	payload: ActionPayload<CreateSnapshotAction>
): CreateSnapshotAction => ({
	type: ActionTypes.CREATE_SNAPSHOT,
	payload
});

export const createSnapshot =
	(name: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.CREATE_SNAPSHOT,
			dispatch
		});

		try {
			activity.begin();

			const {
				projects: { projectId },
				filters: {
					dataset: { byProjectId: filtersByProjectId, byId: filtersById }
				},
				analyses: { byProjectId: analysesByProjectId, byId: analysesById }
			} = getState().data;

			if (projectId) {
				const filters = filtersByProjectId[projectId].active.map(id => filtersById[id]);
				const analysisList = analysesByProjectId[projectId].active.map(
					id => analysesById[id]
				);

				const analysisListWithNewIds = analysisList.map(analysis => ({
					...analysis,
					id: nanoid()
				}));

				const snapshot = {
					projectId,
					snapName: name,
					snapshotJson: JSON.stringify({
						filters,
						analysisList: analysisListWithNewIds
					})
				};

				const snapshotId = await context.api.data.snapshots().createSnapshot(snapshot);

				dispatch(createSnapshotAction({ projectId, snapshotId, name }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const updateSnapshotAction = (
	payload: ActionPayload<UpdateSnapshotAction>
): UpdateSnapshotAction => ({
	type: ActionTypes.UPDATE_SNAPSHOT,
	payload
});

export const updateSnapshot =
	(snapshotId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.UPDATE_SNAPSHOT,
			dispatch
		});

		try {
			activity.begin();

			const {
				projects: { projectId },
				analyses: { byProjectId: analysesByProjectId, byId: analysesById },
				filters: {
					dataset: { byProjectId: filtersByProjectId, byId: filtersById }
				},
				snapshots: { byId: bySnapshotId }
			} = getState().data;

			if (projectId) {
				const filters = filtersByProjectId[projectId].active.map(id => filtersById[id]);
				const analysisList = analysesByProjectId[projectId].active.map(
					id => analysesById[id]
				);

				const snapshot = {
					snapshotId: Number(snapshotId),
					projectId,
					snapName: bySnapshotId[snapshotId].snapName,
					snapshotJson: JSON.stringify({
						filters,
						analysisList
					})
				};

				await context.api.data.snapshots().updateSnapshot(snapshot);

				dispatch(updateSnapshotAction({ projectId, snapshotId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const deleteSnapshotAction = (
	payload: ActionPayload<DeleteSnapshotAction>
): DeleteSnapshotAction => ({
	type: ActionTypes.DELETE_SNAPSHOT,
	payload
});

export const deleteSnapshot =
	(snapshotId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.DELETE_SNAPSHOT,
			dispatch
		});

		try {
			activity.begin();

			const { projectId } = getState().data.projects;

			if (projectId) {
				await context.api.data.snapshots().deleteSnapshot(snapshotId, projectId);

				// remove analyses from the snapshot from store
				const snapshotAnalysesIds = getState().data.analyses.bySnapshotId[snapshotId];
				const jadBioAnalysesIds = extractJADBioAnalysesIds(snapshotAnalysesIds, getState);

				if (jadBioAnalysesIds.length)
					dispatch(deleteJADBioAnalyses(jadBioAnalysesIds, projectId));

				// Check if there are other snapshots that use the same analyses and delete the unused ones
				const analisesToRemove = [...snapshotAnalysesIds];

				for (const [snapId, analysesList] of Object.entries(
					getState().data.analyses.bySnapshotId
				)) {
					if (snapId !== snapshotId) {
						for (const analysisId of analysesList) {
							if (analisesToRemove.includes(analysisId)) {
								analisesToRemove.splice(analisesToRemove.indexOf(analysisId), 1);
							}
						}
					}
				}

				dispatch(deleteAnalysesAction({ projectId, analysisIds: analisesToRemove }));

				dispatch(deleteSnapshotAction({ projectId, snapshotId }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const setActiveSnapshot = (
	payload: ActionPayload<SetActiveSnapshotAction>
): SetActiveSnapshotAction => ({
	type: ActionTypes.SET_ACTIVE_SNAPSHOT,
	payload
});

export const clearSnapshot = (): ClearSnapshotAction => ({
	type: ActionTypes.CLEAR_SNAPSHOT
});
